import {
    InstanceTrackingTypeCodeEnum,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    OperationTypeCodeEnum,
    ProjectInfoRead,
    ProjectTypeCodeEnum,
    RentStateCodeEnum,
    SubrentInfoRead,
    TaxBaseTypeCodeEnum,
    TemplateInfoRead,
} from "../../../server/api";
import {
    InstanceStackMapList,
    KitStackMapList,
    OperationElement,
    ProductStackMapList,
    TimeTable
} from "../operationForm/reducers/operationForm.reducer";
import moment from "moment";
import {serverApi} from "../../../server";
import {AxiosResponse} from "axios";
import {RentElementsGridItem} from "../../../types";
import {getKeepLeftoverByElementStateCode, isOrderOperation, isRentOperation, isReturnOperation} from "../operationForm/utils/utils";
import {IntlShape} from "react-intl";
import React from 'react';
import {showActualizeDatesConfirm} from "../../../shared/util/showActualizeDatesConfirm";
import {
    setNewReturnDatesBeforeProlongOperationConfirm,
    SetNewReturnDatesForProlongConfirmResult
} from "../../../shared/util/setNewReturnDatesBeforeProlongOperationConfirm";
import {
    findNomenclature,
    loadAllNomenclaturesForRentElements,
    rentElementToNomenclatureBaseObj,
    nomenclaturesToTimetables, getTargetStackTypeCodes
} from "../operationForm/utils/nomenclaturesUtils";

export const createOperationElementFrom = (selectedItems:RentElementsGridItem[], item: RentElementsGridItem, itemStartDate:Date, itemEndDate:Date, instanceCount:number, code:OperationTypeCodeEnum|undefined):OperationElement => {

    let partOfKit;
    if(item.nomenclatureEntityTypeCode === 'KIT' && item['subRows']){
        let kitMembers = selectedItems.filter((i) => i.rentElementsKitId === item.id);
        if(item['subRows'].length !== kitMembers.length) partOfKit = true;
    }

    return {
        id: item.id,
        businessVersion: item.businessVersion,
        productId: item.productId,
        productShortName: item.nomenclatureShortName,
        productMainImage: item.image,
        stateCode: item.stateCode,
        rentPeriodStartDate: moment(itemStartDate).toDate(),
        rentPeriodEndDate: moment(itemEndDate).toDate(),
        shiftCount: item.rentTerms.shiftCount,
        calendarShiftCount: item.rentTerms.shiftCount,
        discount: item.rentTerms.discount,
        instanceCount: instanceCount !== undefined ? instanceCount : item.instanceCount,
        instanceCountOriginal: instanceCount !== undefined ? instanceCount : item.instanceCount,
        finalTotalPrice: item.finalTotalPrice,
        effectivePrice: item.effectivePricingScheme ? item.basePriceAtTheTimeOfCreation : item.effectivePrice,
        //stockInstanceCountX: 0,
        availableInstanceCount: 0,

        discountChanged: false,
        rentPeriodChanged: false,
        shiftCountChanged: false,
        disabled: code === OperationTypeCodeEnum.CANCEL,

        instanceIds: item.instanceIds,
        instanceIdsOriginal: item.instanceIds ? [...item.instanceIds] : undefined,
        variantId: item.variantId,
        variantName: item.variantName,
        instanceTrackingTypeCode: item.instanceTrackingTypeCode,

        rentPeriodStartDateOriginal: moment(itemStartDate).toDate(),
        rentPeriodEndDateOriginal: moment(itemEndDate).toDate(),

        mainImage: item.image,
        parentId: item.rentElementsKitId,
        kitId: item.kitId,
        warnings: [],
        problems: [],
        partOfKit,
        anonymousInstanceCount: item.anonymousInstanceCount,
        //anonymousInstanceCountOriginal: data.anonymousInstanceCount

        leftoverInstanceCount: 0,
        leftoverAnonymousInstanceCount: 0,
        keepLeftover: getKeepLeftoverByElementStateCode(item.stateCode),

        projectId: item.projectId,
        templateId: item.templateId,
        renterId: item.renterId,

        variantIdOriginal: item.variantId,
        externalCode: item.externalCode,

        pricingSchemeId: item.effectivePricingScheme
    };
};

export const createOperation = async (
    intl:IntlShape,
    projectTemplate: boolean,
    startNewOperation: (isSubrent: boolean|undefined, taxRate: number | undefined, taxBaseType: TaxBaseTypeCodeEnum | undefined, projectTemplate: boolean, renterId: number|undefined, renterShortName: string|undefined, projectId: number, projectShortName: string, projectType: ProjectTypeCodeEnum | undefined, typeCode: OperationTypeCodeEnum, elements: OperationElement[], mnemoKey: string, fromRequest: boolean, discount?: number, targetStateCode?: RentStateCodeEnum, productStackMapLists?: Array<ProductStackMapList|KitStackMapList>, instanceStackMapList?:Array<InstanceStackMapList>, timeTables?:TimeTable[], elementsDelayedReturnDates?: { [key: number]: Date; }, setActualDate?: boolean, recalculateShiftCount?: boolean, shiftCount?: number, startDate?: any, endDate?: any) => void,
    businessAccountId: number,
    code: OperationTypeCodeEnum,
    selectedItems: Array<RentElementsGridItem>,
    targetStateCode?: RentStateCodeEnum,
    instanceCount?: number
) => {
    return new Promise<void>(async (resolve, reject) => {

        let taxRate:number|undefined;
        let taxBaseType:TaxBaseTypeCodeEnum|undefined;

        let recalculateShiftCount = false;
        let setActualDate = false;
        if(
            //isRentOperation(targetStateCode) || isReturnOperation(targetStateCode) ||
            code === OperationTypeCodeEnum.RENT ||
            code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
            code === OperationTypeCodeEnum.RETURN ||
            code === OperationTypeCodeEnum.RETURNBROKEN ||
            code === OperationTypeCodeEnum.LOSTNORETURN ||
            code === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER
        ) {
            let title = '';
            if (code === OperationTypeCodeEnum.RENT || code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT) title = 'Установить текущую дату выдачи?';
            else title = 'Установить текущую дату возврата?';

            const operationType = code === OperationTypeCodeEnum.RENT ? 'rent' : code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ? 'subrentAcceptShipment' : code === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ? 'subrentReturnToShipper' : 'return';
            let setActualDateRes = await showActualizeDatesConfirm(
                intl,
                title,
                operationType
            );
            setActualDate = setActualDateRes.setActualDate;
            recalculateShiftCount = setActualDateRes.recalculateShiftCount;
        }

        let prolongData:SetNewReturnDatesForProlongConfirmResult|undefined;
        if(code === OperationTypeCodeEnum.PROLONG || code === OperationTypeCodeEnum.SUBRENTPROLONG){
            const dates = selectedItems.map(el => moment(el.rentTerms.rentPeriodEndDate).valueOf());
            prolongData = await setNewReturnDatesBeforeProlongOperationConfirm(intl, new Date(Math.max(...dates)));
            setActualDate = prolongData.setNewDate;
        }

        let startDate = Number.MAX_VALUE,
            endDate = 0,
            elements: OperationElement[] = selectedItems.map((item: RentElementsGridItem) => {
                let itemStartDate = item.rentTerms.rentPeriodStartDate;
                let itemEndDate = item.rentTerms.rentPeriodEndDate;

                // Это хак для шаблонов
                if(projectTemplate){
                    itemStartDate = new Date();
                    itemEndDate = new Date(Date.now() + 100000);
                }

                if(prolongData ){
                    if(prolongData.setNewDate && prolongData.newData){
                        itemEndDate = prolongData.newData;
                    }
                    if(prolongData.recalculateShiftCount){
                        recalculateShiftCount = prolongData.recalculateShiftCount;
                    }
                }

                startDate = Math.min(startDate, moment(itemStartDate).valueOf());
                endDate = Math.max(endDate, moment(itemEndDate).valueOf());

                if(isRentOperation(code)){
                    startDate = Date.now();
                }else if(isReturnOperation(code)){
                    endDate = Date.now();
                }

                let partOfKit;
                if(item.nomenclatureEntityTypeCode === 'KIT' && item['subRows']){
                    let kitMembers = selectedItems.filter((i) => i.rentElementsKitId === item.id);
                    if(item['subRows'].length !== kitMembers.length) partOfKit = true;
                }

                const _instanceCount = instanceCount !== undefined ? instanceCount : item.instanceCount;
                let _instanceCountOriginal = item.instanceCount;
                let _instanceIds = item.instanceIds;
                let _instanceIdsOriginal = _instanceIds ? [..._instanceIds] : undefined;
                let _anonymousInstanceCount = item.anonymousInstanceCount;

                if(instanceCount !== undefined){
                    if(item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED){
                        _instanceCountOriginal = item.instanceCount;
                        if(instanceCount <= item.anonymousInstanceCount){
                            _anonymousInstanceCount = instanceCount;
                            _instanceIds = [];
                        }else{
                            const instancesToAdd = instanceCount - item.anonymousInstanceCount;
                            _anonymousInstanceCount = instanceCount - instancesToAdd;
                            _instanceIds = (item.instanceIds||[]).slice(0, instancesToAdd);
                        }
                    }
                }

                return {
                    id: item.id,
                    numberInActivityFrame: item.numberInActivityFrame,
                    businessVersion: item.businessVersion,
                    productId: item.productId,
                    productShortName: item.nomenclatureShortName,
                    productMainImage: item.image,
                    stateCode: item.stateCode,
                    rentPeriodStartDate: moment(itemStartDate).toDate(),
                    rentPeriodEndDate: moment(itemEndDate).toDate(),
                    shiftCount: item.rentTerms.shiftCount,
                    calendarShiftCount: item.rentTerms.shiftCount,
                    discount: item.rentTerms.discount,
                    instanceCount: _instanceCount,
                    instanceCountOriginal: _instanceCountOriginal,
                    finalTotalPrice: item.finalTotalPrice,
                    effectivePrice: item.effectivePricingScheme ? item.basePriceAtTheTimeOfCreation : item.effectivePrice,
                    //stockInstanceCountX: 0,
                    availableInstanceCount: 0,

                    discountChanged: false,
                    rentPeriodChanged: false,
                    shiftCountChanged: false,
                    disabled: code === OperationTypeCodeEnum.CANCEL,

                    instanceIds: _instanceIds,
                    instanceIdsOriginal: _instanceIdsOriginal,
                    variantId: item.variantId,
                    variantName: item.variantName,
                    instanceTrackingTypeCode: item.instanceTrackingTypeCode,

                    rentPeriodStartDateOriginal: moment(itemStartDate).toDate(),
                    rentPeriodEndDateOriginal: moment(itemEndDate).toDate(),

                    mainImage: item.image,
                    parentId: item.rentElementsKitId,
                    kitId: item.kitId,
                    warnings: [],
                    problems: [],
                    partOfKit,
                    anonymousInstanceCount: _anonymousInstanceCount,
                    //anonymousInstanceCountOriginal: data.anonymousInstanceCount

                    leftoverInstanceCount: 0,
                    leftoverAnonymousInstanceCount: 0,
                    keepLeftover: getKeepLeftoverByElementStateCode(item.stateCode),

                    projectId: item.projectId,
                    templateId: item.templateId,
                    renterId: item.renterId,

                    variantIdOriginal: item.variantId,
                    externalCode: item.externalCode,

                    availableTransitionCodes: item.availableTransitionCodes || undefined,

                    pricingSchemeId: item.effectivePricingScheme
                };
            }),
            firstElement: RentElementsGridItem = selectedItems[0];

        let timeTables:TimeTable[] = [];
        let elementsDelayedReturnDates = {};

        if (firstElement) {
            let type = code;
            let projectId = firstElement.projectId || firstElement.templateId || firstElement.subrentId;
            let projectName = firstElement.projectShortName || firstElement.templateShortName || firstElement.subrentShortName;
            let renterId = firstElement.renterId;

            let renterName = firstElement.renterShortName;

            //let instancesIntervals:Array<InstanceStackMapList> = [];

            try{
                if(firstElement.projectId){
                    let project = await serverApi.getProjectById(businessAccountId, firstElement.projectId);
                    taxRate = project.data.taxRate;
                    taxBaseType = project.data.taxBaseType;
                }
            }catch (err){
                return reject(err);
            }

            /*try{
                let instanceIds:number[] = [];
                elements.forEach(element => {
                    if(element.instanceIds){
                        element.instanceIds.forEach(id => {
                            if(!instanceIds.includes(id)) instanceIds.push(id);
                        })
                    }
                });

                if(instanceIds.length > 0){

                    let possibleDelayedRentElementIds = elements.filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT).map(item => item.id);

                    let request = await serverApi.listInstancesOnInterval(
                        businessAccountId,
                        startDate - 365 * 1 * 24 * 60 * 60 * 1000,
                        endDate + 365 * 2 * 24 * 60 * 60 * 1000,
                        {
                            possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                            listFilters: [`id;IN;${instanceIds.join(';')}`]
                        },
                        instanceIds.length,
                        0,
                        undefined,
                        undefined,
                        undefined,
                        [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
                        );

                    request.data.elementsDelayedReturnDates?.forEach((item)=>{
                        elementsDelayedReturnDates[item.id] = new Date(item.date);
                    });

                    instancesIntervals = request.data.records
                        .filter(product => product.stackMapList)
                        .map(product => {
                            return {productId: product.productId, variantId:product.variantId, instanceId: product.id, list:  product.stackMapList};
                        });

                    saveOrUpdateTimetables(request.data.records, timeTables, targetStateCode || code);
                }
            }catch(e) {
                return reject(e);
            }*/

            try {
                /*let filters = [
                    'productId;IN;' +
                    elements
                        .map(item => item.productId)
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .join(';')
                ];
                if(elements.filter(item => item.variantId).length > 0){
                    filters.push('variantId;IN;' +
                        elements
                            .map(item => item.variantId || -1)
                            .filter((v, i, a) => a.indexOf(v) === i)
                            .join(';'));
                }

                filters.push('identifiesRentElementAvailability;EQ;true');

                let possibleDelayedRentElementIds = elements.filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT).map(item => item.id);

                let productsResponse = await serverApi.listNomenclatureOnInterval(
                    businessAccountId,
                    startDate - 365 * 1 * 24 * 60 * 60 * 1000,
                    endDate + 365 * 2 * 24 * 60 * 60 * 1000,
                    {
                        possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                        listFilters: filters
                    },
                    1000,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
                );


                productsResponse.data.elementsDelayedReturnDates?.forEach((item)=>{
                    elementsDelayedReturnDates[item.id] = new Date(item.date);
                });

                let products = productsResponse.data.records;

                let productIntervals:Array<ProductStackMapList|KitStackMapList> = products
                    .filter(product => product.stackMapList)
                    .map(product => {
                        return {productId: product.productId, variantId:product.variantId, list: product.stackMapList, timetableVersion: product.timetableVersion};
                    });

                saveOrUpdateTimetables(products, timeTables, targetStateCode || code);
                timeTables = JSON.parse(JSON.stringify(timeTables));

                elements.forEach((element) => {
                    let p:NomenclatureRecord|undefined;
                    products.forEach((product)=>{
                        if(element.productId === product.productId && element.variantId === product.variantId){
                            p = product;
                        }
                    });
                    if(p){
                        element.shiftLengthInMinutes = p.shiftLengthInMinutes;
                        element.hasOwnShiftLength = p.productHasOwnShiftLength;

                        element.requiredTimeIndentBetweenElementsInMinutes = p.requiredTimeIndentBetweenElementsInMinutes;
                        element.productHasOwnRequiredTimeIndentBetweenElements = p.productHasOwnRequiredTimeIndentBetweenElements;
                    }
                });*/

                // const nomenclaturesToLoad = getAllNomenclatureIntervalsForRentElements(selectedItems);
                // const possibleDelayedRentElementIds = getPossibleDelayedRentElementIds(selectedItems);
                // const res = await loadNomenclaturesOnIntervals(businessAccountId, nomenclaturesToLoad, [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER], possibleDelayedRentElementIds);
                const targetStackTypeCodes = getTargetStackTypeCodes({isProjectTemplate: projectTemplate, isOrderOperation: isOrderOperation(code)});
                const res = await loadAllNomenclaturesForRentElements(businessAccountId, selectedItems, targetStackTypeCodes);
                timeTables = nomenclaturesToTimetables(res.nomenclatures);
                res.elementsDelayedReturnDates.forEach((item)=>{
                    elementsDelayedReturnDates[item.id] = item.date;
                });
                elements.forEach((element) => {
                    const n = rentElementToNomenclatureBaseObj(element);
                    if(n && (n.type === NomenclatureEntityTypeCodeEnum.PRODUCT || n.type === NomenclatureEntityTypeCodeEnum.VARIANT)){
                        let nn = findNomenclature(n.id, n.type, res.nomenclatures);
                        if(nn?.nomenclature){
                            const p = nn.nomenclature as NomenclatureRecord;
                            element.shiftLengthInMinutes = p.shiftLengthInMinutes;
                            element.hasOwnShiftLength = p.productHasOwnShiftLength;

                            element.requiredTimeIndentBetweenElementsInMinutes = p.requiredTimeIndentBetweenElementsInMinutes;
                            element.productHasOwnRequiredTimeIndentBetweenElements = p.productHasOwnRequiredTimeIndentBetweenElements;
                        }
                    }
                });

                let isSubrent:boolean|undefined;
                if(firstElement && firstElement.subrentId) isSubrent = true;

                startNewOperation(
                    isSubrent,
                    taxRate,
                    taxBaseType,
                    projectTemplate,
                    renterId,
                    renterName,
                    projectId || -1,
                    projectName || '',
                    undefined,
                    type,
                    elements,
                    'mnemoKey',
                    false,
                    0,
                    targetStateCode,
                    [],
                    [],
                    timeTables,
                    elementsDelayedReturnDates,
                    setActualDate,
                    recalculateShiftCount,
                );
                resolve();
            } catch (e) {
                console.error(e);
                return reject(e);
            }
        }
    });

};


export const createNewDraftOperation = async (
    typeCode: OperationTypeCodeEnum.DRAFT | OperationTypeCodeEnum.SUBRENTDRAFT,
    projectTemplate: boolean,
    startNewOperation: (isSubrent: boolean|undefined, taxRate: number | undefined, taxBaseType: TaxBaseTypeCodeEnum | undefined, projectTemplate: boolean, renterId: number|undefined, renterShortName: string|undefined, projectId: number, projectShortName: string, projectType: ProjectTypeCodeEnum | undefined, typeCode: OperationTypeCodeEnum, elements: OperationElement[], mnemoKey: string, fromRequest: boolean, discount?: number, targetStateCode?: RentStateCodeEnum, productStackMapLists?: Array<ProductStackMapList|KitStackMapList>, instanceStackMapList?:Array<InstanceStackMapList>, timeTables?:TimeTable[], elementsDelayedReturnDates?: { [key: number]: Date; }, setActualDate?: boolean, recalculateShiftCount?: boolean, shiftCount?: number, startDate?: any, endDate?: any) => void,
    businessAccountId: number,
    projectId: number,
    projectShortName: string,
    renterId: number|undefined,
    renterShortName: string|undefined,
    discount?:number,
    shiftCount?: number
) => {
    return new Promise<void>(async (resolve, reject) => {

        try {

            const mnemoKeyResponse: AxiosResponse<string> = await serverApi.getGeneratedField(
                businessAccountId,
                projectTemplate ? (typeCode === OperationTypeCodeEnum.DRAFT ? 'template_operation' : 'subrent_operation') : (typeCode === OperationTypeCodeEnum.DRAFT ? 'project_operation' : 'subrent_operation'),
                'mnemoKey',
                projectId,
                typeCode
            );

            //let discount:number|undefined = discount;
            if(discount === undefined){
                discount = 0;
                if(projectTemplate){
                    const projectResponse: AxiosResponse<TemplateInfoRead> = await serverApi.getTemplateById(
                        businessAccountId,
                        projectId
                    );
                    discount = projectResponse.data.defaultDiscount;
                }else{
                    if(typeCode === OperationTypeCodeEnum.DRAFT){
                        const projectResponse: AxiosResponse<ProjectInfoRead> = await serverApi.getProjectById(
                            businessAccountId,
                            projectId
                        );
                        discount = projectResponse.data.defaultDiscount;
                    }else{
                        const projectResponse: AxiosResponse<SubrentInfoRead> = await serverApi.getSubrentById(
                            businessAccountId,
                            projectId
                        );
                        discount = projectResponse.data.supplierDefaultDiscount;
                    }
                }
            }

            startNewOperation(
                undefined,
                undefined,
                undefined,
                projectTemplate,
                renterId,
                renterShortName,
                projectId,
                projectShortName,
                undefined,
                typeCode,
                [],
                mnemoKeyResponse.data,
                false,
                discount,
                undefined,
                undefined,
                undefined,
                [],
                {},
                undefined,
                undefined,
                shiftCount,
                projectTemplate ? new Date(Date.now() + 61 * 1000) : undefined, projectTemplate ? new Date(Date.now() + 61 * 2 * 1000) : undefined
            );
            resolve();
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

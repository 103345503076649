import React from 'react';
import Trigger from 'rc-trigger';
import { TimeRangePickerPanel } from './timeRangePickerPanel';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { Icon } from 'antd';
import { generateArrayOfNumbers } from '../../shared/util/utils2';
import { IconClockO } from '../icons';
import './timeRangePicker.less';
import { RequiredTimeIndentBetweenElementsInMinutesIcon } from './requiredTimeIndentBetweenElementsInMinutesIcon';
import moment, {Moment} from 'moment';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

interface IProps {
    minutesStep: number;
    onChange?: (value) => void;
    value?: RangePickerValue;
    requiredTimeIndentBetweenElementsInMinutes?: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    showRequiredTimeIndentBetweenElementsInMinutesIcon?: boolean;
    getTodayDate?: ()=>Moment;
    onStartDateTodayButtonClick1?:(()=>void)|null;
    onEndDateTodayButtonClick1?:(()=>void)|null;
}

interface IState {
    visibleStart?: boolean;
    visibleEnd?: boolean;
    value: RangePickerValue;
    startSelected?: moment.Moment;
    endSelected?: moment.Moment;
    focusStart: boolean;
    focusEnd: boolean;
    startV?: string;
    endV?: string;
}

export class TimeRangePicker extends React.Component<IProps, IState> {
    private inputStartRef;
    private inputEndRef;

    constructor(props: IProps) {
        super(props);
        this.state = {
            visibleStart: false,
            visibleEnd: false,
            value: [],
            focusStart: false,
            focusEnd: false,
            startV: this.props.value && this.props.value[0] ? moment(this.props.value[0]).format('HH:mm') : undefined,
            endV: this.props.value && this.props.value[1] ? moment(this.props.value[1]).format('HH:mm') : undefined,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!isEqual(prevProps.value, this.props.value)) {
            this.setState({
                startV: this.props.value && this.props.value[0] ? moment(this.props.value[0]).format('HH:mm') : undefined,
                endV: this.props.value && this.props.value[1] ? moment(this.props.value[1]).format('HH:mm') : undefined,
            });
        }
    }

    onPopupVisibleChangeStart = (visible) => {
        if ((visible && this.state.focusStart) || (!visible && !this.state.focusStart)) {
            this.setState({
                visibleStart: visible,
            });
        }
        if (!visible && !this.state.focusStart) {
            this.setState({
                value: [undefined, this.state.value[1] as moment.Moment],
                startSelected: undefined,
                focusStart: false,
            });
            if (this.inputStartRef) this.inputStartRef.blur();
        }
    };

    onPopupVisibleChangeEnd = (visible) => {
        if ((visible && this.state.focusEnd) || (!visible && !this.state.focusEnd)) {
            this.setState({
                visibleEnd: visible,
            });
        }
        if (!visible && !this.state.focusEnd) {
            this.setState({
                value: [this.state.value[0] as moment.Moment, undefined],
                endSelected: undefined,
                focusEnd: false,
            });
            if (this.inputEndRef) this.inputEndRef.blur();
        }
    };

    render() {
        const { showRequiredTimeIndentBetweenElementsInMinutesIcon = true } = this.props;

        let startDate = this.state.value && this.state.value[0] ? this.state.value[0] : undefined;
        let endDate = this.state.value && this.state.value[1] ? this.state.value[1] : undefined;

        if (!startDate && this.props.value && this.props.value[0]) startDate = this.props.value[0];
        if (!endDate && this.props.value && this.props.value[1]) endDate = this.props.value[1];

        let disabledStartHours: number[] = [];
        let disabledStartMinutes: number[] = [];
        let disabledEndHours: number[] = [];
        let disabledEndMinutes: number[] = [];

        let startSelected = this.state.startSelected || startDate;
        let endSelected = this.state.endSelected || endDate;

        if (
            startDate &&
            endDate &&
            startDate.year() === endDate.year() &&
            startDate.month() === endDate.month() &&
            startDate.date() === endDate.date()
        ) {
            if (this.state.visibleStart) {
                // Открыто начало
                disabledStartHours = generateArrayOfNumbers(endDate.hours() + 1, 24);
                if (startSelected && endDate.hours() === startSelected.hours())
                    disabledStartMinutes = generateArrayOfNumbers(endDate.minutes(), 60);
            } else if (this.state.visibleEnd) {
                // Открыт конец
                disabledEndHours = generateArrayOfNumbers(0, startDate.hours());
                if (endSelected && startDate.hours() === endSelected.hours())
                    disabledEndMinutes = generateArrayOfNumbers(0, startDate.minutes() + 1);
            }
        }

        const currentTimeButtonEnabled1a = this.props.onStartDateTodayButtonClick1 === undefined ? undefined : Boolean(this.props.onStartDateTodayButtonClick1);
        const currentTimeButtonEnabled1b = this.props.onEndDateTodayButtonClick1 === undefined ? undefined : Boolean(this.props.onEndDateTodayButtonClick1);

        return (
            <>
                <span className="ant-calendar-picker" style={{ maxWidth: 310, cursor: 'default' }}>
                    <span
                        className={classNames('ant-calendar-picker-input', 'ant-input')}
                        style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                    >
                        {showRequiredTimeIndentBetweenElementsInMinutesIcon && (
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    top: 0,
                                    left: 0,
                                    width: 60,
                                    borderRight: '1px solid #dcdfe8',
                                    height: '100%',
                                }}
                            >
                                <RequiredTimeIndentBetweenElementsInMinutesIcon
                                    style={{ color: '#a547a1', fontSize: 20 }}
                                    productHasOwnRequiredTimeIndentBetweenElements={
                                        this.props.productHasOwnRequiredTimeIndentBetweenElements
                                    }
                                    timeInMinutes={this.props.requiredTimeIndentBetweenElementsInMinutes || 0}
                                />
                            </span>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 12,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: -1,
                                }}
                            >
                                <Trigger
                                    popupTransitionName={'rc-trigger-popup-zoom'}
                                    destroyPopupOnHide
                                    popupVisible={this.state.visibleStart}
                                    onPopupVisibleChange={this.onPopupVisibleChangeStart}
                                    stretch={['width']}
                                    popupAlign={{
                                        points: ['tc', 'bc'],
                                        offset: [-11, -314 + 7],
                                    }}
                                    action={['click']}
                                    popup={
                                        <TimeRangePickerPanel
                                            disabledHours={disabledStartHours}
                                            disabledMinutes={disabledStartMinutes}
                                            value={startDate}
                                            minutesStep={this.props.minutesStep}
                                            onChange={(value) => {
                                                let newDates = this.state.value;
                                                let endDate = this.state.value && this.state.value[1] ? this.state.value[1] : undefined;
                                                if (!endDate && this.props.value && this.props.value[1]) endDate = this.props.value[1];
                                                if (endDate && value.isSameOrAfter(endDate)) {
                                                    value = endDate.clone().add(-1, 'm');
                                                }
                                                if (newDates) newDates[0] = value;
                                                this.setState({
                                                    visibleStart: false,
                                                    value: [undefined, this.state.value[1] as moment.Moment],
                                                    startSelected: undefined,
                                                    startV: value.format('HH:mm'),
                                                });
                                                if (this.props.onChange) this.props.onChange(newDates);
                                            }}
                                            onSelect={(value) => {
                                                let newDates = this.state.value;
                                                let endDate = this.state.value && this.state.value[1] ? this.state.value[1] : undefined;
                                                if (!endDate && this.props.value && this.props.value[1]) endDate = this.props.value[1];
                                                if (endDate && value.isSameOrAfter(endDate)) {
                                                    value = endDate.clone().add(-1, 'm');
                                                    this.setState({ visibleEnd: false });
                                                }
                                                if (newDates) newDates[0] = value;
                                                this.setState({
                                                    startSelected: value,
                                                    value: newDates,
                                                    startV: value.format('HH:mm'),
                                                });
                                                if (this.props.onChange) this.props.onChange(newDates);
                                            }}
                                            onSetCurrentTime={this.props.onStartDateTodayButtonClick1}
                                            currentTimeButtonEnabled={currentTimeButtonEnabled1a}
                                        />
                                    }
                                >
                                    <input
                                        ref={(ref) => {
                                            if (!this.inputStartRef) this.inputStartRef = ref;
                                        }}
                                        placeholder="Время"
                                        className="ant-calendar-range-picker-input"
                                        style={{ fontSize: 16, fontWeight: 500, width: 100 }}
                                        defaultValue={this.state.startV}
                                        value={this.state.startV}
                                        onFocus={(e) => {
                                            let t = e.currentTarget;
                                            setTimeout(() => {
                                                t.setSelectionRange(0, 5);
                                            }, 100);
                                            this.setState({
                                                focusStart: true,
                                                visibleEnd: false,
                                            });
                                        }}
                                        onBlurCapture={(e) => {
                                            let s = window.getSelection();
                                            if (s && s.removeRange) {
                                                for (let i = 0; i < s.rangeCount; i++) {
                                                    s.removeRange(s.getRangeAt(i));
                                                }
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({
                                                focusStart: false,
                                                startV: moment(this.state.value[0] || (this.props.value && this.props.value[0])).format(
                                                    'HH:mm'
                                                ),
                                            });
                                        }}
                                        onChange={(e) => {
                                            let str = e.target.value;
                                            let t = e.currentTarget;

                                            if (str === '') {
                                                this.setState({
                                                    startV: str,
                                                });
                                            } else if (str.match(/^[а-яf-z0-9]$/)) {
                                                this.setState({
                                                    startV: str,
                                                });
                                            } else if (str.match(/^[а-яf-z0-9]{2}$/)) {
                                                if (this.state.startV && this.state.startV[this.state.startV?.length - 1] === ':') {
                                                    this.setState({
                                                        startV: str.substr(0, str.length - 1),
                                                    });
                                                } else {
                                                    this.setState({
                                                        startV: str + ':',
                                                    });
                                                }
                                            } else if (str.match(/^[а-яf-z0-9]{2}:[а-яf-z0-9]{0,1}$/)) {
                                                this.setState({
                                                    startV: str,
                                                });
                                            } else if (str.match(/^[а-яf-z0-9]{2}:[а-яf-z0-9]{2}$/)) {
                                                let res = str.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
                                                if (res) {
                                                    let startDate =
                                                        this.state.value && this.state.value[0] ? this.state.value[0] : undefined;
                                                    if (!startDate && this.props.value && this.props.value[0])
                                                        startDate = this.props.value[0];

                                                    let endDate = this.state.value && this.state.value[1] ? this.state.value[1] : undefined;
                                                    if (!endDate && this.props.value && this.props.value[1]) endDate = this.props.value[1];

                                                    if (startDate) {
                                                        let h = str.split(':')[0];
                                                        let m = str.split(':')[1];
                                                        let newDate = startDate.clone();
                                                        newDate.hours(+h).minutes(+m);
                                                        if (endDate && startDate) {
                                                            if (newDate.isSameOrAfter(endDate)) {
                                                                newDate = endDate.clone().add(-1, 'm');
                                                                str = newDate.format('HH:mm');
                                                            }
                                                        }

                                                        this.setState({
                                                            value: [newDate, endDate] as any,
                                                            startV: str,
                                                        });
                                                        if (this.props.onChange) this.props.onChange([newDate, endDate] as any);
                                                    }
                                                } else {
                                                    this.setState(
                                                        {
                                                            startV: moment(
                                                                this.state.value[0] || (this.props.value && this.props.value[0])
                                                            ).format('HH:mm'),
                                                        },
                                                        () => {
                                                            t.setSelectionRange(0, 5);
                                                        }
                                                    );
                                                }
                                                t.setSelectionRange(0, 5);
                                            }
                                        }}
                                    />
                                </Trigger>

                                <span className="ant-calendar-range-picker-separator"> → </span>

                                <Trigger
                                    popupTransitionName={'slide-up'}
                                    destroyPopupOnHide
                                    popupVisible={this.state.visibleEnd}
                                    onPopupVisibleChange={this.onPopupVisibleChangeEnd}
                                    stretch={['width']}
                                    popupAlign={{
                                        points: ['tc', 'bc'],
                                        offset: [-11, -314 + 7],
                                    }}
                                    action={['click']}
                                    popup={
                                        <TimeRangePickerPanel
                                            disabledHours={disabledEndHours}
                                            disabledMinutes={disabledEndMinutes}
                                            value={endDate}
                                            minutesStep={this.props.minutesStep}
                                            onChange={(value) => {
                                                let newDates = this.state.value;

                                                let startDate = this.state.value && this.state.value[0] ? this.state.value[0] : undefined;
                                                if (!startDate && this.props.value && this.props.value[0]) startDate = this.props.value[0];
                                                if (startDate && value.isSameOrBefore(startDate)) {
                                                    value = startDate.clone().add(1, 'm');
                                                }

                                                if (newDates) newDates[1] = value;
                                                this.setState({
                                                    visibleEnd: false,
                                                    value: [this.state.value[0] as moment.Moment, undefined],
                                                    endSelected: undefined,
                                                    endV: value.format('HH:mm'),
                                                });
                                                if (this.props.onChange) this.props.onChange(newDates);
                                            }}
                                            onSelect={(value) => {
                                                let newDates = this.state.value;

                                                let startDate = this.state.value && this.state.value[0] ? this.state.value[0] : undefined;
                                                if (!startDate && this.props.value && this.props.value[0]) startDate = this.props.value[0];
                                                if (startDate && value.isSameOrBefore(startDate)) {
                                                    value = startDate.clone().add(1, 'm');
                                                    this.setState({ visibleEnd: false });
                                                }

                                                if (newDates) newDates[1] = value;
                                                this.setState({
                                                    endSelected: value,
                                                    value: newDates,
                                                    endV: value.format('HH:mm'),
                                                });
                                                if (this.props.onChange) this.props.onChange(newDates);
                                            }}
                                            onSetCurrentTime={this.props.onEndDateTodayButtonClick1}
                                            currentTimeButtonEnabled={currentTimeButtonEnabled1b}
                                        />
                                    }
                                >
                                    <input
                                        ref={(ref) => {
                                            if (!this.inputEndRef) this.inputEndRef = ref;
                                        }}
                                        placeholder="Время"
                                        className="ant-calendar-range-picker-input"
                                        style={{ fontSize: 16, fontWeight: 500, width: 100 }}
                                        defaultValue={this.state.endV}
                                        value={this.state.endV}
                                        onFocus={(e) => {
                                            let t = e.currentTarget;
                                            setTimeout(() => {
                                                t.setSelectionRange(0, 5);
                                            }, 100);
                                            this.setState({
                                                focusEnd: true,
                                                visibleStart: false,
                                            });
                                        }}
                                        onBlurCapture={(e) => {
                                            let s = window.getSelection();
                                            if (s) {
                                                for (let i = 0; i < s.rangeCount; i++) {
                                                    s.removeRange(s.getRangeAt(i));
                                                }
                                            }
                                        }}
                                        onBlur={() => {
                                            this.setState({
                                                focusEnd: false,
                                                endV: moment(this.state.value[1] || (this.props.value && this.props.value[1])).format(
                                                    'HH:mm'
                                                ),
                                            });
                                        }}
                                        onChange={(e) => {
                                            let str = e.target.value;
                                            let t = e.currentTarget;

                                            if (str === '') {
                                                this.setState({
                                                    endV: str,
                                                });
                                            } else if (str.match(/^[а-яf-z0-9]$/)) {
                                                this.setState({
                                                    endV: str,
                                                });
                                            } else if (str.match(/^[а-яf-z0-9]{2}$/)) {
                                                if (this.state.endV && this.state.endV[this.state.endV?.length - 1] === ':') {
                                                    this.setState({
                                                        endV: str.substr(0, str.length - 1),
                                                    });
                                                } else {
                                                    this.setState({
                                                        endV: str + ':',
                                                    });
                                                }
                                            } else if (str.match(/^[а-яf-z0-9]{2}:[а-яf-z0-9]{0,1}$/)) {
                                                this.setState({
                                                    endV: str,
                                                });
                                            } else if (str.match(/^[а-яf-z0-9]{2}:[а-яf-z0-9]{2}$/)) {
                                                let res = str.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
                                                if (res) {
                                                    let startDate =
                                                        this.state.value && this.state.value[0] ? this.state.value[0] : undefined;
                                                    if (!startDate && this.props.value && this.props.value[0])
                                                        startDate = this.props.value[0];

                                                    let endDate = this.state.value && this.state.value[1] ? this.state.value[1] : undefined;
                                                    if (!endDate && this.props.value && this.props.value[1]) endDate = this.props.value[1];

                                                    if (endDate) {
                                                        let h = str.split(':')[0];
                                                        let m = str.split(':')[1];
                                                        let newDate = endDate.clone();
                                                        newDate.hours(+h).minutes(+m);
                                                        if (endDate && startDate) {
                                                            if (newDate.isSameOrBefore(startDate)) {
                                                                newDate = startDate.clone().add(1, 'm');
                                                                str = newDate.format('HH:mm');
                                                            }
                                                        }

                                                        this.setState({
                                                            value: [this.state.value[0], newDate] as any,
                                                            endV: str,
                                                        });
                                                        if (this.props.onChange) this.props.onChange([this.state.value[0], newDate] as any);
                                                    }
                                                } else {
                                                    this.setState(
                                                        {
                                                            endV: moment(
                                                                this.state.value[1] || (this.props.value && this.props.value[1])
                                                            ).format('HH:mm'),
                                                        },
                                                        () => {
                                                            t.setSelectionRange(0, 5);
                                                        }
                                                    );
                                                }

                                                t.setSelectionRange(0, 5);
                                            }
                                        }}
                                    />
                                </Trigger>
                            </div>

                            <span className={'time-picker-icon'} style={{ fontSize: 20, display: 'flex', alignItems: 'center' }}>
                                <Icon component={IconClockO} />
                            </span>
                        </div>
                    </span>
                </span>
            </>
        );
    }
}

import React from 'react';
import {
    ConcurrentOperationObj,
    ConcurrentOperationObjList,
    IdDatePairObj,
    ImageObj,
    InstanceInfoRead,
    InstanceRecord,
    InstanceTrackingTypeCodeEnum,
    KitInfoRead,
    KitMemberObjRead,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    NomenclatureRecordList,
    OperationInfoCreate,
    OperationInfoRead,
    OperationTypeCodeEnum,
    PricingSchemeExternalRepresentationObj,
    ProblemAndWarningRentIndicatorsRecordObj,
    ProjectInfoRead,
    ProjectTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RentElementInfoWrite,
    RentElementRecordList,
    RentElementsKitObjWrite,
    RentStateCodeEnum,
    serverApi,
    ServerError,
    ShiftCountRoundingTypeCodeEnum,
    TaxBaseTypeCodeEnum,
    TimetableList,
    TimetableTypeCodeEnum,
} from '../../../../server';
import {IRootState} from '../../../../shared/reducers';
import {push, replace} from 'connected-react-router';
import {Action} from 'redux';
import {AxiosError, AxiosPromise, AxiosResponse} from 'axios';
import {showNotification} from '../../../../components/notification/showNotification';
import {ProductUtils} from '../../../../shared/util/productUtils';
import {getServerError, getShiftCountFromDates, getStringServerProblem, isDefined, test1} from '../../../../shared/util/utils';
import moment, {Moment} from 'moment';
import {resetBusinessAccountIndicators} from '../../../../shared/reducers/businessAccountIndicators.reducer';
import clone from 'lodash/clone';
import {
    canBeErrorWhenShortage,
    canCreateOperationWithAnonymousInstances,
    createNewOperationElementFromKitMemember,
    findTimeTable,
    getLeftoverInstances,
    isOrderOperation,
    isRentOperation,
    isRentOrSubrentOperation,
    isReturnOperation,
    isReturnOrSubrentReturnOperation,
    isSubrentOperation,
    operationElementCanBeCancelled,
    OperationErrorCodeEnum,
} from '../utils/utils';
import {getCurrentServerTime} from '../../../../shared/reducers/system.reducer';
import {convertRentElementRecordToRentElementsGridItem, ProblemEnum, RentElementsGridItem} from '../../../../types';
import {getHideStateCodeFilterTypes} from '../../../../shared/util/utils4';
import {OperationFormUtils} from '../utils/operationFormUtils';
import sortByFn from 'lodash/sortBy';
import {ThunkAction} from 'redux-thunk';
import {MoneyUtils} from '../../../../core/utils/moneyUtils';
import {PricingSchemeUtils} from '../../../../core/utils/pricingSchemeUtils';
import {gridDataChangedSignal} from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import {ListParams} from '../../../../components/page/entityGrid/types/params';
import {getStore, RootState} from '../../../../../index';
import {
    findTimeTableById,
    getElementEndDate,
    getElementStartDate, getTargetStackTypeCodes,
    loadAllNomenclaturesForRentElements,
    loadNomenclaturesOnIntervalsIfNeed,
    NomenclatureOnInterval,
    nomenclaturesToTimetables,
} from '../utils/nomenclaturesUtils';
import {getIsCorrectCancelOperation, getIsElementReducesAvailability} from '../utils/operationUtils';
import {getNomenclatureAvailability, xxx20, xxx21} from '../utils/availabilityUtils';

import {
    OfferPageTabsEnum,
    ProjectPageTabsEnum,
    ProjectsPageTabsEnum,
    SubrentShippingPageTabsEnum,
    TemplatePageTabsEnum,
} from '../../../../shared/constants/tabEnums';

export interface RentElementsGridItemCustom extends RentElementsGridItem {
    isCancelled?: boolean;
    elementInstanceCount?: number;
    elementAnonymousInstancesCount?: number;
}

const getElementPricePerShift = (el: OperationElement) => {
    let pricePerShift = el.pricingSchemeId
        ? PricingSchemeUtils.calculatePriceByPricingScheme({
              shiftCount: el.shiftCount || 0,
              basePrice: el.effectivePrice,
              pricingScheme: el.pricingSchemeId,
          })
        : el.effectivePrice;
    return pricePerShift;
};

export interface ProductsListParams extends Partial<ListParams> {
    categoryIds?: string;
    startDate?: number;
    endDate?: number;
    search?: string;
    problem?: string;
    finalTotalPrice?: number[];
    rentInterval?: any[];
    nomenclatureEntityTypeCode?: string;
}

export interface EquipmentListParams extends Partial<ListParams> {
    typeCode?: string[];
    hide?: string;
    problem?: string;
    startDate?: (number | undefined)[] | undefined;
    endDate?: (number | undefined)[] | undefined;
}

export interface InstancePropertiesEntityRecordCustom extends NomenclatureRecord {
    availableInstanceCount: number;
    stockInstanceCount: number;
    orderedInstanceCount: number;
    availableInstanceCountTemplate?: number;
}

export interface OperationElement {
    id: number;
    numberInActivityFrame?: number;
    businessVersion: number;
    productId: number;
    productShortName: string;
    productMainImage?: ImageObj;
    stateCode?: RentStateCodeEnum;
    rentPeriodStartDate: Date;
    rentPeriodEndDate: Date;
    shiftCount: number | undefined;
    calendarShiftCount: number;
    discount: number;
    instanceCount: number;
    finalTotalPrice: number;
    effectivePrice: number;
    pricePerShift?: number;
    finalPricePerShift?: number;
    //stockInstanceCountX: number;
    availableInstanceCount: number;
    unavailableInstanceCount?: number;
    instanceCountOriginal: number;

    discountChanged: boolean;
    rentPeriodChanged: boolean;
    shiftCountChanged: boolean;
    disabled: boolean;

    instanceIds?: Array<number>;
    instanceIdsOriginal?: Array<number>;
    variantId?: number;
    variantName?: string;
    instanceTrackingTypeCode?: InstanceTrackingTypeCodeEnum;

    rentPeriodStartDateOriginal?: Date;
    rentPeriodEndDateOriginal?: Date;

    kitId?: number;
    mainImage?: ImageObj;
    parentId?: number;

    anonymousInstanceCount: number;
    anonymousInstanceCountOriginal?: number;

    leftoverInstanceCount: number;
    leftoverAnonymousInstanceCount: number;
    keepLeftover?: boolean;

    warnings: string[];
    problems: string[];
    partOfKit?: boolean;
    mainKitMember?: boolean;

    hidden?: boolean;
    originalId?: number;

    renterId?: number;
    projectId?: number;
    templateId?: number;

    variantIdOriginal?: number;

    shiftLengthInMinutes?: number;
    hasOwnShiftLength?: boolean;

    problemsAndWarnings?: ProblemAndWarningRentIndicatorsRecordObj;
    requiredTimeIndentBetweenElementsInMinutes?: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    externalCode?: string;

    pricingSchemeId?: PricingSchemeExternalRepresentationObj | null;

    canBeCancelled?: boolean; // Может ли быть отменено
    isCancelled?: boolean; // Отменено ли
    availableTransitionCodes?: Array<OperationTypeCodeEnum>;

    subrentedInstanceCount?: number;
    kitMemberId?: number;

    rentPeriodStartDateChanged?: boolean;
    rentPeriodEndDateChanged?: boolean;
}

export enum ACTION_TYPES {
    SET_LOADING = 'operationForm/SET_LOADING',

    SET_DISCOUNT_FOR_ELEMENT = 'operationForm/SET_DISCOUNT_FOR_ELEMENT',
    SET_SHIFT_COUNT_FOR_ELEMENT = 'operationForm/SET_SHIFT_COUNT_FOR_ELEMENT',
    SET_PRICE_FOR_ELEMENT = 'operationForm/SET_PRICE_FOR_ELEMENT',
    SET_RENT_PERIOD_FOR_ELEMENT = 'operationForm/SET_RENT_PERIOD_FOR_ELEMENT',

    START_NEW_OPERATION = 'operationForm/START_NEW_OPERATION',

    SET_ELEMENT_IS_NOW_EDITING = 'operationForm/SET_ELEMENT_IS_NOW_EDITING',

    SET_AUTO_RECALCULATE_SHIFTS_COUNT_MODE = 'operationForm/SET_AUTO_RECALCULATE_SHIFTS_COUNT_MODE',
    SET_ACTUALIZE_DATES_MODE = 'operationForm/SET_ACTUALIZE_DATES_MODE',

    SET_DEFAULT_RENT_PERIOD = 'operationForm/SET_DEFAULT_RENT_PERIOD',
    SET_DEFAULT_DISCOUNT = 'operationForm/SET_DEFAULT_DISCOUNT',
    SET_DEFAULT_SHIFT_COUNT = 'operationForm/SET_DEFAULT_SHIFT_COUNT',

    RESET_DEFAULT_RENT_PERIOD = 'operationForm/RESET_DEFAULT_RENT_PERIOD',
    RESET_DEFAULT_DISCOUNT = 'operationForm/RESET_DEFAULT_DISCOUNT',
    RESET_DEFAULT_SHIFT_COUNT = 'operationForm/RESET_DEFAULT_SHIFT_COUNT',

    REMOVE_INSTANCES = 'operationForm/REMOVE_INSTANCES',
    REMOVE_TRACKED_INSTANCES = 'operationForm/REMOVE_TRACKED_INSTANCES',
    ADD_ELEMENT = 'operationForm/ADD_ELEMENT',
    UPDATE_ELEMENT = 'operationForm/UPDATE_ELEMENT',
    EDIT_ELEMENT = 'operationForm/EDIT_ELEMENT',
    ADD_KIT = 'operationForm/ADD_KIT',
    ADD_INSTANCE_FROM_SCANNER = 'operationForm/ADD_INSTANCE_FROM_SCANNER',

    CREATE_OPERATION = 'operationForm/CREATE_OPERATION',
    CREATE_OPERATION_PENDING = 'operationForm/CREATE_OPERATION_PENDING',
    CREATE_OPERATION_SUCCESS = 'operationForm/CREATE_OPERATION_FULFILLED',
    CREATE_OPERATION_ERROR = 'operationForm/CREATE_OPERATION_REJECTED',

    RESET = 'operationForm/RESET',
    FULL_RESET = 'operationForm/FULL_RESET',

    RESET_FORM = 'operationForm/RESET_FORM',

    SET_ELEMENTS_LIST_PARAMS = 'operationForm/SET_ELEMENTS_LIST_PARAMS',
    SET_PRODUCTS_LIST_PARAMS = 'operationForm/SET_PRODUCTS_LIST_PARAMS',
    SET_PRODUCTS_LIST_PARAMS_SUCCESS = 'operationForm/SET_PRODUCTS_LIST_PARAMS_FULFILLED',

    SET_ELEMENTS_LIST_PARAMS1 = 'operationForm/SET_ELEMENTS_LIST_PARAMS1',

    RESET_PRODUCTS_LIST_FILTERS = 'operationForm/RESET_PRODUCTS_LIST_FILTERS',
    RESET_PRODUCTS_LIST_FILTERS_SUCCESS = 'operationForm/RESET_PRODUCTS_LIST_FILTERS_FULFILLED',

    LOAD_PRODUCTS = 'operationForm/LOAD_PRODUCTS',
    LOAD_PRODUCTS_PENDING = 'operationForm/LOAD_PRODUCTS_PENDING',
    LOAD_PRODUCTS_SUCCESS = 'operationForm/LOAD_PRODUCTS_FULFILLED',
    LOAD_PRODUCTS_ERROR = 'operationForm/LOAD_PRODUCTS_REJECTED',

    LOAD_ELEMENTS_FOR_PROJECT = 'operationForm/LOAD_ELEMENTS_FOR_PROJECT',
    LOAD_ELEMENTS_FOR_PROJECT_PENDING = 'operationForm/LOAD_ELEMENTS_FOR_PROJECT_PENDING',
    LOAD_ELEMENTS_FOR_PROJECT_SUCCESS = 'operationForm/LOAD_ELEMENTS_FOR_PROJECT_FULFILLED',
    LOAD_ELEMENTS_FOR_PROJECT_ERROR = 'operationForm/LOAD_ELEMENTS_FOR_PROJECT_ERROR',

    SET_RENTER_AND_PROJECT = 'operationForm/SET_RENTER_AND_PROJECT',
    SET_RENTER_AND_PROJECT_SUCCESS = 'operationForm/SET_RENTER_AND_PROJECT_FULFILLED',

    // LOAD_KITS = 'operationForm/LOAD_KITS',
    // LOAD_KITS_PENDING = 'operationForm/LOAD_KITS_PENDING',
    // LOAD_KITS_SUCCESS = 'operationForm/LOAD_KITS_FULFILLED',
    // LOAD_KITS_ERROR = 'operationForm/LOAD_KITS_REJECTED',

    // LOAD_PRODUCTS_FOR_KITS = 'operationForm/LOAD_PRODUCTS_FOR_KITS',
    // LOAD_PRODUCTS_FOR_KITS_PENDING = 'operationForm/LOAD_PRODUCTS_FOR_KITS_PENDING',
    // LOAD_PRODUCTS_FOR_KITS_SUCCESS = 'operationForm/LOAD_PRODUCTS_FOR_KITS_FULFILLED',
    // LOAD_PRODUCTS_FOR_KITS_ERROR = 'operationForm/LOAD_PRODUCTS_FOR_KITS_REJECTED',

    SET_OPERATION_START_DATE_TO_CURRENT = 'operationForm/SET_OPERATION_START_DATE_TO_CURRENT',
    SET_OPERATION_END_DATE_TO_CURRENT = 'operationForm/SET_OPERATION_END_DATE_TO_CURRENT',

    CHANGE_VARIANT_OF_ELEMENT = 'operationForm/CHANGE_VARIANT_OF_ELEMENT',

    MOVE_ELEMENT_TO_KIT = 'operationForm/MOVE_ELEMENT_TO_KIT',
    MOVE_ELEMENT_FROM_KIT = 'operationForm/MOVE_ELEMENT_FROM_KIT',

    SET_BA_PREFERENCES = 'operationForm/SET_BA_PREFERENCES',

    CHANGE_OPERATION_TYPE = 'operationForm/CHANGE_OPERATION_TYPE',

    SET_KEEPLEFTOVER = 'operationForm/SET_KEEPLEFTOVER',

    ADD_INSTANCE_TO_EXISTED_OPERATION_ELEMENT = 'operationForm/ADD_INSTANCE_TO_EXISTED_OPERATION_ELEMENT',
    ADD_NEW_OPERATION_ELEMENT_FROM_EQUIPMENT = 'operationForm/ADD_NEW_OPERATION_ELEMENT_FROM_EQUIPMENT',

    RESET_ALL_KEEPLEFTOVER = 'operationForm/RESET_ALL_KEEPLEFTOVER',

    ADD_ELEMENT_FROM_EQUIPMENT = 'operationForm/ADD_ELEMENT_FROM_EQUIPMENT',

    ADD_ELEMENT_ITEM_BY_NOMENCLATURE = 'operationForm/ADD_ELEMENT_ITEM_BY_NOMENCLATURE',

    REMOVE_INSTANCE_FROM_ELEMENT = 'operationForm/REMOVE_INSTANCE_FROM_ELEMENT',

    ADD_ELEMENT_ITEM_FROM_ELEMENTS = 'operationForm/ADD_ELEMENT_ITEM_FROM_ELEMENTS',

    CANCEL_OPERATION_ELEMENT = 'operationForm/CANCEL_OPERATION_ELEMENT',

    ADD_KITS_INFO = 'operationForm/ADD_KITS_INFO',
    //ADD_NOMENCLATURES = 'operationForm/ADD_NOMENCLATURES',

    ADD_INSTANCE_TO_ELEMENT = 'operationForm/ADD_INSTANCE_TO_ELEMENT',

    SET_START_DATE_FOR_ALL_ELEMENTS = 'operationForm/SET_START_DATE_FOR_ALL_ELEMENTS',
    SET_END_DATE_FOR_ALL_ELEMENTS = 'operationForm/SET_END_DATE_FOR_ALL_ELEMENTS',

    LOAD_CONCURRENT_OPERATIONS = 'operationForm/LOAD_CONCURRENT_OPERATIONS',
    LOAD_CONCURRENT_OPERATIONS_SUCCESS = 'operationForm/LOAD_CONCURRENT_OPERATIONS_FULFILLED',

    REMOVE_CONCURRENT_OPERATION = 'operationForm/REMOVE_CONCURRENT_OPERATION',
    //REMOVE_CONCURRENT_OPERATION_PENDING = 'operationForm/REMOVE_CONCURRENT_OPERATION_PENDING',
    REMOVE_CONCURRENT_OPERATION_SUCCESS = 'operationForm/REMOVE_CONCURRENT_OPERATION_FULFILLED',
    //REMOVE_CONCURRENT_OPERATION_ERROR = 'operationForm/REMOVE_CONCURRENT_OPERATION_REJECTED',

    UPDATE_ELEMENTS_CONTEXT = 'operationForm/UPDATE_ELEMENTS_CONTEXT',

    SET_COMMENT = 'operationForm/SET_COMMENT',
    SET_COMMENT_VISIBILITY = 'operationForm/SET_COMMENT_VISIBILITY',

    UPDATE_TIMETABLES = 'operationForm/UPDATE_TIMETABLES',

    SET_STATE = 'operationForm/SET_STATE',

    // SetEffectivePriceAndDiscountForSelectedIds
    SET_EFFECTIVE_PRICE_AND_DISCOUNT_FOR_SELECTED_IDS = 'operationForm/SET_EFFECTIVE_PRICE_AND_DISCOUNT_FOR_SELECTED_IDS',
    // setFinalPricePerShiftForSelectedIds
    SET_FINAL_PRICE_PER_SHIFT_FOR_SELECTED_IDS = 'operationForm/SET_FINAL_PRICE_PER_SHIFT_FOR_SELECTED_IDS',
    // finalTotalPrice
    SET_FINAL_TOTAL_PRICE_FOR_SELECTED_IDS = 'operationForm/SET_FINAL_TOTAL_PRICE_FOR_SELECTED_IDS',
    // Даты
    SET_DATES_FOR_SELECTED_IDS = 'operationForm/SET_DATES_FOR_SELECTED_IDS',
    // Смены
    SET_SHIFT_COUNT_FOR_SELECTED_IDS = 'operationForm/SET_SHIFT_COUNT_FOR_SELECTED_IDS',
    // Пересчитать смены
    RECALCULATE_SHIFT_COUNT_FOR_SELECTED_IDS = 'operationForm/RECALCULATE_SHIFT_COUNT_FOR_SELECTED_IDS',
    // Скидка
    SET_DISCOUNT_FOR_SELECTED_IDS = 'operationForm/SET_DISCOUNT_FOR_SELECTED_IDS',

    SET_TOTAL_DISCOUNT = 'operationForm/SET_TOTAL_DISCOUNT',
    SET_FINAL_TOTAL_PRICE = 'operationForm/SET_FINAL_TOTAL_PRICE',

    SET_NOMENCLATURES = 'operationForm/SET_NOMENCLATURES',
}

export const initialProductsParamsState = {
    limit: 10,
    page: 1,
    sortBy: 'productShortName' as string | undefined,
    sortOrder: 'ASC' as 'ASC' | 'DESC' | undefined,
    finalTotalPrice: undefined as number[] | undefined,
};

export const initialProductsParamsState1 = {
    limit: 10,
    page: 1,
    sortBy: 'productShortName' as string | undefined,
    sortOrder: 'ASC' as 'ASC' | 'DESC' | undefined,
    startDate: [undefined, undefined] as (number | undefined)[] | undefined,
    endDate: [undefined, undefined] as (number | undefined)[] | undefined,
};

const initialState = {
    renterId: undefined as number | undefined,
    renterShortName: undefined as string | undefined,
    projectId: undefined as number | undefined,
    projectShortName: undefined as string | undefined,
    projectType: undefined as ProjectTypeCodeEnum | undefined,
    projectTemplate: false,
    taxRate: undefined as number | undefined,
    taxBaseType: undefined as TaxBaseTypeCodeEnum | undefined,

    isSubrent: undefined as boolean | undefined,

    typeCode: undefined as OperationTypeCodeEnum | undefined,
    targetStateCode: undefined as RentStateCodeEnum | undefined,
    fromRequest: true,
    mnemoKey: '',

    rentPeriodStartDate: undefined as Date | undefined,
    rentPeriodEndDate: undefined as Date | undefined,
    rentPeriodIsFixed: true,

    shiftCount: undefined as number | undefined,
    shiftCountIsFixed: true,

    discount: 0,
    discountIsFixed: true,

    // Всего
    instanceCount: 0,

    // Общая стоимость
    priceBeforeDiscount: 0,

    // Сумма скидки
    discountAmount: 0,

    // Итого
    finalTotalPrice: 0,

    finalTotalPriceWithTaxes: undefined as number | undefined,

    elementsCount: 0,

    elements: {
        limit: 10,
        page: 1,
        sortBy: 'id' as string | undefined,
        sortOrder: 'ASC' as 'ASC' | 'DESC' | undefined,
        entities: [] as Array<OperationElement>,
    },

    products: {
        entities: null as Array<InstancePropertiesEntityRecordCustom> | null,
        params: initialProductsParamsState as ProductsListParams,

        loading: false,
        loadingError: null,
        filteredCount: 0,
        smartFiltersOpened: false,
    },

    equipment: {
        entities: null as Array<RentElementsGridItemCustom> | null,
        filteredEntities: null as Array<RentElementsGridItemCustom> | null,
        params: initialProductsParamsState1 as EquipmentListParams,

        loading: false,
        loadingError: null,
        filteredCount: 0,
        smartFiltersOpened: false,
    },

    // instances: {
    //     productId: undefined as number | undefined,
    //     variantId: undefined as number | undefined,
    //     entities: null as Array<InstanceRecord> | null,
    //     loading: false,
    //     loadingError: undefined as ServerError | undefined,
    // },

    // variants: {
    //     productId: undefined as number | undefined,
    //     entities: null as Array<NomenclatureRecord> | null,
    //     loading: false,
    //     loadingError: undefined as ServerError | undefined,
    // },

    // kits: {
    //     entities: null as Array<KitInfoRead> | null,
    //     loading: false,
    //     loadingError: undefined as ServerError | undefined,
    // },

    kitsInfo: [] as Array<KitInfoRead>,
    //nomenclatures: [] as Array<NomenclatureRecord>,

    creationError: undefined as ServerError | undefined,
    creationInProgress: false,
    loading: false,

    withExistingElements: false,

    timeTables: [] as TimeTable[],
    elementsDelayedReturnDates: {} as { [key: number]: Date },

    shiftLengthInMinutes: undefined as number | undefined,
    shiftCountRoundingType: undefined as ShiftCountRoundingTypeCodeEnum | undefined,
    requiredTimeIndentBetweenElementsInMinutes: undefined as number | undefined,
    subrentForShortage: undefined as boolean | undefined,

    goBackPath: undefined as string | undefined,

    operationStartTime: undefined as Date | undefined,

    autoRecalculateShiftsCountMode: false as boolean,
    actualizeDateMode: false as boolean,

    createdNewInstances: false,
    variantChanged: false,
    rentElementCanceledByCorrection: false,
    leftoverCleared: false,

    currentOperationUUID: undefined as string | undefined,
    concurrentOperations: {
        entities: null as ConcurrentOperationObj[] | null,
    },
    elementIsNowEditing: false,

    currentOperation: {
        comment: undefined,
        commentVisible: false,
    } as CurrentOperation | undefined,
};

interface CurrentOperation {
    comment?: string;
    commentVisible: boolean;
}

export interface IntervalsObj {
    id: number;
    type: NomenclatureEntityTypeCodeEnum;
    available?: [number, number][];
    stock?: [number, number][];
}

export interface ProductStackMapList {
    productId: number;
    variantId?: number;
    kitId?: number;
    list?: TimetableList;
    timetableVersion: number;
}

export interface InstanceStackMapList {
    productId: number;
    instanceId: number;
    variantId?: number;
    list?: TimetableList;
}

export interface KitStackMapList {
    kitId: number;
    list?: TimetableList;
    timetableVersion: number;
}

export interface TimeTable {
    productId?: number;
    variantId?: number;
    instanceId?: number;
    kitId?: number;
    timetableVersion: number;
    list?: TimetableList;
    available1: [number, number][];
    orderAvailable: [number, number][];
    stock: [number, number][];
    order: [number, number][];
    shiftLengthInMinutes?: number;
    hasOwnShiftLength?: boolean;
    requiredTimeIndentBetweenElementsInMinutes?: number;
    productHasOwnRequiredTimeIndentBetweenElements?: boolean;
    subrentSupply?: boolean;
    pricingScheme?: PricingSchemeExternalRepresentationObj;

    from?: number;
    until?: number;
    id?: number;
    type?: NomenclatureEntityTypeCodeEnum;
    nomenclature: NomenclatureRecord | InstanceRecord;
}

export type OperationFormState = Readonly<typeof initialState>;

// Reducer

export default (state: OperationFormState = initialState, action: OperationFormAction): OperationFormState => {
    switch (action.type) {
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }

        case ACTION_TYPES.SET_DISCOUNT_FOR_ELEMENT: {
            let entities = [...state.elements.entities];
            let element = entities.find((el) => el.id === action.payload.elementId);
            if (element) {
                element.discount = action.payload.discount;
            }
            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: entities,
                },
            });
        }

        case ACTION_TYPES.SET_SHIFT_COUNT_FOR_ELEMENT: {
            let entities = [...state.elements.entities];
            let element = entities.find((el) => el.id === action.payload.elementId);
            if (element) {
                element.shiftCount = action.payload.shiftCount;
            }
            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: entities,
                },
            });
        }

        case ACTION_TYPES.SET_PRICE_FOR_ELEMENT: {
            let entities = [...state.elements.entities];
            let element = entities.find((el) => el.id === action.payload.elementId);
            if (element) {
                element.effectivePrice = action.payload.price;
                element.pricingSchemeId = undefined;
            }
            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: entities,
                },
            });
        }

        case ACTION_TYPES.SET_RENT_PERIOD_FOR_ELEMENT: {
            let entities = [...state.elements.entities];
            let element = entities.find((el) => el.id === action.payload.elementId);
            if (element) {
                element.rentPeriodStartDate = action.payload.rentPeriod[0];
                element.rentPeriodEndDate = action.payload.rentPeriod[1];
                if (isDefined(action.payload.shiftCount)) element.shiftCount = action.payload.shiftCount;
            }
            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: entities,
                },
            });
        }

        case ACTION_TYPES.SET_AUTO_RECALCULATE_SHIFTS_COUNT_MODE:
            let shiftCount = undefined;
            return updateState({
                ...state,
                autoRecalculateShiftsCountMode: action.payload,
                shiftCount,
            });

        case ACTION_TYPES.SET_ELEMENT_IS_NOW_EDITING:
            const elementsEntities = action.payload ? sortByFn(state.elements.entities, state.elements.sortBy!) : state.elements.entities;
            return action.payload
                ? { ...state, elementIsNowEditing: action.payload, elements: { ...state.elements, entities: elementsEntities } }
                : updateState({
                      ...state,
                      elementIsNowEditing: action.payload,
                      elements: { ...state.elements, entities: elementsEntities },
                  });
        case ACTION_TYPES.SET_ACTUALIZE_DATES_MODE:
            if (action.payload) {
                let rentPeriodStartDate =
                    action.payload && isRentOrSubrentOperation(state.targetStateCode || state.typeCode)
                        ? state.operationStartTime //?.toDate()
                        : state.rentPeriodStartDate;
                let rentPeriodEndDate =
                    action.payload && isReturnOrSubrentReturnOperation(state.targetStateCode || state.typeCode)
                        ? state.operationStartTime //?.toDate()
                        : state.rentPeriodEndDate;
                return {
                    ...state,
                    actualizeDateMode: action.payload,
                    rentPeriodStartDate: rentPeriodStartDate,
                    rentPeriodEndDate: rentPeriodEndDate,
                    products: {
                        ...state.products,
                        params: {
                            ...state.products.params,
                            startDate: rentPeriodStartDate?.valueOf(),
                            endDate: rentPeriodEndDate?.valueOf(),
                            rentInterval: [moment(rentPeriodStartDate), moment(rentPeriodEndDate)],
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    actualizeDateMode: action.payload,
                };
            }

        case ACTION_TYPES.LOAD_CONCURRENT_OPERATIONS_SUCCESS: {
            let data = action.payload.data;
            if (
                state.currentOperationUUID !== data.currentOperationUUID ||
                JSON.stringify(state.concurrentOperations.entities) !== JSON.stringify(data.records)
            ) {
                return {
                    ...state,
                    concurrentOperations: {
                        ...state.concurrentOperations,
                        entities: data.records,
                    },
                    currentOperationUUID: data.currentOperationUUID,
                };
            } else {
                return state;
            }
        }

        case ACTION_TYPES.UPDATE_ELEMENTS_CONTEXT: {
            let taxRate: number | undefined;
            let taxBaseType: TaxBaseTypeCodeEnum | undefined;
            if (action.payload.project) {
                taxRate = action.payload.project.taxRate;
                taxBaseType = action.payload.project.taxBaseType;
            } else {
                taxRate = state.taxRate;
                taxBaseType = state.taxBaseType;
            }

            state.elements.entities.forEach((el) => {
                if (el.pricingSchemeId) {
                    let t = findTimeTable(state.timeTables, {
                        kitId: el.kitId,
                        productId: el.productId,
                        variantId: el.variantId,
                    });
                    if (JSON.stringify(t?.pricingScheme?.steps) !== JSON.stringify(el.pricingSchemeId?.steps)) {
                        el.pricingSchemeId = undefined;
                    }
                }
            });

            return updateState({
                ...state,
                elementsDelayedReturnDates: action.payload.elementsDelayedReturnDates,
                taxRate: taxRate,
                kitsInfo: [],
                taxBaseType: taxBaseType,
                operationStartTime: action.payload.operationStartTime.toDate(),
            });
        }

        case ACTION_TYPES.LOAD_PRODUCTS_PENDING:
            return {
                ...state,
                products: {
                    ...state.products,
                    loading: true,
                },
            };

        case ACTION_TYPES.LOAD_PRODUCTS_ERROR: {
            let err = getServerError(action.payload);
            console.log(err);

            return {
                ...state,
                products: {
                    ...state.products,
                    loading: false,
                },
            };
        }

        case ACTION_TYPES.LOAD_PRODUCTS_SUCCESS:
            let records = action.payload.data.records;
            //let timeTables = state.timeTables;
            //let productStackMapLists = state.productStackMapLists || [];

            // Сохраняем карты доступности для счетных продуктов
            //xxx(records, productStackMapLists);

            // Тут нужно сохранить карту или обновить, если отличается версия
            //saveOrUpdateTimetables(records, timeTables, state.targetStateCode || state.typeCode);

            // let elementsDelayedReturnDates = state.elementsDelayedReturnDates;
            // action.payload.data.elementsDelayedReturnDates?.forEach((item) => {
            //     elementsDelayedReturnDates[item.id] = new Date(item.date);
            // });

            // state.elements.entities.forEach((el) => {
            //     if (el.pricingSchemeId) {
            //         let t = findTimeTable(timeTables, {
            //             kitId: el.kitId,
            //             productId: el.productId,
            //             variantId: el.variantId,
            //         });
            //         if (JSON.stringify(t?.pricingScheme?.steps) !== JSON.stringify(el.pricingSchemeId?.steps)) {
            //             el.pricingSchemeId = undefined;
            //         }
            //     }
            // });

            return updateState({
                ...state,
                products: {
                    ...state.products,
                    loading: false,
                    entities: records as InstancePropertiesEntityRecordCustom[],
                    filteredCount: action.payload.data.listAttributes.filteredCount,
                },
            });

        case ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT_PENDING:
            return {
                ...state,
                equipment: {
                    ...state.equipment,
                    loading: true,
                },
            };

        case ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT_ERROR: {
            let err = getServerError(action.payload);
            console.log(err);

            return {
                ...state,
                equipment: {
                    ...state.equipment,
                    loading: false,
                },
            };
        }

        case ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT_SUCCESS: {
            const entities: RentElementsGridItemCustom[] = (action.payload.data as RentElementRecordList).records.map((item) => {
                return convertRentElementRecordToRentElementsGridItem(item);
            });

            //equipment
            let filteredEntities = filterEquipmentData(entities, state.equipment.params);

            let _sortOrder = state.equipment.params.sortOrder;
            let _sortBy = state.equipment.params.sortBy || '';

            let sortFieldName = _sortBy;

            let sortAsc = _sortOrder === 'ASC';
            let newEntities: RentElementsGridItemCustom[] = filteredEntities ? [...filteredEntities] : [];
            //
            newEntities = newEntities.map((e) => {
                let ok = false;
                let leftoverInstanceCount: undefined | number = undefined;

                if (e.subRows) {
                    let withoutLeftover = 0;
                    e.subRows.forEach((e) => {
                        let element = state.elements.entities.find((el) => el.id === e.id);
                        if (element && element.leftoverInstanceCount <= 0) withoutLeftover++;
                    });
                    ok = withoutLeftover > 0 && withoutLeftover === e.subRows.length;
                } else {
                    if (state.elements.entities && state.elements.entities.length > 0) {
                        let element = state.elements.entities.find((el) => el.id === e.id);
                        if (element) {
                            leftoverInstanceCount = element.leftoverInstanceCount;
                            ok = isDefined(leftoverInstanceCount) && leftoverInstanceCount <= 0;
                        }
                    }
                }

                e['allAdded'] = ok ? 1 : 0;

                let instanceCount: number | undefined = 0;
                if (state.elements.entities) {
                    let element = state.elements.entities.find((el) => el.id === e.id);
                    if (element) {
                        instanceCount = element.instanceCount;
                    }
                }
                e['added'] = instanceCount;

                return e;
            });
            //

            let sortedData: RentElementsGridItemCustom[] = sortByFn(newEntities, [sortFieldName, 'id']);
            if (!sortAsc) sortedData.reverse();

            return updateState({
                ...state,
                equipment: {
                    ...state.equipment,
                    loading: false,
                    entities: entities,
                    filteredCount: entities.length,
                    filteredEntities: sortedData,
                },
                //productStackMapLists,
                //timeTables,
                //elementsDelayedReturnDates
            });
        }
        case ACTION_TYPES.RESET:
            if (state.withExistingElements) {
                return {
                    ...initialState,
                    shiftLengthInMinutes: state.shiftLengthInMinutes,
                    shiftCountRoundingType: state.shiftCountRoundingType,
                    requiredTimeIndentBetweenElementsInMinutes: state.requiredTimeIndentBetweenElementsInMinutes,
                };
            } else {
                return {
                    ...initialState,
                    isSubrent: state.isSubrent,
                    renterId: state.renterId,
                    renterShortName: state.renterShortName,
                    projectId: state.projectId,
                    projectShortName: state.projectShortName,
                    shiftLengthInMinutes: state.shiftLengthInMinutes,
                    shiftCountRoundingType: state.shiftCountRoundingType,
                    requiredTimeIndentBetweenElementsInMinutes: state.requiredTimeIndentBetweenElementsInMinutes,
                };
            }
        case ACTION_TYPES.FULL_RESET:
            return {
                ...initialState,
                shiftLengthInMinutes: state.shiftLengthInMinutes,
                shiftCountRoundingType: state.shiftCountRoundingType,
                requiredTimeIndentBetweenElementsInMinutes: state.requiredTimeIndentBetweenElementsInMinutes,
            };
        case ACTION_TYPES.RESET_FORM:
            return {
                ...initialState,
                shiftLengthInMinutes: state.shiftLengthInMinutes,
                shiftCountRoundingType: state.shiftCountRoundingType,
                requiredTimeIndentBetweenElementsInMinutes: state.requiredTimeIndentBetweenElementsInMinutes,
            };

        case ACTION_TYPES.CREATE_OPERATION_PENDING:
            return {
                ...state,
                creationError: undefined,
                creationInProgress: true,
            };

        case ACTION_TYPES.CREATE_OPERATION_ERROR:
            let err = getServerError(action.payload);

            return {
                ...state,
                creationError: err,
                creationInProgress: false,
            };

        case ACTION_TYPES.START_NEW_OPERATION: {
            let actualizeDateMode = action.payload.setActualDate || false;
            let dafaultDiscount: number = action.payload.discount || 0;
            let defaultShiftCount: number | undefined = action.payload.shiftCount || undefined;
            let currentDate = moment();
            let defaultStartDate: number = currentDate
                .seconds(0)
                .milliseconds(0) /*.minutes(Math.ceil(currentDate.minutes() / 15) * 15)*/
                .valueOf();
            let defaultEndDate: number = defaultStartDate + 60 * 60 * 24 * (defaultShiftCount || 7) * 1000;

            if (action.payload.startDate) {
                defaultStartDate = moment(action.payload.startDate)
                    .seconds(0)
                    .milliseconds(0) /*.minutes(Math.ceil(currentDate.minutes() / 15) * 15)*/
                    .valueOf();
            }

            if (action.payload.endDate) {
                defaultEndDate = moment(action.payload.endDate)
                    .seconds(0)
                    .milliseconds(0) /*.minutes(Math.ceil(currentDate.minutes() / 15) * 15)*/
                    .valueOf();
            }

            let discountObj = {};
            let shiftCountsObj = {};
            let startDatesObj = {};
            let endDatesObj = {};

            let elements = [...action.payload.elements];

            let withExistingElements = false;

            if (elements && elements.length > 0) {
                withExistingElements = true;

                elements
                    .filter((element) => !element.kitId)
                    .forEach((element: OperationElement) => {
                        const startDate = new Date(element.rentPeriodStartDate).getTime();
                        const endDate = new Date(element.rentPeriodEndDate).getTime();

                        discountObj[element.discount] = discountObj[element.discount] ? discountObj[element.discount] + 1 : 1;
                        shiftCountsObj[element.shiftCount || 0] = shiftCountsObj[element.shiftCount || 0]
                            ? shiftCountsObj[element.shiftCount || 0] + 1
                            : 1;
                        startDatesObj[startDate] = startDatesObj[startDate] ? startDatesObj[startDate] + 1 : 1;
                        endDatesObj[endDate] = endDatesObj[endDate] ? endDatesObj[endDate] + 1 : 1;
                    });

                let objs = [discountObj, shiftCountsObj, startDatesObj, endDatesObj];

                const defaultValues = objs.map((obj, index) => {
                    let max = -1,
                        value = 0;
                    for (let key in obj) {
                        if (obj[key] > max) {
                            value = +key;
                            max = obj[key];
                        }
                    }

                    let maxCount = 0;
                    for (let key in obj) {
                        if (obj[key] === max) {
                            maxCount += 1;
                        }
                    }

                    if (maxCount > 1) {
                        if (index === 0) value = 0;
                        else if (index === 1) value = 0;
                        else if (index === 2) {
                            value = Math.min(...Object.keys(obj).map((key) => +key));
                        } else if (index === 3) {
                            value = Math.max(...Object.keys(obj).map((key) => +key));
                        }
                    }
                    return value;
                });

                dafaultDiscount = defaultValues[0];
                defaultShiftCount = defaultValues[1];
                defaultStartDate = defaultValues[2];
                defaultEndDate = defaultValues[3];

                elements = elements.map((element: OperationElement) => ({
                    ...element,
                    discountChanged: element.discount !== dafaultDiscount,
                    rentPeriodChanged:
                        new Date(element.rentPeriodStartDate).getTime() !== defaultStartDate ||
                        new Date(element.rentPeriodEndDate).getTime() !== defaultEndDate,
                    shiftCountChanged: element.shiftCount !== defaultShiftCount,
                }));
            }

            if (actualizeDateMode) {
                if (
                    isRentOperation(action.payload.targetStateCode) ||
                    action.payload.typeCode === OperationTypeCodeEnum.RENT ||
                    action.payload.typeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT
                ) {
                    let startDate: Moment = moment(action.payload.operationStartDate /*Date.now()*/);
                    let endDate: Moment = moment(defaultEndDate);
                    let diff =
                        startDate && endDate
                            ? getShiftCountFromDates(startDate, endDate, state.shiftLengthInMinutes, state.shiftCountRoundingType)
                            : 0;
                    //
                    elements = elements.map((element) => {
                        element.rentPeriodStartDate = startDate.toDate();
                        if (action.payload.recalculateShiftCount) {
                            let diffEl = getShiftCountFromDates(
                                moment(element.rentPeriodStartDate),
                                moment(element.rentPeriodEndDate),
                                element.shiftLengthInMinutes,
                                state.shiftCountRoundingType
                            );
                            element.shiftCount = diffEl;
                            element.shiftCountChanged = diff !== diffEl;
                        }
                        return element;
                    });
                    defaultStartDate = startDate.valueOf();
                    if (action.payload.recalculateShiftCount) defaultShiftCount = diff;
                } else if (
                    isReturnOperation(action.payload.targetStateCode) ||
                    action.payload.typeCode === OperationTypeCodeEnum.RETURN ||
                    action.payload.typeCode === OperationTypeCodeEnum.RETURNBROKEN ||
                    action.payload.typeCode === OperationTypeCodeEnum.LOSTNORETURN ||
                    action.payload.typeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER
                ) {
                    let startDate: Moment = moment(defaultStartDate);
                    let endDate: Moment = moment(action.payload.operationStartDate /*Date.now()*/);
                    let diff =
                        startDate && endDate
                            ? getShiftCountFromDates(startDate, endDate, state.shiftLengthInMinutes, state.shiftCountRoundingType)
                            : 0;
                    elements = elements.map((element) => {
                        element.rentPeriodEndDate = endDate.toDate();
                        if (action.payload.recalculateShiftCount) {
                            let diffEl = getShiftCountFromDates(
                                moment(element.rentPeriodStartDate),
                                moment(element.rentPeriodEndDate),
                                element.shiftLengthInMinutes,
                                state.shiftCountRoundingType
                            );
                            element.shiftCount = diffEl;
                            element.shiftCountChanged = diff !== diffEl;
                        }
                        return element;
                    });
                    defaultEndDate = endDate.valueOf();
                    if (action.payload.recalculateShiftCount) defaultShiftCount = diff;
                } else if (action.payload.typeCode === OperationTypeCodeEnum.PROLONG || action.payload.typeCode === OperationTypeCodeEnum.SUBRENTPROLONG) {
                    if (action.payload.recalculateShiftCount) {
                        let startDate: Moment = moment(defaultStartDate);
                        let endDate: Moment = moment(defaultEndDate /*action.payload.operationStartDate*/ /*Date.now()*/);
                        let diff =
                            startDate && endDate
                                ? getShiftCountFromDates(startDate, endDate, state.shiftLengthInMinutes, state.shiftCountRoundingType)
                                : 0;
                        elements = elements.map((element) => {
                            //element.rentPeriodEndDate = endDate.toDate();
                            //if (action.payload.recalculateShiftCount) {
                            let diffEl = getShiftCountFromDates(
                                moment(element.rentPeriodStartDate),
                                moment(element.rentPeriodEndDate),
                                element.shiftLengthInMinutes,
                                state.shiftCountRoundingType
                            );
                            element.shiftCount = diffEl;
                            element.shiftCountChanged = diff !== diffEl;
                            //}
                            return element;
                        });
                        //defaultEndDate = endDate.valueOf();
                        if (action.payload.recalculateShiftCount) defaultShiftCount = diff;
                    }
                    actualizeDateMode = false;
                }
            }

            let timetables: TimeTable[] = [];
            timetables = [...state.timeTables];
            if (action.payload.timetables) {
                action.payload.timetables.forEach((tt) => {
                    let oldTT = findTimeTable(timetables, {
                        kitId: tt.kitId,
                        instanceId: tt.instanceId,
                        productId: tt.productId,
                        variantId: tt.variantId,
                    });
                    if (oldTT) {
                        let index = timetables.indexOf(oldTT);
                        timetables.splice(index, 1);
                        timetables.push(tt);
                    } else {
                        timetables.push(tt);
                    }
                });
            }

            if (action.payload.recalculateShiftCount) {
                defaultShiftCount = undefined;
            }

            return updateState({
                ...initialState,
                shiftLengthInMinutes: state.shiftLengthInMinutes,
                shiftCountRoundingType: state.shiftCountRoundingType,
                requiredTimeIndentBetweenElementsInMinutes: state.requiredTimeIndentBetweenElementsInMinutes,
                projectTemplate: action.payload.projectTemplate,
                renterId: action.payload.renterId,
                renterShortName: action.payload.renterShortName,
                projectId: action.payload.projectId,
                projectShortName: action.payload.projectShortName,
                projectType: action.payload.projectType,
                isSubrent: action.payload.isSubrent || isSubrentOperation(action.payload.typeCode, action.payload.targetStateCode),
                typeCode: action.payload.typeCode,
                mnemoKey: action.payload.mnemoKey,
                elements: {
                    ...initialState.elements,
                    entities: elements,
                },
                withExistingElements: withExistingElements,
                discount: dafaultDiscount,
                shiftCount: defaultShiftCount,
                rentPeriodStartDate: new Date(defaultStartDate),
                rentPeriodEndDate: new Date(defaultEndDate),
                fromRequest: action.payload.fromRequest,
                products: {
                    ...initialState.products,
                    params: {
                        ...initialState.products.params,
                        startDate: defaultStartDate,
                        endDate: defaultEndDate,
                        rentInterval: [moment(defaultStartDate), moment(defaultEndDate)],
                    },
                },
                targetStateCode: action.payload.targetStateCode,
                timeTables: timetables,
                elementsDelayedReturnDates: { ...state.elementsDelayedReturnDates, ...action.payload.elementsDelayedReturnDates } || {},
                goBackPath: window.location.pathname + (window.location.search ? '?' + window.location.search : ''),
                operationStartTime: action.payload.operationStartDate,
                autoRecalculateShiftsCountMode: action.payload.recalculateShiftCount || false,
                actualizeDateMode: actualizeDateMode,
                taxRate: action.payload.taxRate,
                taxBaseType: action.payload.taxBaseType,
            });
        }

        case ACTION_TYPES.SET_DEFAULT_RENT_PERIOD: {
            let startDate: Moment | undefined = action.payload.startDate;
            let endDate: Moment | undefined = action.payload.endDate;
            let diff =
                startDate && endDate
                    ? getShiftCountFromDates(startDate, endDate, state.shiftLengthInMinutes, state.shiftCountRoundingType)
                    : 0;

            let elementsEntities = [...state.elements.entities];

            return updateState({
                ...state,
                rentPeriodStartDate: startDate ? startDate.toDate() : undefined,
                rentPeriodEndDate: endDate ? endDate.toDate() : undefined,
                shiftCount: action.payload.recalculateShiftsCount && !state.autoRecalculateShiftsCountMode ? diff : state.shiftCount,
                products: {
                    ...state.products,
                    params: {
                        ...state.products.params,
                        startDate: startDate ? startDate.valueOf() : undefined,
                        endDate: endDate ? endDate.valueOf() : undefined,
                        rentInterval: [startDate, endDate],
                    },
                },
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.SET_DEFAULT_DISCOUNT: {
            return updateState({
                ...state,
                discount: action.payload,
            });
        }

        case ACTION_TYPES.SET_DEFAULT_SHIFT_COUNT: {
            let elementsEntities = state.elements.entities.map((element) => {
                //if(!state.autoRecalculateShiftsCountMode /*&& action.payload !== undefined*/){
                if (action.payload !== undefined) {
                    if (element.shiftCount !== action.payload) element.shiftCountChanged = true;
                    else element.shiftCountChanged = false;
                } else {
                    if (
                        element.shiftCount !==
                        getShiftCountFromDates(
                            moment(state.rentPeriodStartDate),
                            moment(state.rentPeriodEndDate),
                            element.shiftLengthInMinutes,
                            state.shiftCountRoundingType
                        )
                    )
                        element.shiftCountChanged = true;
                    else element.shiftCountChanged = false;
                }
                return element;
            });
            elementsEntities = state.elements.entities.map((element) => {
                if (!state.autoRecalculateShiftsCountMode && action.payload !== undefined) {
                    if (element.kitId) {
                        let shiftCountChanged = state.elements.entities
                            .filter((el) => el.parentId === element.id)
                            .every((el) => el.shiftCountChanged === true);
                        element.shiftCountChanged = shiftCountChanged;
                    }
                }
                return element;
            });

            return updateState({
                ...state,
                shiftCount: action.payload,
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.RESET_DEFAULT_RENT_PERIOD: {
            let startDate: Moment = moment(state.rentPeriodStartDate);
            let endDate: Moment = moment(state.rentPeriodEndDate);

            let elementsEntities = state.elements.entities.map((element) => {
                element.rentPeriodStartDate = startDate?.toDate();
                element.rentPeriodEndDate = endDate?.toDate();
                return element;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.RESET_DEFAULT_DISCOUNT: {
            let elementsEntities = state.elements.entities.map((element) => {
                element.discount = state.discount;
                element.discountChanged = false;
                return element;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.RESET_DEFAULT_SHIFT_COUNT: {
            let elementsEntities = state.elements.entities.map((element) => {
                let shiftCount = getShiftCountFromDates(
                    moment(element.rentPeriodStartDate),
                    moment(element.rentPeriodEndDate),
                    element.shiftLengthInMinutes,
                    state.shiftCountRoundingType
                );
                element.shiftCount = state.shiftCount !== undefined ? state.shiftCount : shiftCount;

                if (state.shiftCount === undefined || state.autoRecalculateShiftsCountMode) {
                    element.shiftCount = getShiftCountFromDates(
                        moment(element.rentPeriodStartDate),
                        moment(element.rentPeriodEndDate),
                        element.shiftLengthInMinutes,
                        state.shiftCountRoundingType
                    );
                }

                element.shiftCountChanged = false;
                return element;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.SET_RENTER_AND_PROJECT_SUCCESS: {
            const { renterId, renterShortName, projectId, projectShortName, defaultDiscount, isSubrentOperation } = action.payload;

            if (!state.mnemoKey && !state.typeCode) {
                return {
                    ...state,
                    renterId,
                    renterShortName,
                    projectId,
                    projectShortName,
                    //discount: defaultDiscount || state.discount
                    discount: defaultDiscount || 0,
                    isSubrent: isSubrentOperation,
                };
            }
            return state;
        }

        case ACTION_TYPES.REMOVE_TRACKED_INSTANCES: {
            // Тут нужно найти обязательство по id и убрать у него удаляемые экземпляры

            let entities = [...state.elements.entities],
                elementIndex = entities.findIndex((element) => element.id === action.payload.id);

            if (elementIndex > -1) {
                let element = entities[elementIndex];

                // Убрали анонимные
                if (element.anonymousInstanceCount) {
                    element.anonymousInstanceCount -= action.payload.anonymousCount;
                    if (element.anonymousInstanceCount < 0) element.anonymousInstanceCount = 0;
                }

                if (action.payload.ids.length > 0) {
                    action.payload.ids.forEach((id) => {
                        if (element.instanceIds) {
                            let idIndex = element.instanceIds.indexOf(id);
                            if (idIndex > -1) {
                                element.instanceIds.splice(idIndex, 1);
                            }
                        }
                    });
                }

                element.instanceCount = (element.anonymousInstanceCount || 0) + (element.instanceIds?.length || 0);

                if (element.instanceCount <= 0) {
                    element.instanceCount = 0;
                    entities.splice(elementIndex, 1);
                }
            }

            // Удаляем набор если у него нет элементов
            let kitIds = entities.filter((element) => element.kitId).map((element) => element.id);
            let kitsToDelete: number[] = [];
            kitIds.forEach((id) => {
                let withMembers = entities.filter((element) => element.parentId === id).length > 0;
                if (!withMembers) kitsToDelete.push(id);
            });
            if (kitsToDelete.length > 0) {
                kitsToDelete.forEach((id) => {
                    for (let i = 0; i < entities.length; i++) {
                        if (entities[i].id === id) {
                            entities.splice(i, 1);
                            i--;
                        }
                    }
                });
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities,
                },
            });
        }

        case ACTION_TYPES.REMOVE_INSTANCES: {
            // Тут нужно найти обязательство по id и отнять у него количество нужное

            let entities = [...state.elements.entities],
                elementIndex = entities.findIndex((element) => element.id === action.payload.id);

            if (elementIndex > -1) {
                let element = entities[elementIndex];
                if (!element.kitId) {
                    // Не набор
                    element.instanceCount -= action.payload.amount;
                    if (element.instanceCount <= 0) {
                        element.instanceCount = 0;
                        entities.splice(elementIndex, 1);
                    }
                } else {
                    // Набор
                    // Удаляем сам набор
                    entities.splice(elementIndex, 1);

                    for (let i = 0; i < entities.length; i++) {
                        if (entities[i].parentId === element.id) {
                            entities.splice(i, 1);
                            i--;
                        }
                    }
                }
            }

            // Удаляем набор если у него нет элементов
            let kitIds = entities.filter((element) => element.kitId).map((element) => element.id);
            let kitsToDelete: number[] = [];
            kitIds.forEach((id) => {
                let withMembers = entities.filter((element) => element.parentId === id).length > 0;
                if (!withMembers) kitsToDelete.push(id);
            });
            if (kitsToDelete.length > 0) {
                kitsToDelete.forEach((id) => {
                    for (let i = 0; i < entities.length; i++) {
                        if (entities[i].id === id) {
                            entities.splice(i, 1);
                            i--;
                        }
                    }
                });
            }
            //

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities,
                },
            });
        }

        case ACTION_TYPES.SET_ELEMENTS_LIST_PARAMS:
            return {
                ...state,
                elements: {
                    ...state.elements,
                    limit: action.payload.limit !== undefined ? action.payload.limit : state.elements.limit,
                    page: action.payload.page !== undefined ? action.payload.page : state.elements.page,
                    sortOrder: action.payload.sortOrder !== undefined ? action.payload.sortOrder : state.elements.sortOrder,
                    sortBy: action.payload.sortBy !== undefined ? action.payload.sortBy : state.elements.sortBy,
                },
            };

        case ACTION_TYPES.SET_ELEMENTS_LIST_PARAMS1:
            let startDate = 'startDate' in action.payload ? action.payload.startDate : state.equipment.params.startDate;
            let endDate = 'endDate' in action.payload ? action.payload.endDate : state.equipment.params.endDate;

            let limit = action.payload.limit !== undefined ? action.payload.limit : state.equipment.params.limit;
            let page = action.payload.page !== undefined ? action.payload.page : state.equipment.params.page;
            let sortOrder = action.payload.sortOrder !== undefined ? action.payload.sortOrder : state.equipment.params.sortOrder;
            let sortBy = action.payload.sortBy !== undefined ? action.payload.sortBy : state.equipment.params.sortBy;

            let problem = 'problem' in action.payload ? action.payload.problem : state.equipment.params.problem;
            let typeCode =
                'typeCode' in action.payload
                    ? action.payload.typeCode?.length
                        ? action.payload.typeCode
                        : undefined
                    : state.equipment.params.typeCode;

            let hide = 'hide' in action.payload ? action.payload.hide : state.equipment.params.hide;

            //if(startDate && !startDate[0] && !startDate[1]) startDate = undefined;
            //if(endDate && !endDate[0] && !endDate[1]) endDate = undefined;

            let params = {
                ...state.equipment.params,
                limit: limit,
                page: page,
                sortOrder: sortOrder,
                sortBy: sortBy,

                problem: problem,
                typeCode: typeCode,

                hide: hide,
                startDate: startDate,
                endDate: endDate,
            };

            let filteredEntities = filterEquipmentData(state.equipment.entities || [], params);

            let _sortOrder = params.sortOrder;
            let _sortBy = params.sortBy || '';

            let sortFieldName = _sortBy;

            let sortAsc = _sortOrder === 'ASC';
            let newEntities: RentElementsGridItemCustom[] = filteredEntities ? [...filteredEntities] : [];
            //
            newEntities = newEntities.map((e) => {
                let ok = false;
                let leftoverInstanceCount: undefined | number = undefined;

                if (e.subRows) {
                    let withoutLeftover = 0;
                    e.subRows.forEach((e) => {
                        let element = state.elements.entities.find((el) => el.id === e.id);
                        if (element && element.leftoverInstanceCount <= 0) withoutLeftover++;
                    });
                    ok = withoutLeftover > 0 && withoutLeftover === e.subRows.length;
                } else {
                    if (state.elements.entities && state.elements.entities.length > 0) {
                        let element = state.elements.entities.find((el) => el.id === e.id);
                        if (element) {
                            leftoverInstanceCount = element.leftoverInstanceCount;
                            ok = isDefined(leftoverInstanceCount) && leftoverInstanceCount <= 0;
                        }
                    }
                }

                e['allAdded'] = ok ? 1 : 0;

                let instanceCount: number | undefined = 0;
                if (state.elements.entities) {
                    let element = state.elements.entities.find((el) => el.id === e.id);
                    if (element) {
                        instanceCount = element.instanceCount;
                    }
                }
                e['added'] = instanceCount;

                return e;
            });
            //

            let sortedData: RentElementsGridItemCustom[] = sortByFn(newEntities, [sortFieldName, 'id']);
            if (!sortAsc) sortedData.reverse();

            return updateState({
                ...state,
                equipment: {
                    ...state.equipment,
                    filteredEntities: sortedData,
                    params: params,
                },
            });

        case ACTION_TYPES.SET_PRODUCTS_LIST_PARAMS_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    params: {
                        ...state.products.params,
                        limit: action.payload.limit !== undefined ? action.payload.limit : state.products.params.limit,
                        page: action.payload.page !== undefined ? action.payload.page : state.products.params.page,
                        sortOrder: action.payload.sortOrder !== undefined ? action.payload.sortOrder : state.products.params.sortOrder,
                        sortBy: action.payload.sortBy !== undefined ? action.payload.sortBy : state.products.params.sortBy,
                        categoryIds:
                            action.payload.categoryIds !== undefined ? action.payload.categoryIds : state.products.params.categoryIds,
                        startDate: action.payload.startDate !== undefined ? action.payload.startDate : state.products.params.startDate,
                        endDate: action.payload.endDate !== undefined ? action.payload.endDate : state.products.params.endDate,
                        search: action.payload.search !== undefined ? action.payload.search : state.products.params.search,
                        problem: 'problem' in action.payload ? action.payload.problem : state.products.params.problem, //kkk1(action.payload),
                        finalTotalPrice: test1('' + action.payload.finalTotalPrice, state.products.params.finalTotalPrice),
                        rentInterval:
                            action.payload.rentInterval !== undefined ? action.payload.rentInterval : state.products.params.rentInterval,
                        nomenclatureEntityTypeCode:
                            'nomenclatureEntityTypeCode' in action.payload
                                ? action.payload.nomenclatureEntityTypeCode
                                : state.products.params.nomenclatureEntityTypeCode,
                    },
                },
            };

        case ACTION_TYPES.RESET_PRODUCTS_LIST_FILTERS_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    params: {
                        ...state.products.params,
                        search: undefined,
                        categoryIds: undefined,
                        problem: undefined,
                        finalTotalPrice: initialProductsParamsState.finalTotalPrice,
                        startDate: state.products.params.startDate,
                        endDate: state.products.params.endDate,
                        rentInterval: state.products.params.rentInterval,
                        nomenclatureEntityTypeCode: undefined,
                    },
                },
            };

        case ACTION_TYPES.ADD_ELEMENT: {
            let elements = [...state.elements.entities],
                element = action.payload.element;

            let newElements: OperationElement[];

            if (Array.isArray(element)) {
                newElements = element;
            } else {
                newElements = [element];
            }

            if (action.payload.xx) {
                newElements.forEach((element) => {
                    element['tmpId'] = element.id;
                });
            }

            // Ищем элемент с такими же параметрами
            // Не искать в составе набора
            // Если найден, то обновляем кол-во
            // Иначе создаем новый

            newElements.forEach((element) => {
                if (element.variantId === null) element.variantId = undefined;
                if (element.variantIdOriginal === null) element.variantIdOriginal = undefined;

                let addedElement = elements.find((item: OperationElement) => {
                    return (
                        !isDefined(item.kitId) &&
                        item.id < 0 &&
                        !item.parentId &&
                        item.productId === element.productId &&
                        item.variantId === element.variantId &&
                        item.discount === element.discount &&
                        item.shiftCount === element.shiftCount &&
                        item.effectivePrice === element.effectivePrice &&
                        item.rentPeriodStartDate.toString() === element.rentPeriodStartDate.toString() &&
                        item.rentPeriodEndDate.toString() === element.rentPeriodEndDate.toString()
                    );
                });

                let instanceTracked =
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;

                if (element.id === 0 && addedElement && !instanceTracked) {
                    addedElement.instanceCount += element.instanceCount;
                }
                if (
                    element.id === 0 &&
                    addedElement &&
                    instanceTracked &&
                    (element.instanceIds || []).length === 0 &&
                    element.anonymousInstanceCount > 0
                ) {
                    addedElement.instanceCount += element.instanceCount;
                    addedElement.anonymousInstanceCount += element.anonymousInstanceCount;
                } else if (element.id > 0 && !action.payload.subrentForShortage) {
                    elements.push(element);
                } else {
                    let id = 0;
                    elements.forEach((item) => {
                        id = Math.min(item.id, id);
                    });
                    id = id - 1;
                    element.id = id;
                    elements.push(element);
                }
            });

            let timeTables = state.timeTables;

            if (action.payload.xx) {
                elements.forEach((element) => {
                    if (element.parentId) {
                        let el = elements.find((el) => el['tmpId'] === element.parentId);
                        if (el) {
                            element.parentId = el.id;
                        }
                    }
                });
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
                timeTables,
                subrentForShortage: action.payload.subrentForShortage,
            });
        }

        case ACTION_TYPES.UPDATE_ELEMENT: {
            let elements = [...state.elements.entities],
                element = action.payload.element;

            let elIndex = elements.findIndex((el) => el.id === element.id);
            if (elIndex > -1) elements[elIndex] = element;

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.ADD_INSTANCE_FROM_SCANNER: {
            let instanceEntity: InstanceInfoRead = action.payload.entity;
            let productRecord = action.payload.nomenclature;

            let rentPeriodStartDate = state.rentPeriodStartDate || new Date();
            let rentPeriodEndDate = state.rentPeriodEndDate || new Date();
            let elements1 = state.elements.entities;

            // в первую очередь ищем обязательства с анонимными экземплярами с той же номенлктурой и параметрами аренды как в операции

            let addedElement;
            let insteadOfAnonymous = false;

            // ищем не в наборах
            addedElement = elements1.find((item: OperationElement) => {
                return (
                    !isDefined(item.kitId) &&
                    !item.parentId &&
                    item.productId == instanceEntity.productId &&
                    item.variantId == instanceEntity.variantId &&
                    item.isCancelled !== true &&
                    item.rentPeriodStartDate.toString() === rentPeriodStartDate.toString() &&
                    item.rentPeriodEndDate.toString() === rentPeriodEndDate.toString() &&
                    !(item.instanceIds || []).includes(instanceEntity.id) &&
                    item.anonymousInstanceCount > 0
                );
            });

            // ищем в наборах
            if (!addedElement) {
                addedElement = elements1.find((item: OperationElement) => {
                    return (
                        item.parentId &&
                        item.productId == instanceEntity.productId &&
                        item.variantId == instanceEntity.variantId &&
                        item.isCancelled !== true &&
                        item.rentPeriodStartDate.toString() === rentPeriodStartDate.toString() &&
                        item.rentPeriodEndDate.toString() === rentPeriodEndDate.toString() &&
                        !(item.instanceIds || []).includes(instanceEntity.id) &&
                        item.anonymousInstanceCount > 0
                    );
                });
            }

            if (addedElement) insteadOfAnonymous = true;

            // Ищем как раньше
            if (!addedElement) {
                addedElement = elements1.find((item: OperationElement) => {
                    return (
                        !isDefined(item.kitId) &&
                        !item.parentId &&
                        item.productId == instanceEntity.productId &&
                        item.variantId == instanceEntity.variantId &&
                        item.isCancelled !== true &&
                        item.rentPeriodStartDate.toString() === rentPeriodStartDate.toString() &&
                        item.rentPeriodEndDate.toString() === rentPeriodEndDate.toString()
                    );
                });
            }

            let elements;

            let newElement: OperationElement;
            if (addedElement) {
                newElement = addedElement;
                if (!newElement.instanceIds) newElement.instanceIds = [];
                newElement.instanceIds?.push(instanceEntity.id);

                if (insteadOfAnonymous) {
                    newElement.anonymousInstanceCount--;
                } else {
                    newElement.instanceCount += 1;
                    if (newElement.id < 0) newElement.instanceCountOriginal += 1;
                }
                elements = [...state.elements.entities];
            } else {
                let newElementId = 0;
                state.elements.entities.forEach((item) => {
                    newElementId = Math.min(item.id, newElementId);
                });
                newElementId = newElementId - 1;

                let shiftCount = state.shiftCount;

                if (!isDefined(shiftCount)) {
                    if (productRecord.shiftLengthInMinutes)
                        shiftCount = getShiftCountFromDates(
                            moment(state.rentPeriodStartDate),
                            moment(state.rentPeriodEndDate),
                            productRecord.shiftLengthInMinutes,
                            state.shiftCountRoundingType
                        );
                }

                newElement = {
                    id: newElementId,
                    businessVersion: 0,
                    productId: instanceEntity.productId,
                    productShortName: instanceEntity.productShortName,
                    variantId: instanceEntity.variantId,
                    variantName: instanceEntity.variantName,

                    rentPeriodStartDate: state.rentPeriodStartDate || new Date(),
                    rentPeriodEndDate: state.rentPeriodEndDate || new Date(),

                    mainImage: productRecord.mainImage,

                    shiftCount: shiftCount,
                    calendarShiftCount: shiftCount || 0,
                    discount: state.discount,
                    instanceCount: 1,
                    finalTotalPrice: 0,
                    effectivePrice: productRecord.pricePerShift,
                    //stockInstanceCountX: 0,
                    availableInstanceCount: 0,
                    instanceCountOriginal: 1,
                    discountChanged: false,
                    rentPeriodChanged: false,

                    shiftCountChanged: false,
                    disabled: false,
                    anonymousInstanceCount: 0,

                    leftoverInstanceCount: 0,
                    leftoverAnonymousInstanceCount: 0,
                    warnings: [],
                    problems: [],

                    instanceIds: [instanceEntity.id],

                    instanceTrackingTypeCode: instanceEntity.variantId
                        ? InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                        : InstanceTrackingTypeCodeEnum.INSTANCETRACKED,

                    shiftLengthInMinutes: productRecord.shiftLengthInMinutes,
                    hasOwnShiftLength: productRecord.productHasOwnShiftLength,
                    requiredTimeIndentBetweenElementsInMinutes: productRecord.requiredTimeIndentBetweenElementsInMinutes,
                    productHasOwnRequiredTimeIndentBetweenElements: productRecord.productHasOwnRequiredTimeIndentBetweenElements,

                    externalCode: productRecord.externalCode,

                    pricingSchemeId: productRecord.pricingScheme,
                };

                elements = [...state.elements.entities, newElement];
            }

            //saveOrUpdateTimetables(action.payload.records || [], state.timeTables, state.targetStateCode || state.typeCode);

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.ADD_KIT: {
            let elements = [...state.elements.entities];

            let instanceCount = action.payload.element.instanceCount;

            for (let i = 0; i < instanceCount; ++i) {
                let element = clone(action.payload.element);

                if (element.variantId === null) element.variantId = undefined;

                element.instanceCount = 1;
                let kitMemebers = action.payload.members.map((item) => clone(item)); //clone(action.payload.members);

                let id = 0;
                elements.forEach((item) => {
                    id = Math.min(item.id, id);
                });
                id = id - 1;
                element.id = id;
                elements.push(element);

                kitMemebers.forEach((kitMemeber) => {
                    id = id - 1;
                    kitMemeber.id = id;
                    elements.push(kitMemeber);
                    kitMemeber.parentId = element.id;
                });
            }

            console.log('UUUUUUU', elements);
            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.EDIT_ELEMENT: {
            let elements = [...state.elements.entities];
            let element = elements.find((item: OperationElement) => {
                return item.id === action.payload.elementId;
            });

            if (element) {
                element.rentPeriodStartDate = action.payload.startDate;
                element.rentPeriodEndDate = action.payload.endDate;
                element.discount = action.payload.discount;
                element.shiftCount = action.payload.shiftCount;
                element.instanceCount = action.payload.instanceCount;

                element.shiftCountChanged = action.payload.shiftCountChanged;

                element.discountChanged = action.payload.discountChanged;
                element.rentPeriodChanged = action.payload.rentPeriodChanged;

                element.effectivePrice = action.payload.effectivePrice || 0;

                element.pricingSchemeId = action.payload.pricingSchemeId;

                if (
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
                ) {
                    element.instanceIds = action.payload.instanceIds?.slice();
                    element.anonymousInstanceCount = action.payload.anonumousInstanceCount || 0;
                    element.instanceCount = (action.payload.instanceIds?.length || 0) + (element.anonymousInstanceCount || 0);

                    let elementIndex = elements.indexOf(element);
                    if (elementIndex > -1) {
                        if (element.instanceCount <= 0) {
                            element.instanceCount = 0;
                            elements.splice(elementIndex, 1);
                        }
                    }
                }

                if (element.id < 0) {
                    // Когда редактируем новое обязательство (элемент), задаем instanceCountOriginal как instanceCount
                    element.instanceCountOriginal = element.instanceCount;
                    element.anonymousInstanceCountOriginal = element.anonymousInstanceCount;
                }
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_OPERATION_START_DATE_TO_CURRENT: {
            let startDate: Moment = moment(/*Date.now()*/ state.operationStartTime);
            let endDate: Moment = moment(state.rentPeriodEndDate);
            let diff =
                startDate && endDate
                    ? getShiftCountFromDates(startDate, endDate, state.shiftLengthInMinutes, state.shiftCountRoundingType)
                    : 0;

            let elementsEntities = state.elements.entities.map((element) => {
                if (!action.payload.xxx) {
                    element.rentPeriodStartDate = startDate?.toDate();
                    element.rentPeriodChanged = element.rentPeriodEndDate.getTime() !== endDate.valueOf();
                    if (action.payload.recalculateShiftCount) {
                        let diffEl = getShiftCountFromDates(
                            moment(element.rentPeriodStartDate),
                            moment(element.rentPeriodEndDate),
                            element.shiftLengthInMinutes,
                            state.shiftCountRoundingType
                        );
                        element.shiftCount = diffEl;
                        element.shiftCountChanged = diff !== diffEl;
                    }
                } else {
                    element.rentPeriodStartDate = startDate?.toDate();
                }
                return element;
            });

            if (!action.payload.xxx) {
                return updateState({
                    ...state,
                    rentPeriodStartDate: startDate ? startDate.toDate() : undefined,
                    rentPeriodEndDate: endDate ? endDate.toDate() : undefined,
                    shiftCount: action.payload.recalculateShiftCount ? diff : state.shiftCount,

                    products: {
                        ...state.products,
                        params: {
                            ...state.products.params,
                            startDate: startDate ? startDate.valueOf() : undefined,
                            endDate: endDate ? endDate.valueOf() : undefined,
                        },
                    },
                    elements: {
                        ...state.elements,
                        entities: elementsEntities,
                    },
                });
            } else {
                return updateState({
                    ...state,
                    rentPeriodStartDate: startDate ? startDate.toDate() : undefined,
                    rentPeriodEndDate: endDate ? endDate.toDate() : undefined,
                    products: {
                        ...state.products,
                        params: {
                            ...state.products.params,
                            startDate: startDate ? startDate.valueOf() : undefined,
                            rentInterval: [
                                moment(startDate),
                                state.products.params.rentInterval ? state.products.params.rentInterval[1] : moment(endDate),
                            ],
                        },
                    },
                    elements: {
                        ...state.elements,
                        entities: elementsEntities,
                    },
                });
            }
        }

        case ACTION_TYPES.SET_OPERATION_END_DATE_TO_CURRENT: {
            let startDate: Moment = moment(state.rentPeriodStartDate);
            let endDate: Moment = moment(/*Date.now()*/ state.operationStartTime);
            let diff =
                startDate && endDate
                    ? getShiftCountFromDates(startDate, endDate, state.shiftLengthInMinutes, state.shiftCountRoundingType)
                    : 0;

            let elementsEntities = state.elements.entities.map((element) => {
                if (!action.payload.xxx) {
                    element.rentPeriodEndDate = endDate?.toDate();
                    element.rentPeriodChanged = element.rentPeriodStartDate.getTime() !== startDate.valueOf();
                    if (action.payload.recalculateShiftCount) {
                        let diffEl = getShiftCountFromDates(
                            moment(element.rentPeriodStartDate),
                            moment(element.rentPeriodEndDate),
                            element.shiftLengthInMinutes,
                            state.shiftCountRoundingType
                        );
                        element.shiftCount = diffEl;
                        element.shiftCountChanged = diff !== diffEl;
                    }
                } else {
                    element.rentPeriodEndDate = endDate?.toDate();
                }
                return element;
            });

            if (!action.payload.xxx) {
                return updateState({
                    ...state,
                    rentPeriodStartDate: startDate ? startDate.toDate() : undefined,
                    rentPeriodEndDate: endDate ? endDate.toDate() : undefined,
                    shiftCount: action.payload.recalculateShiftCount ? diff : state.shiftCount,

                    products: {
                        ...state.products,
                        params: {
                            ...state.products.params,
                            endDate: action.payload.yyy && endDate ? endDate.valueOf() : state.products.params.endDate,
                            rentInterval: action.payload.yyy ? [moment(startDate), moment(endDate)] : state.products.params.rentInterval,
                        },
                    },
                    elements: {
                        ...state.elements,
                        entities: elementsEntities,
                    },
                });
            } else {
                return updateState({
                    ...state,
                    rentPeriodStartDate: startDate ? startDate.toDate() : undefined,
                    rentPeriodEndDate: endDate ? endDate.toDate() : undefined,
                    //
                    products: {
                        ...state.products,
                        params: {
                            ...state.products.params,
                            endDate: endDate ? endDate.valueOf() : undefined,
                            rentInterval: [moment(startDate), moment(endDate)],
                        },
                    },
                    elements: {
                        ...state.elements,
                        entities: elementsEntities,
                    },
                });
            }
        }

        case ACTION_TYPES.ADD_INSTANCE_TO_EXISTED_OPERATION_ELEMENT: {
            //
            let entities = [...state.elements.entities],
                element = entities.find((element) => element.id === action.payload.element.id);

            if (element) {
                if (
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                ) {
                    if (action.payload.instanceId) {
                        if (element.instanceIds) element.instanceIds.push(action.payload.instanceId);
                        else element.instanceIds = [action.payload.instanceId];

                        if (element.anonymousInstanceCount > 0) {
                            if (action.payload.insteadOfAnonymous) {
                                element.anonymousInstanceCount--;
                            } else {
                                element.instanceCount += 1;
                            }
                        } else {
                            element.instanceCount += 1;
                        }
                    } else {
                        element.anonymousInstanceCount += 1;
                        element.instanceCount += 1;
                    }
                } else {
                    element.instanceCount += 1;
                }

                //element.instanceCountOriginal += 1;
            }

            //saveOrUpdateTimetables(action.payload.records || [], state.timeTables, state.targetStateCode || state.typeCode);

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: entities,
                },
            });
        }

        case ACTION_TYPES.ADD_NEW_OPERATION_ELEMENT_FROM_EQUIPMENT: {
            let entities = [...state.elements.entities];
            //entities.push(...action.payload.elements);

            //saveOrUpdateTimetables(action.payload.records || [], state.timeTables, state.targetStateCode || state.typeCode);

            action.payload.elements.forEach((el) => {
                let index = entities.findIndex((e) => e.id === el.id);
                if (index > -1) {
                    entities[index] = el;
                } else {
                    entities.push(el);
                    if (el.productId) {
                        let timeTable = findTimeTable(state.timeTables, {
                            productId: el.productId,
                            variantId: el.variantId,
                        });
                        if (timeTable) {
                            el.hasOwnShiftLength = timeTable.hasOwnShiftLength;
                            el.shiftLengthInMinutes = timeTable.shiftLengthInMinutes;
                            if(state.autoRecalculateShiftsCountMode){
                                let shiftCount = getShiftCountFromDates(
                                    moment(el.rentPeriodStartDate),
                                    moment(el.rentPeriodEndDate),
                                    el.shiftLengthInMinutes,
                                    state.shiftCountRoundingType
                                );
                                el.shiftCount = shiftCount;
                            }
                        }
                    }
                }
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: entities,
                },
            });
        }

        case ACTION_TYPES.CHANGE_VARIANT_OF_ELEMENT:
            // Пока по простому берем и меняем id варианты и название

            let elements = state.elements.entities.map((element) => {
                if (element.id === action.payload.elementId) {
                    element.variantId = action.payload.newVariantId;
                    element.variantName = action.payload.newVariantName;
                    element.effectivePrice = action.payload.effectivePrice;
                    element.externalCode = action.payload.externalCode;

                    if (element.instanceIds && element.instanceIds.length > 0) {
                        element.instanceIds = [];
                        element.anonymousInstanceCount = element.instanceCount;
                    }
                }
                return element;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });

        case ACTION_TYPES.MOVE_ELEMENT_TO_KIT: {
            // Все выбранные элементы перемещаем в набор
            action.payload.elementIds.forEach((id) => {
                let elIndex = state.elements.entities.findIndex((element) => element.id === id);
                if (elIndex > -1) {
                    let elements = state.elements.entities.splice(elIndex, 1);
                    elements[0].parentId = action.payload.kitId;
                    elements[0].mainKitMember = false;
                    state.elements.entities.push(...elements);
                }
            });

            // Если есть пустые наборы, то удалить их
            let kits = state.elements.entities.filter((element) => element.kitId);
            kits.forEach((kit) => {
                if (!state.elements.entities.find((element) => element.parentId === kit.id)) {
                    state.elements.entities.splice(state.elements.entities.indexOf(kit), 1);
                }
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: state.elements.entities,
                },
            });
        }

        case ACTION_TYPES.MOVE_ELEMENT_FROM_KIT: {
            let el = state.elements.entities.find((element) => element.id === action.payload.elementId);
            if (el) {
                el.parentId = undefined;
                el.mainKitMember = false;
            }

            let kits = state.elements.entities.filter((element) => element.kitId);
            kits.forEach((kit) => {
                if (!state.elements.entities.find((element) => element.parentId === kit.id)) {
                    state.elements.entities.splice(state.elements.entities.indexOf(kit), 1);
                }
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: state.elements.entities,
                },
            });
        }

        case ACTION_TYPES.SET_BA_PREFERENCES: {
            return updateState({
                ...state,
                shiftLengthInMinutes: action.payload.shiftLengthInMinutes,
                shiftCountRoundingType: action.payload.shiftCountRoundingType,
                requiredTimeIndentBetweenElementsInMinutes: action.payload.requiredTimeIndentBetweenElementsInMinutes,
            });
        }

        case ACTION_TYPES.CHANGE_OPERATION_TYPE: {
            const typeCode = action.payload.operationType;
            if (
                typeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
                typeCode === OperationTypeCodeEnum.RETURN ||
                typeCode === OperationTypeCodeEnum.RETURNBROKEN ||
                typeCode === OperationTypeCodeEnum.LOSTNORETURN ||
                typeCode === OperationTypeCodeEnum.PROLONG ||
                typeCode === OperationTypeCodeEnum.SUBRENTPROLONG
            ) {
                state.elements.entities.forEach((element) => {
                    element.keepLeftover = true;
                });
            }

            state.elements.entities.forEach((element) => {
                element.disabled = typeCode === OperationTypeCodeEnum.CANCEL || typeCode === OperationTypeCodeEnum.SUBRENTCANCEL;
            });

            let startDate = state.rentPeriodStartDate;
            let endDate = state.rentPeriodEndDate;

            if (state.actualizeDateMode) {
                if (isRentOrSubrentOperation(action.payload.correctionCode || typeCode)) {
                    startDate = state.operationStartTime; //?.toDate();
                } else if (isReturnOrSubrentReturnOperation(action.payload.correctionCode || typeCode)) {
                    endDate = state.operationStartTime; //?.toDate();
                }
            }

            return updateState({
                ...state,
                rentPeriodStartDate: moment(startDate).toDate(),
                rentPeriodEndDate: moment(endDate).toDate(),
                products: {
                    ...state.products,
                    params: {
                        ...state.products.params,
                        startDate: startDate ? moment(startDate).valueOf() : undefined,
                        endDate: endDate ? moment(endDate).valueOf() : undefined,
                        rentInterval: [moment(startDate), moment(endDate)],
                    },
                },
                elements: {
                    ...state.elements,
                    entities: state.elements.entities,
                },
                typeCode: typeCode,
                targetStateCode: action.payload.correctionCode,
            });
        }

        case ACTION_TYPES.SET_KEEPLEFTOVER: {
            let el = state.elements.entities.find((element) => element.id === action.payload.elementId);
            if (el) el.keepLeftover = action.payload.keepLeftover;

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: state.elements.entities,
                },
            });
        }

        case ACTION_TYPES.RESET_ALL_KEEPLEFTOVER: {
            state.elements.entities.forEach((element) => {
                element.keepLeftover = false;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: state.elements.entities,
                },
            });
        }

        // Добавление из комплектации
        //
        case ACTION_TYPES.ADD_ELEMENT_FROM_EQUIPMENT: {
            // Нужно загрузить список всех экземпляров, которые можем добавить

            let entities = [...state.elements.entities],
                element = entities.find((element) => element.id === action.payload.equipmentElement.id);

            if (element) {
                if (
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK ||
                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK
                ) {
                    element.instanceCount += 1;
                } else {
                    // Добавить случайный экземпляр
                    console.log('XXX1');
                    if (action.payload.equipmentElement.instanceIds && element.instanceIds) {
                        console.log('XXX2');
                        let ss = action.payload.equipmentElement.instanceIds.filter((id) => !element?.instanceIds?.includes(id));
                        if (ss.length > 0) element?.instanceIds.push(ss[0]);
                        else element.anonymousInstanceCount += 1;
                        element.instanceCount += 1;
                    }
                }
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities,
                },
            });
        }

        case ACTION_TYPES.ADD_ELEMENT_ITEM_BY_NOMENCLATURE: {
            const nomenclature = action.payload.nomenclature;
            const kitMembers = action.payload.kitMembers;
            let elements = state.elements.entities;
            let newElement: OperationElement | undefined;
            if (nomenclature.kitId) {
                newElement = OperationFormUtils.createNewKitElement(nomenclature, state); // Набор нужно новый добавить
            } else {
                newElement = OperationFormUtils.createNewProductElement(nomenclature, state); // Тут Продукт/Вариант
            }
            let addedElement: OperationElement | undefined; // = !nomenclature.kitId ? OperationFormUtils.findNomenclatureInElements(nomenclature, state) : undefined;

            //let records = action.payload.records ? [...action.payload.records, action.payload.nomenclature] : [action.payload.nomenclature];
            //saveOrUpdateTimetables(records, state.timeTables, state.targetStateCode || state.typeCode);

            //
            if (!nomenclature.kitId) {
                let startDate = state.products.params.startDate ? new Date(state.products.params.startDate) : new Date();
                let endDate = state.products.params.endDate ? new Date(state.products.params.endDate) : new Date();
                let discount = state.discount;
                let shiftCount = state.shiftCount;

                addedElement = elements.find((item: OperationElement) => {
                    return (
                        item.id < 0 &&
                        item.productId == nomenclature.productId &&
                        item.variantId == nomenclature.variantId &&
                        item.kitId == nomenclature.kitId &&
                        item.discount === discount &&
                        item.pricingSchemeId?.id === nomenclature.pricingScheme?.id &&
                        item.effectivePrice === nomenclature.pricePerShift &&
                        ((shiftCount !== undefined && item.shiftCount === shiftCount) || shiftCount === undefined) &&
                        item.rentPeriodStartDate.toString() === startDate.toString() &&
                        item.rentPeriodEndDate.toString() === endDate.toString() &&
                        !item.parentId
                    );
                });
            }

            if (addedElement) {
                // Существующий элемент
                newElement = addedElement;
                newElement.instanceCount += 1;
                newElement.instanceCountOriginal += 1;
                if (
                    newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                    newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
                ) {
                    newElement.anonymousInstanceCount += 1;
                    newElement.anonymousInstanceCountOriginal = newElement.anonymousInstanceCount;
                }

                elements = [...state.elements.entities];
            } else {
                // Новый элемент
                let newElementId = 0;
                state.elements.entities.forEach((item) => {
                    newElementId = Math.min(item.id, newElementId);
                });
                newElementId = newElementId - 1;
                if (newElement) {
                    newElement.id = newElementId;
                    if (
                        newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                        newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
                    ) {
                        newElement.anonymousInstanceCount += 1;
                        newElement.anonymousInstanceCountOriginal = newElement.anonymousInstanceCount;
                    }
                    elements = [...state.elements.entities, newElement];

                    let kits: any[] = [];
                    if (kitMembers) {
                        kitMembers.forEach((member) => {
                            let instanceTracked =
                                member.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                                member.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;

                            let timeTable = findTimeTable(state.timeTables, {
                                productId: member.productId,
                                variantId: member.variantId,
                            });
                            let shiftLengthInMinutes = timeTable ? timeTable.shiftLengthInMinutes : undefined;
                            let hasOwnShiftLength = timeTable ? timeTable.hasOwnShiftLength : undefined;

                            let requiredTimeIndentBetweenElementsInMinutes = timeTable
                                ? timeTable.requiredTimeIndentBetweenElementsInMinutes
                                : undefined;
                            let productHasOwnRequiredTimeIndentBetweenElements = timeTable
                                ? timeTable.productHasOwnRequiredTimeIndentBetweenElements
                                : undefined;

                            let rentPeriodStartDate = state.products.params.startDate
                                ? new Date(state.products.params.startDate)
                                : new Date();
                            let rentPeriodEndDate = state.products.params.endDate ? new Date(state.products.params.endDate) : new Date();

                            let shiftCount = state.shiftCount;
                            if (!isDefined(shiftCount)) {
                                if (shiftLengthInMinutes)
                                    shiftCount = getShiftCountFromDates(
                                        moment(rentPeriodStartDate),
                                        moment(rentPeriodEndDate),
                                        shiftLengthInMinutes,
                                        state.shiftCountRoundingType
                                    );
                            }

                            let discount = state.discount;

                            kits.push(
                                createNewOperationElementFromKitMemember({
                                    //productRecord: productRecord,
                                    kitRecord: member,
                                    rentPeriodStartDate: rentPeriodStartDate,
                                    rentPeriodEndDate: rentPeriodEndDate,
                                    shiftCount: shiftCount,
                                    discount: discount,
                                    instanceCount: member.instanceCount,
                                    anonymousInstanceCount: instanceTracked ? member.instanceCount : undefined,
                                    rentPeriodChanged: false,
                                    shiftCountChanged: false,
                                    discountChanged: false,
                                    shiftLengthInMinutes: shiftLengthInMinutes,
                                    hasOwnShiftLength,
                                    requiredTimeIndentBetweenElementsInMinutes,
                                    productHasOwnRequiredTimeIndentBetweenElements,
                                })
                            );
                        });

                        kits.forEach((kitMemeber) => {
                            newElementId = newElementId - 1;
                            kitMemeber.id = newElementId;
                            elements.push(kitMemeber);
                            if (newElement) kitMemeber.parentId = newElement.id;
                        });
                    }
                }
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.ADD_INSTANCE_TO_ELEMENT: {
            let elements = [...state.elements.entities];
            let element = elements.find((el) => el.id === action.payload.elementId);
            if (element) {
                if (!element.instanceIds) element.instanceIds = [];
                element.instanceIds.push(action.payload.instanceId);
                element.anonymousInstanceCount--;
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.ADD_ELEMENT_ITEM_FROM_ELEMENTS: {
            let elements = [...state.elements.entities];
            const index = elements.findIndex((e)=>e.id === action.payload.element.id);
            if(index > -1){
                elements[index] = action.payload.element;
            }
            // let newElement: OperationElement | undefined = action.payload.element;
            // if (newElement) {
            //     newElement.instanceCount += 1;
            //     if (newElement.id < 0) newElement.instanceCountOriginal += 1;
            //     if (
            //         newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            //         newElement.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
            //     ) {
            //         if (newElement.id < 0) {
            //             // Новое
            //             newElement.anonymousInstanceCount += 1;
            //         } else {
            //             // Существующее
            //             // В начале добавляются анонимные, потом экз-ры, потом анонимные
            //             if (newElement.anonymousInstanceCount < (newElement.anonymousInstanceCountOriginal || 0)) {
            //                 newElement.anonymousInstanceCount += 1;
            //             } else {
            //                 let notAddedInstanceId = newElement.instanceIdsOriginal?.find((id) => !newElement?.instanceIds?.includes(id));
            //                 if (notAddedInstanceId) {
            //                     newElement.instanceIds = newElement.instanceIds
            //                         ? [...newElement.instanceIds, notAddedInstanceId]
            //                         : [notAddedInstanceId];
            //                 } else {
            //                     newElement.anonymousInstanceCount += 1;
            //                 }
            //             }
            //         }
            //         if (newElement.id < 0) newElement.anonymousInstanceCountOriginal = newElement.anonymousInstanceCount;
            //     }
            // }
            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.REMOVE_INSTANCE_FROM_ELEMENT: {
            const elements = [...state.elements.entities];
            const element = action.payload.element;

            // Найден тот элемент у которого нужно отнять 1!
            if (element) {
                if (element.instanceCount > 1) {
                    if (
                        element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                        element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
                    ) {
                        if (element.anonymousInstanceCount > 0) element.anonymousInstanceCount--;
                        else if (element.instanceIds && element.instanceIds.length) {
                            let newInstances: number[] = [];
                            element.instanceIds.forEach((id) => {
                                if (!element.instanceIdsOriginal || !element.instanceIdsOriginal.includes(id)) newInstances.push(id);
                            });
                            if (newInstances.length) {
                                element.instanceIds.splice(element.instanceIds.indexOf(newInstances[newInstances.length - 1]), 1);
                            } else {
                                element.instanceIds.pop();
                            }
                        }
                        element.instanceCount = (element.instanceIds?.length || 0) + element.anonymousInstanceCount;
                    } else {
                        element.instanceCount -= 1;
                    }
                } else {
                    let elementIndex = elements.indexOf(element);
                    if (elementIndex > -1) {
                        elements.splice(elementIndex, 1);
                        // Может оказаться набор без элементов внутри
                        if (!elements.find((el) => el.parentId === element.parentId)) {
                            let kitEl = elements.find((el) => el.id === element.parentId);
                            if (kitEl) elements.splice(elements.indexOf(kitEl), 1);
                        }
                        // Если это набор, то нужно удалить всех его детей
                        if (element.kitId) {
                            for (let i = 0; i < elements.length; i++) {
                                if (elements[i].parentId === element.id) {
                                    elements.splice(i, 1);
                                    i--;
                                }
                            }
                        }
                    }
                }
            }

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.CANCEL_OPERATION_ELEMENT: {
            const elements = state.elements.entities;
            const element = elements.find((el) => el.id === action.payload.elementId);
            if (element) {
                element.isCancelled = action.payload.cancelled;
            }
            return updateState(state);
        }

        case ACTION_TYPES.ADD_KITS_INFO: {
            let kitsInfo = [...state.kitsInfo];
            action.payload.kitsInfo.forEach((k) => {
                // TODO Если нет то записывать, если есть то перезаписывать
                const i = kitsInfo.findIndex((kk) => kk.id === k.id);
                if (i > -1) kitsInfo[i] = k;
                else kitsInfo.push(k);
            });
            return { ...state, kitsInfo };
        }

        // case ACTION_TYPES.ADD_NOMENCLATURES: {
        //     let nomenclatures = [...state.nomenclatures];
        //     action.payload.nomenclatures.forEach((n) => {
        //         // TODO Если нет то записывать, если есть то перезаписывать
        //         let i;
        //         if (isDefined(n.kitId)) {
        //             // Набор
        //             i = nomenclatures.findIndex((nn) => nn.kitId === n.kitId);
        //         } else if (isDefined(n.productId) && !isDefined(n.variantId)) {
        //             // Продукт
        //             i = nomenclatures.findIndex((nn) => nn.productId === n.productId);
        //         } else if (isDefined(n.productId) && isDefined(n.variantId)) {
        //             // Вариант
        //             i = nomenclatures.findIndex((nn) => nn.productId === n.productId && nn.variantId === n.variantId);
        //         }
        //         if (i > -1) nomenclatures[i] = n;
        //         else nomenclatures.push(n);
        //     });
        //     saveOrUpdateTimetables(action.payload.nomenclatures, state.timeTables, state.targetStateCode || state.typeCode);
        //     return { ...state, nomenclatures };
        // }

        case ACTION_TYPES.SET_START_DATE_FOR_ALL_ELEMENTS: {
            const elementsEntities = state.elements.entities.map((el) => {
                el.rentPeriodStartDate = action.payload.date;
                return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.SET_END_DATE_FOR_ALL_ELEMENTS: {
            const elementsEntities = state.elements.entities.map((el) => {
                el.rentPeriodEndDate = action.payload.date;
                return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elementsEntities,
                },
            });
        }

        case ACTION_TYPES.SET_COMMENT:
            return {
                ...state,
                currentOperation: state.currentOperation
                    ? {
                          ...state.currentOperation,
                          comment: action.payload,
                      }
                    : undefined,
            };

        case ACTION_TYPES.SET_COMMENT_VISIBILITY:
            return {
                ...state,
                currentOperation: state.currentOperation
                    ? {
                          ...state.currentOperation,
                          commentVisible: action.payload,
                      }
                    : undefined,
            };

        case ACTION_TYPES.UPDATE_TIMETABLES: {
            // action.payload.elementsDelayedReturnDates
            // const elementsDelayedReturnDates = action.payload.elementsDelayedReturnDates && action.payload.elementsDelayedReturnDates.length > 0 ? {...state.elementsDelayedReturnDates} : state.elementsDelayedReturnDates;
            // action.payload.elementsDelayedReturnDates?.forEach((item)=>{
            //     elementsDelayedReturnDates[item.id] = item.date;
            // });
            const newState = {
                ...state,
                timeTables: action.payload.timetables,
                elementsDelayedReturnDates: action.payload.elementsDelayedReturnDates,
            };
            if (action.payload.updateState) return updateState(newState);
            else return newState;
        }

        case ACTION_TYPES.SET_STATE: {
            return {
                ...action.payload,
            };
        }

        case ACTION_TYPES.SET_EFFECTIVE_PRICE_AND_DISCOUNT_FOR_SELECTED_IDS: {
            // effectivePrice
            const elements = state.elements.entities.map((el) => {
                if (action.payload.selectedIds.includes(el.id)) {
                    return {
                        ...el,
                        discount: isDefined(action.payload.discount) ? action.payload.discount : el.discount,
                        effectivePrice: isDefined(action.payload.price) ? action.payload.price : el.effectivePrice,
                    };
                } else return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_FINAL_PRICE_PER_SHIFT_FOR_SELECTED_IDS: {
            // finalPricePerShift
            const elements = state.elements.entities.map((el) => {
                if (action.payload.selectedIds.includes(el.id)) {
                    let pricePerShift = getElementPricePerShift(el);
                    const discount = 100 - (action.payload.price * 100) / pricePerShift;
                    return {
                        ...el,
                        discount,
                    };
                } else return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_FINAL_TOTAL_PRICE_FOR_SELECTED_IDS: {
            // finalTotalPrice
            const elements = state.elements.entities.map((el) => {
                if (action.payload.selectedIds.includes(el.id)) {
                    let pricePerShift = getElementPricePerShift(el);
                    let priceWithoutDiscount = MoneyUtils.calculateFinalTotalPrice(pricePerShift, el.instanceCount, el.shiftCount!);
                    let discount =
                        priceWithoutDiscount !== 0 ? ((priceWithoutDiscount - action.payload.price) * 100) / priceWithoutDiscount : 0;
                    return {
                        ...el,
                        discount,
                    };
                } else return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_DATES_FOR_SELECTED_IDS: {

            const elements = state.elements.entities.map((el) => {
                if (action.payload.selectedIds.includes(el.id)) {
                    const rentPeriodStartDate = action.payload.dates[0] || el.rentPeriodStartDate;
                    const rentPeriodEndDate = action.payload.dates[1] || el.rentPeriodEndDate;

                    return {
                        ...el,
                        rentPeriodStartDate,
                        rentPeriodEndDate
                    };
                } else return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_SHIFT_COUNT_FOR_SELECTED_IDS: {

            const elements = state.elements.entities.map((el) => {
                if (action.payload.selectedIds.includes(el.id)) {

                    return {
                        ...el,
                        shiftCount: action.payload.shiftCount //
                    };
                } else return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.RECALCULATE_SHIFT_COUNT_FOR_SELECTED_IDS: {
            const elements = state.elements.entities.map((el) => {
                if (action.payload.selectedIds.includes(el.id)) {
                    return {
                        ...el,
                        shiftCount: el.calendarShiftCount //
                    };
                } else return el;
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_TOTAL_DISCOUNT: {
            const newTotalDiscount = action.payload.price;
            const elements = state.elements.entities.map((el) => {
                if (el.kitId) {
                    return el;
                } else {
                    let discount = !state.priceBeforeDiscount ? 0 : (newTotalDiscount * 100) / state.priceBeforeDiscount;
                    return {
                        ...el,
                        discount,
                    };
                }
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_FINAL_TOTAL_PRICE: {
            const oldPrice = state.finalTotalPrice;
            const newPrice = action.payload.price;
            const diff = oldPrice - newPrice;

            const elements = state.elements.entities.map((el) => {
                if (el.kitId) {
                    return el;
                } else {
                    let pricePerShift = getElementPricePerShift(el);
                    let priceWithoutDiscount = MoneyUtils.calculateFinalTotalPrice(pricePerShift, el.instanceCount, el.shiftCount!);
                    const p = !el.finalTotalPrice ? 0 : 100 / (oldPrice / el.finalTotalPrice);
                    const diff2 = (diff * p) / 100;
                    let discount =
                        priceWithoutDiscount !== 0
                            ? ((priceWithoutDiscount - (el.finalTotalPrice - diff2)) * 100) / priceWithoutDiscount
                            : 0;

                    return {
                        ...el,
                        discount,
                    };
                }
            });

            return updateState({
                ...state,
                elements: {
                    ...state.elements,
                    entities: elements,
                },
            });
        }

        case ACTION_TYPES.SET_NOMENCLATURES: {
            return updateState({
                ...state,
                products: {
                    ...state.products,
                    entities: action.payload as any,
                    //loading: false,
                    //entities: records as InstancePropertiesEntityRecordCustom[],
                    //filteredCount: action.payload.data.listAttributes.filteredCount,
                },
            });
        }

        default:
            return state;
    }
};

// Когда какой-то параметр изменился, то все пересчитываем
function updateState(state: OperationFormState): OperationFormState {
    let operation_instanceCount = 0;
    let operation_priceBeforeDiscount = 0;
    let operation_discountAmount = 0;
    let operation_finalTotalPrice = 0;
    let isSubrent = state.isSubrent;//isSubrentOperation(state.typeCode, state.targetStateCode);
    let availabilityFactor = isSubrent ? -1 : 1;


    // Высчитываем, может ли обязательство быть отменяемым
    //
    state.elements.entities.forEach((entity) => {
        if (entity.id > -1 && !isDefined(entity.kitId) && operationElementCanBeCancelled(state.typeCode, entity))
            entity.canBeCancelled = true;
        else entity.canBeCancelled = false;

        if (!entity.canBeCancelled) {
            entity.isCancelled = undefined;
        } else {
            if (entity.isCancelled === undefined) entity.isCancelled = false;
        }

        // Если у набора все дети отменены, то и он отменен
        if (isDefined(entity.kitId)) {
            if (state.elements.entities.filter((el) => el.parentId === entity.id).every((el) => el.isCancelled)) {
                entity.isCancelled = true;
            } else entity.isCancelled = undefined;
        }
    });

    // Для каждого элемента операции расчитываем его: скидку, кол-во смен, дату начала и завершения, цену, цену со скидкой
    // + складываем показатели по всем элементам: кол-во экземпляров, сумма до скидки, сумма скидки, сумма со скидкой
    // НЕ УЧИТЫВАЕМ НАБОРЫ
    state.elements.entities = state.elements.entities.map((entity) => {
        if (!entity.kitId) {
            let discount = entity.discount;
            let discountChanged = entity.discount !== state.discount;
            let shiftCount = entity.shiftCount;
            let calendarShiftCount = getShiftCountFromDates(
                moment(entity.rentPeriodStartDate),
                moment(entity.rentPeriodEndDate),
                entity.shiftLengthInMinutes,
                state.shiftCountRoundingType
            );
            let shiftCountChanged;
            if (state.shiftCount !== undefined) {
                shiftCountChanged = entity.shiftCount !== state.shiftCount;
            } else {
                shiftCountChanged = shiftCount !== calendarShiftCount;
            }
            let rentPeriodStartDate = entity.rentPeriodStartDate;
            let rentPeriodEndDate = entity.rentPeriodEndDate;
            let rentPeriodChanged =
                rentPeriodStartDate.valueOf() !== state.rentPeriodStartDate?.valueOf() ||
                rentPeriodEndDate.valueOf() !== state.rentPeriodEndDate?.valueOf();

            let rentPeriodStartDateChanged = rentPeriodStartDate.valueOf() !== state.rentPeriodStartDate?.valueOf();
            let rentPeriodEndDateChanged = rentPeriodEndDate.valueOf() !== state.rentPeriodEndDate?.valueOf();

            let pricingScheme = entity.pricingSchemeId;

            // Цена экземпляра за смену, с учетом ценовой схемы
            let pricePerShift =
                entity.pricingSchemeId && pricingScheme
                    ? PricingSchemeUtils.calculatePriceByPricingScheme({
                          shiftCount: shiftCount || 0,
                          basePrice: entity.effectivePrice,
                          pricingScheme,
                      })
                    : entity.effectivePrice;

            // Стоимость всего обязательства без скидки
            const totalPriceWithoutDiscount = MoneyUtils.calculateFinalTotalPrice(pricePerShift, entity.instanceCount, shiftCount || 0);
            // Стоимость экземпляра за смену с учетом скидки
            let finalPricePerShift = MoneyUtils.calculateFinalInstancePrice(pricePerShift, discount);
            // Стоимость всего обязательства
            let finalTotalPrice = MoneyUtils.calculateFinalTotalPrice(finalPricePerShift, entity.instanceCount, shiftCount || 0);

            let requiredIndent = (entity.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;

            if (!entity.isCancelled) {
                operation_instanceCount += entity.instanceCount;
                operation_priceBeforeDiscount += totalPriceWithoutDiscount;
                operation_discountAmount += totalPriceWithoutDiscount - finalTotalPrice;
                operation_finalTotalPrice += finalTotalPrice;
            }

            let intervalsAvailable =
                ProductUtils.getIntervalsFromProductStackMapList2(
                    state.timeTables,
                    entity.kitId,
                    entity.productId,
                    entity.variantId,
                    undefined,
                    TimetableTypeCodeEnum.AVAILABLE
                ) || [];
            // let intervalsStock =
            //     ProductUtils.getIntervalsFromProductStackMapList2(
            //         state.timeTables,
            //         entity.kitId,
            //         entity.productId,
            //         entity.variantId,
            //         undefined,
            //         TimetableTypeCodeEnum.STOCK
            //     ) || [];

            let leftoverInstanceCount = Math.max(0, entity.instanceCountOriginal - entity.instanceCount);
            let leftoverAnonymousInstanceCount = 0;

            if (
                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
            ) {
                let leftoverInstances = getLeftoverInstances(entity.instanceIdsOriginal, entity.instanceIds);
                leftoverInstanceCount = Math.max(0, entity.instanceCountOriginal - entity.instanceCount, leftoverInstances.length);
                leftoverAnonymousInstanceCount = leftoverInstanceCount - leftoverInstances.length;
            }

            return {
                ...entity,
                shiftCount: shiftCount,
                calendarShiftCount,
                discount: discount,
                discountChanged,
                rentPeriodChanged,
                shiftCountChanged,
                rentPeriodStartDate: rentPeriodStartDate,
                rentPeriodEndDate: rentPeriodEndDate,
                finalTotalPrice: finalTotalPrice,
                pricePerShift: pricePerShift,
                finalPricePerShift: finalPricePerShift,
                rentPeriodStartDateChanged,
                rentPeriodEndDateChanged,

                leftoverInstanceCount,
                leftoverAnonymousInstanceCount,

                // stockInstanceCountX: ProductUtils.getMinimumValueInInterval(
                //     intervalsAvailable,
                //     new Date(rentPeriodStartDate).getTime() - requiredIndent,
                //     new Date(rentPeriodEndDate).getTime() + requiredIndent
                // ),
                availableInstanceCount: ProductUtils.getMinimumValueInInterval(
                    intervalsAvailable,
                    new Date(rentPeriodStartDate).getTime() - requiredIndent,
                    new Date(rentPeriodEndDate).getTime() + requiredIndent
                ), // - entity.instanceCount
            };
            // Если изменилось кол-во смен, то меняем в каждом обязательствое, где можем
        } else {
            return entity;
        }
    });

    // Проходим по Наборам, после того как прошлись по всем элементам
    state.elements.entities = state.elements.entities.map((entity) => {
        if (entity.kitId) {
            let discount = 0;
            let shiftCount = 0;
            let rentPeriodStartDate;
            let rentPeriodEndDate;
            let finalTotalPrice = 0;

            let startDateMsec = Number.MAX_SAFE_INTEGER;
            let endDateMsec = 0;

            let requiredIndent = (entity.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;

            state.elements.entities
                .filter((element) => element.parentId === entity.id)
                .forEach((element) => {
                    if (element.rentPeriodStartDate) startDateMsec = Math.min(startDateMsec, element.rentPeriodStartDate.getTime());
                    if (element.rentPeriodEndDate) endDateMsec = Math.max(endDateMsec, element.rentPeriodEndDate.getTime());
                });

            rentPeriodStartDate = new Date(startDateMsec);
            rentPeriodEndDate = new Date(endDateMsec);

            let intervalsAvailable =
                ProductUtils.getIntervalsFromProductStackMapList2(
                    state.timeTables,
                    entity.kitId,
                    entity.productId,
                    entity.variantId,
                    undefined,
                    TimetableTypeCodeEnum.AVAILABLE
                ) || [];
            // let intervalsStock =
            //     ProductUtils.getIntervalsFromProductStackMapList2(
            //         state.timeTables,
            //         entity.kitId,
            //         entity.productId,
            //         entity.variantId,
            //         undefined,
            //         TimetableTypeCodeEnum.STOCK
            //     ) || [];

            const rentPeriodStartDateChanged = state.elements.entities
                .filter((element) => element.parentId === entity.id)
                .some((item) => {
                    return item.rentPeriodStartDateChanged;
                });

            const rentPeriodEndDateChanged = state.elements.entities
                .filter((element) => element.parentId === entity.id)
                .some((item) => {
                    return item.rentPeriodEndDateChanged;
                });

            return {
                ...entity,
                shiftCount: shiftCount,
                discount: discount,
                rentPeriodStartDate: rentPeriodStartDate,
                rentPeriodEndDate: rentPeriodEndDate,
                finalTotalPrice: finalTotalPrice,

                rentPeriodStartDateChanged,
                rentPeriodEndDateChanged,

                // stockInstanceCountX: ProductUtils.getMinimumValueInInterval(
                //     intervalsAvailable,
                //     new Date(rentPeriodStartDate).getTime() - requiredIndent,
                //     new Date(rentPeriodEndDate).getTime() + requiredIndent
                // ),
                availableInstanceCount: ProductUtils.getMinimumValueInInterval(
                    intervalsAvailable,
                    new Date(rentPeriodStartDate).getTime() - requiredIndent,
                    new Date(rentPeriodEndDate).getTime() + requiredIndent
                ),
            };
        } else {
            return entity;
        }
    });

    // Тут все карты приводим к начальному состоянию, которое пришло с сервера
    state.timeTables.forEach((t) => {
        let availableIntervals =
            ProductUtils.getIntervalsFromProductStackMapList2(
                state.timeTables,
                t.kitId,
                t.productId,
                t.variantId,
                t.instanceId,
                !state.projectTemplate ? TimetableTypeCodeEnum.AVAILABLE : TimetableTypeCodeEnum.STOCK
            ) || [];
        let orderAvailableIntervals =
            ProductUtils.getIntervalsFromProductStackMapList2(
                state.timeTables,
                t.kitId,
                t.productId,
                t.variantId,
                t.instanceId,
                !state.projectTemplate ? TimetableTypeCodeEnum.ORDERAVAILABLE : TimetableTypeCodeEnum.STOCK
            ) || [];
        let stockIntervals =
            ProductUtils.getIntervalsFromProductStackMapList2(
                state.timeTables,
                t.kitId,
                t.productId,
                t.variantId,
                t.instanceId,
                TimetableTypeCodeEnum.STOCK
            ) || [];
        let orderIntervals =
            ProductUtils.getIntervalsFromProductStackMapList2(
                state.timeTables,
                t.kitId,
                t.productId,
                t.variantId,
                t.instanceId,
                TimetableTypeCodeEnum.ORDER
            ) || [];

        //console.log('AAA', availableIntervals, state.timeTables);
        t.available1 = availableIntervals;
        t.orderAvailable = orderAvailableIntervals;
        t.stock = stockIntervals;
        t.order = orderIntervals;

        // logStackMap({
        //     productId: t.productId,
        //     kitId: t.kitId,
        //     variantId: t.variantId,
        //     instanceId: t.instanceId,
        //     map: availableIntervals
        // }, true);
    });

    //console.log('updateState', state.typeCode, state.timeTables);

    // Проходим по всем элементам и обновляем карты доступности в нашем формате
    // state.elements.entities.forEach((element) => {
    //     // Уменьшает ли доступность
    //     let isElementReducesAvailability = getIsElementReducesAvailability(element);
    //     let isCorrectCancelOperation = getIsCorrectCancelOperation(state.typeCode, state.targetStateCode);
    //
    //     let timeTable = findTimeTable(state.timeTables, {
    //         productId: element.productId,
    //         kitId: element.kitId,
    //         variantId: element.variantId,
    //     });
    //
    //     if (timeTable) {
    //         let availableIntervals = isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1;
    //
    //         let elementIntervals: [number, number, number, string][] = [];
    //         //
    //         if (element.id < 0) {
    //             // Это новый (продукт, набор), просто вычитаем по нему
    //             elementIntervals.push([
    //                 availabilityFactor * element.instanceCount,
    //                 element.rentPeriodStartDate.getTime(),
    //                 element.rentPeriodEndDate.getTime(),
    //                 'Это новый (продукт, набор), просто вычитаем по нему',
    //             ]);
    //         } else {
    //             // Это существующий элемент
    //             if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
    //                 // Компенсируем
    //                 if (isElementReducesAvailability && element.variantId === element.variantIdOriginal) {
    //                     let endDate = element.rentPeriodEndDateOriginal.getTime();
    //                     if (element.stateCode === RentStateCodeEnum.RENT && state.elementsDelayedReturnDates[element.id]) {
    //                         endDate = state.elementsDelayedReturnDates[element.id].getTime();
    //                     }
    //                     elementIntervals.push([
    //                         availabilityFactor * -element.instanceCountOriginal,
    //                         element.rentPeriodStartDateOriginal.getTime(),
    //                         endDate,
    //                         'Компенсируем',
    //                     ]);
    //                 }
    //
    //                 // Т.к. теперь отдельная доступность по заявке нужно компенсировать обязательства в статусе заявки над которыми проводим операцию заявки (они в этой карте ORDER_AVAILABLE занимают доступность)
    //                 if (
    //                     element.stateCode === RentStateCodeEnum.ORDERED &&
    //                     isOrderOperation(state.targetStateCode || state.typeCode) &&
    //                     element.variantId === element.variantIdOriginal
    //                 ) {
    //                     elementIntervals.push([
    //                         availabilityFactor * -element.instanceCountOriginal,
    //                         element.rentPeriodStartDateOriginal.getTime(),
    //                         element.rentPeriodEndDateOriginal.getTime(),
    //                         'Т.к. теперь отдельная доступность по заявке нужно компенсировать обязательства в статусе заявки над которыми проводим операцию заявки (они в этой карте ORDER_AVAILABLE занимают доступность)',
    //                     ]);
    //                 }
    //
    //
    //                 //////////
    //                 // Соответственно из внешнего контекста оно полностью компенсируется, и не вычитается т.к. полностью отменено.
    //                 if (element.isCancelled !== true) {
    //                     // Теперь вычитаем текущее значение!!!
    //                     elementIntervals.push([
    //                         availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * element.instanceCount,
    //                         element.rentPeriodStartDate.getTime(),
    //                         element.rentPeriodEndDate.getTime(),
    //                         'Теперь вычитаем текущее значение!!!',
    //                     ]);
    //                     //
    //                     // Теперь вычитаем остаток!!!
    //                     if (
    //                         element.keepLeftover &&
    //                         isElementReducesAvailability /*|| element.stateCode === RentStateCodeEnum.ORDERED*/ &&
    //                         element.variantId === element.variantIdOriginal
    //                     ) {
    //                         let leftoverInstances = getLeftoverInstances(element.instanceIdsOriginal, element.instanceIds);
    //                         elementIntervals.push([
    //                             availabilityFactor * Math.max(0, element.instanceCountOriginal - element.instanceCount, leftoverInstances.length),
    //                             element.rentPeriodStartDateOriginal.getTime(),
    //                             element.rentPeriodEndDateOriginal.getTime(),
    //                             'Теперь вычитаем остаток!!!',
    //                         ]);
    //                     }
    //                 }
    //             }
    //         }
    //
    //         let elementIntervalsNew: [number, number, number][] = [];
    //         elementIntervalsNew = elementIntervals.map((item) => {
    //             return [item[0], item[1], item[2]];
    //         });
    //
    //         availableIntervals = ProductUtils.getNewIntervals(availableIntervals, elementIntervalsNew);
    //
    //         elementIntervals.forEach((item) => {
    //             logStackMap({
    //                 productId: element.productId,
    //                 kitId: element.kitId,
    //                 variantId: element.variantId,
    //                 intervals: [[item[0], item[1], item[2]]],
    //                 comment: item[3],
    //             });
    //         });
    //
    //         logStackMap({
    //             productId: element.productId,
    //             kitId: element.kitId,
    //             variantId: element.variantId,
    //             map: availableIntervals,
    //             comment: 'Результирующая карта',
    //         });
    //
    //         if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = availableIntervals;
    //         else timeTable.available1 = availableIntervals;
    //     }
    // });
    //
    // state.elements.entities.forEach((element) => {
    //     let isElementReducesAvailability = getIsElementReducesAvailability(element);
    //     //////////
    //     //Тут такая тема... Если изменили вариант, то нужно найти ту карту старого варианта
    //     if (element.variantId !== element.variantIdOriginal) {
    //         if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
    //             let timeTable = findTimeTable(state.timeTables, {
    //                 productId: element.productId,
    //                 kitId: element.kitId,
    //                 variantId: element.variantIdOriginal,
    //             });
    //             if (timeTable) {
    //                 let intervals: [number, number, number][] = [];
    //                 if (isElementReducesAvailability) {
    //                     intervals.push([
    //                         availabilityFactor * -element.instanceCountOriginal,
    //                         element.rentPeriodStartDateOriginal.getTime(),
    //                         element.rentPeriodEndDateOriginal.getTime(),
    //                     ]);
    //                     let leftoverInstances = getLeftoverInstances(element.instanceIdsOriginal, element.instanceIds);
    //                     intervals.push([
    //                         availabilityFactor * Math.max(0, element.instanceCountOriginal - element.instanceCount, leftoverInstances.length),
    //                         element.rentPeriodStartDateOriginal.getTime(),
    //                         element.rentPeriodEndDateOriginal.getTime(),
    //                     ]);
    //                     if (isOrderOperation(state.typeCode))
    //                         timeTable.orderAvailable = ProductUtils.getNewIntervals(timeTable.orderAvailable, intervals);
    //                     else timeTable.available1 = ProductUtils.getNewIntervals(timeTable.available1, intervals);
    //                 }
    //             }
    //         }
    //     }
    // });

    state.elements.entities.forEach((element) => {
        let timeTable = findTimeTable(state.timeTables, {
            productId: element.productId,
            kitId: element.kitId,
            variantId: element.variantId,
        });
        if (timeTable) {
            let intervals = xxx20(element, isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1, state);
            if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = intervals;
            else timeTable.available1 = intervals;
        }
    });

    state.elements.entities.forEach((element) => {
        if (element.variantId !== element.variantIdOriginal) {
            let timeTable = findTimeTable(state.timeTables, {
                productId: element.productId,
                kitId: element.kitId,
                variantId: element.variantIdOriginal,
            });
            if (timeTable) {
                let intervals = xxx21(element, isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1, state);
                if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = intervals;
                else timeTable.available1 = intervals;
            }
        }
    });

    state.elements.entities.forEach((element) => {
        // Уменьшает ли доступность
        let isElementReducesAvailability = getIsElementReducesAvailability(element);
        let isCorrectCancelOperation = getIsCorrectCancelOperation(state.typeCode, state.targetStateCode);

        // Тут работа с картами экземпляров
        if (
            element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
        ) {
            // Найти все элементы новые у которых есть экземпляры
            //element.filter(). instanceIdsOriginal?.forEach((instanceId) => {

            // Если Это новый элемент, просто вычитаем по нему
            if (element.id < 0) {
                element.instanceIds?.forEach((instanceId) => {
                    let timeTable = findTimeTable(state.timeTables, {
                        productId: element.productId,
                        kitId: element.kitId,
                        variantId: element.variantId,
                        instanceId,
                    });
                    if (timeTable) {
                        let intervals = ProductUtils.getNewIntervals(
                            isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
                            [[availabilityFactor * 1, element.rentPeriodStartDate.getTime(), element.rentPeriodEndDate.getTime()]]
                        );
                        if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = intervals;
                        else timeTable.available1 = intervals;
                    }
                });
            } else {
                // Существующий элемент
                // Компенсируем
                if (element.variantId === element.variantIdOriginal) {
                    element.instanceIdsOriginal?.forEach((instanceId) => {
                        if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
                            let timeTable = findTimeTable(state.timeTables, {
                                productId: element.productId,
                                kitId: element.kitId,
                                variantId: element.variantId,
                                instanceId,
                            });
                            if (timeTable) {
                                let endDate = element.rentPeriodEndDateOriginal.getTime();
                                if (element.stateCode === RentStateCodeEnum.RENT && state.elementsDelayedReturnDates[element.id]) {
                                    endDate = state.elementsDelayedReturnDates[element.id].getTime();
                                }

                                if (
                                    isElementReducesAvailability ||
                                    (element.stateCode === RentStateCodeEnum.ORDERED &&
                                        isOrderOperation(state.targetStateCode || state.typeCode))
                                ) {
                                    let intervals = ProductUtils.getNewIntervals(
                                        isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
                                        [[availabilityFactor * -1, element.rentPeriodStartDateOriginal.getTime(), endDate]]
                                    );
                                    if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = intervals;
                                    else timeTable.available1 = intervals;
                                }
                            }
                        }
                    });
                }

                //////////
                //Тут такая тема... Если изменили вариант, то нужно найти ту карту старого варианта
                // TODO Что делать тут при смене варианта? не понятно...
                //////////

                if (element.isCancelled !== true) {
                    // Вычетаем что сейчас
                    element.instanceIds?.forEach((instanceId) => {
                        let timeTable = findTimeTable(state.timeTables, {
                            productId: element.productId,
                            kitId: element.kitId,
                            variantId: element.variantId,
                            instanceId,
                        });
                        if (timeTable) {
                            let intervals = ProductUtils.getNewIntervals(
                                isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
                                [
                                    [
                                        availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * 1,
                                        element.rentPeriodStartDate.getTime(),
                                        element.rentPeriodEndDate.getTime(),
                                    ],
                                ]
                            );
                            if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = intervals;
                            else timeTable.available1 = intervals;
                        }
                    });

                    // Вычетаем остаток
                    let ost: number[] = [];
                    element.instanceIdsOriginal?.forEach((instanceId) => {
                        if (element.instanceIds && !element.instanceIds.includes(instanceId)) {
                            ost.push(instanceId);
                        }
                    });
                    if (isElementReducesAvailability && element.variantId === element.variantIdOriginal) {
                        ost.forEach((instanceId) => {
                            if (element.rentPeriodStartDateOriginal && element.rentPeriodEndDateOriginal) {
                                let timeTable = findTimeTable(state.timeTables, {
                                    productId: element.productId,
                                    kitId: element.kitId,
                                    variantId: element.variantId,
                                    instanceId,
                                });
                                if (timeTable) {
                                    let intervals = ProductUtils.getNewIntervals(
                                        isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
                                        [
                                            [
                                                availabilityFactor * 1,
                                                element.rentPeriodStartDateOriginal.getTime(),
                                                element.rentPeriodEndDateOriginal.getTime(),
                                            ],
                                        ]
                                    );
                                    if (isOrderOperation(state.typeCode)) timeTable.orderAvailable = intervals;
                                    else timeTable.available1 = intervals;
                                }
                            }
                        });
                    }
                }
            }
        }
    });

    // Карты обновлены, можем расчитать сколько доступно и т.д. ...
    // Для каждого элемента расчитываем availableInstanceCount, unavailableInstanceCount и stockInstanceCount
    state.elements.entities = state.elements.entities.map((element) => {
        let timeTable = findTimeTable(state.timeTables, {
            productId: element.productId,
            kitId: element.kitId,
            variantId: element.variantId,
        });
        if (timeTable) {
            let requiredIndent = (element.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;
            let startDate = element.rentPeriodStartDate.getTime();
            let endDate = element.rentPeriodEndDate.getTime();

            let yyy1: any = [
                [
                    availabilityFactor * element.instanceCount,
                    element.rentPeriodStartDate.getTime() - requiredIndent,
                    element.rentPeriodStartDate.getTime(),
                ],
                [
                    availabilityFactor * element.instanceCount,
                    element.rentPeriodEndDate.getTime(),
                    element.rentPeriodEndDate.getTime() + requiredIndent,
                ],
            ]; //ProductUtils.findElements(state.elements.entities, {productId: element.productId, variantId:element.variantId, kitId: element.kitId});
            let availableIntervals = !state.projectTemplate ? ProductUtils.getNewIntervals(
                isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
                yyy1
            ) : [];
            let stockIntervals = !state.projectTemplate ? [] : ProductUtils.getNewIntervals(timeTable.stock, yyy1);

            let availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                !state.projectTemplate ? availableIntervals : stockIntervals,
                startDate - requiredIndent,
                endDate + requiredIndent
            );

            // let stockInstanceCount = ProductUtils.getMinimumValueInInterval(
            //     stockIntervals,
            //     startDate - requiredIndent,
            //     endDate + requiredIndent
            // );

            let unavailableInstanceCount = availableInstanceCount < 0 ? Math.abs(availableInstanceCount) : 0;

            // Тут считаем количество недоступных для счетных
            if (
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED
            ) {
                let instanceUnavailableInstanceCount = 0;
                element.instanceIds?.forEach((instanceId) => {
                    let timeTable = findTimeTable(state.timeTables, {
                        productId: element.productId,
                        kitId: element.kitId,
                        variantId: element.variantId,
                        instanceId,
                    });
                    if (timeTable) {
                        let yyy1: any = [
                            [
                                availabilityFactor * 1,
                                element.rentPeriodStartDate.getTime() - requiredIndent,
                                element.rentPeriodStartDate.getTime(),
                            ],
                            [
                                availabilityFactor * 1,
                                element.rentPeriodEndDate.getTime(),
                                element.rentPeriodEndDate.getTime() + requiredIndent,
                            ],
                        ]; //ProductUtils.findInstancesInElements(state.elements.entities, instanceId);
                        let availableIntervals = !state.projectTemplate ? ProductUtils.getNewIntervals(
                            isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
                            yyy1
                        ) : [];
                        let stockIntervals = !state.projectTemplate ? [] : ProductUtils.getNewIntervals(timeTable.stock, yyy1);

                        let availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                            !state.projectTemplate ? availableIntervals : stockIntervals,
                            element.rentPeriodStartDate.getTime() - requiredIndent,
                            element.rentPeriodEndDate.getTime() + requiredIndent
                        );
                        if (availableInstanceCount < 0) {
                            instanceUnavailableInstanceCount += Math.abs(availableInstanceCount);
                        }
                    }
                });
                unavailableInstanceCount = Math.max(unavailableInstanceCount, instanceUnavailableInstanceCount);
            }

            return {
                ...element,
                availableInstanceCount,
                unavailableInstanceCount,
                //stockInstanceCountX: stockInstanceCount,
            };
        }
        return element;
    });

    // На всех продуктах и наборах в ИНВЕНТАРЕ устанавливаем: availableInstanceCount, stockInstanceCount и orderedInstanceCount
    // availableInstanceCount - Число доступных для аренды
    // stockInstanceCount - Общее число экземпляров на момент запроса или минимальное число на целевом интервале
    // orderedInstanceCount - В заявках
    // if (state.products.entities) {
    //     state.products.entities.forEach((record) => {
    //         if (state.products.params && state.products.params.startDate && state.products.params.endDate) {
    //             let timeTable = findTimeTable(state.timeTables, {
    //                 productId: record.productId,
    //                 kitId: record.kitId,
    //                 variantId: record.variantId,
    //             });
    //             if (timeTable) {
    //                 record.availableInstanceCount = ProductUtils.getMinimumValueInInterval(
    //                     isOrderOperation(state.typeCode) ? timeTable.orderAvailable : timeTable.available1,
    //                     state.products.params.startDate - (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000,
    //                     state.products.params.endDate + (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000
    //                 );
    //
    //                 record.stockInstanceCount = ProductUtils.getMinimumValueInInterval(
    //                     timeTable.stock,
    //                     state.products.params.startDate - (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000,
    //                     state.products.params.endDate + (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000
    //                 );
    //
    //                 record.orderedInstanceCount = ProductUtils.getMaximumValueInInterval(
    //                     timeTable.order,
    //                     state.products.params.startDate - (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000,
    //                     state.products.params.endDate + (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000
    //                 );
    //             }
    //         }
    //     });
    // }

    if (state.products.entities) {
        state.products.entities = state.products.entities.map((record) => {
            if (state.products.params && state.products.params.startDate && state.products.params.endDate) {
                let available = getNomenclatureAvailability(record, state);
                let stock = ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.STOCK);
                let order = ProductUtils.getIntervalsFromTimetableList(record.stackMapList, TimetableTypeCodeEnum.ORDER);

                const requiredTimeIndentBetweenElementsInMSec = (record.requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;
                const from = state.products.params.startDate - requiredTimeIndentBetweenElementsInMSec;
                const until = state.products.params.endDate + requiredTimeIndentBetweenElementsInMSec;

                return {
                    ...record,
                    availableInstanceCount: ProductUtils.getMinimumValueInInterval(available, from, until),
                    stockInstanceCount: ProductUtils.getMinimumValueInInterval(stock, from, until),
                    orderedInstanceCount: ProductUtils.getMaximumValueInInterval(order, from, until),
                };
            } else {
                return record;
            }
        });
    }

    // Для всех наборов считаем суммарные значения по всем дочерним элементам
    state.elements.entities = state.elements.entities.map((element) => {
        if (element.kitId) {
            let KitMemebers = state.elements.entities.filter((_element) => _element.parentId === element.id);
            let effectivePrice = 0;
            let rentPeriodStartDate = Number.MAX_SAFE_INTEGER;
            let rentPeriodEndDate = 0;
            let shiftCount = 0;
            let discount = 0;
            let finalTotalPrice = 0;

            KitMemebers.forEach((element) => {
                effectivePrice += element.effectivePrice;
                rentPeriodStartDate = Math.min(rentPeriodStartDate, element.rentPeriodStartDate.getTime());
                rentPeriodEndDate = Math.max(rentPeriodEndDate, element.rentPeriodEndDate.getTime());
                shiftCount += element.shiftCount || 0;
                discount += element.discount;
                finalTotalPrice += element.finalTotalPrice;
            });

            element.effectivePrice = effectivePrice;
            element.rentPeriodStartDate = new Date(rentPeriodStartDate);
            element.rentPeriodEndDate = new Date(rentPeriodEndDate);
            element.shiftCount = Math.round((shiftCount / KitMemebers.length) * 100) / 100;
            element.discount = Math.ceil(discount / KitMemebers.length);
            element.finalTotalPrice = finalTotalPrice;
        }
        return element;
    });

    // Ищем проблемы/предупреждения по каждому элементу и устанавливаем на элемент, если есть
    state.elements.entities = state.elements.entities.map((element) => {
        let problems: string[] = [];
        let warnings: string[] = [];
        if (!element.kitId && !element.isCancelled) {
            if (element.rentPeriodStartDate.getTime() >= element.rentPeriodEndDate.getTime()) {
                problems.push('startDate');
                problems.push('endDate');
            } else {
                if (
                    state.typeCode === OperationTypeCodeEnum.RENT ||
                    state.typeCode === OperationTypeCodeEnum.PROLONG ||
                    state.typeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
                    state.typeCode === OperationTypeCodeEnum.SUBRENTPROLONG ||
                    state.targetStateCode === RentStateCodeEnum.RENT ||
                    state.targetStateCode === RentStateCodeEnum.SUBRENT
                ) {
                    if (element.rentPeriodStartDate.getTime() > moment(state.operationStartTime).valueOf()) {
                        problems.push('startDate');
                    }
                } else if (
                    state.typeCode === OperationTypeCodeEnum.LOSTNORETURN ||
                    state.typeCode === OperationTypeCodeEnum.RETURNBROKEN ||
                    state.typeCode === OperationTypeCodeEnum.RETURN ||
                    state.typeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
                    state.targetStateCode === RentStateCodeEnum.RETURNED ||
                    state.targetStateCode === RentStateCodeEnum.RETURNEDBROKEN ||
                    state.targetStateCode === RentStateCodeEnum.LOSTDURINGRENT ||
                    state.targetStateCode === RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER
                ) {
                    if (element.rentPeriodEndDate.getTime() > moment(state.operationStartTime).valueOf()) {
                        problems.push('endDate');
                    }
                    if (element.rentPeriodStartDate.getTime() > moment(state.operationStartTime).valueOf()) {
                        problems.push('startDate');
                    }
                }
            }

            if (!state.projectTemplate) {
                if (state.typeCode !== OperationTypeCodeEnum.CANCEL && state.targetStateCode !== RentStateCodeEnum.CANCELED) {
                    if (
                        (element.shiftCount || 0) >
                        getShiftCountFromDates(
                            moment(element.rentPeriodStartDate),
                            moment(element.rentPeriodEndDate),
                            element.shiftLengthInMinutes,
                            state.shiftCountRoundingType
                        )
                    ) {
                        warnings.push('shiftCount');
                    }

                    if (
                        element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    ) {
                        if (!element.isCancelled && !canCreateOperationWithAnonymousInstances(state.targetStateCode || state.typeCode)) {
                            if (
                                (!element.instanceIds && element.instanceCount > 0) ||
                                (element.instanceIds &&
                                    element.instanceIds.length <
                                        element.instanceCount) /* && state.typeCode !== OperationTypeCodeEnum.CANCEL*/
                            ) {
                                problems.push('instanceCount');
                            }
                        }
                    }

                    if (element.unavailableInstanceCount && element.unavailableInstanceCount > 0) {
                        let err = canBeErrorWhenShortage(state.typeCode, state.targetStateCode);
                        if (err) {
                            if (err === OperationErrorCodeEnum.WARNING) warnings.push('instanceCount');
                            else if (err === OperationErrorCodeEnum.PROBLEM) problems.push('instanceCount');
                        }
                    }
                }
            } else {
                if (
                    state.typeCode !== OperationTypeCodeEnum.CANCEL &&
                    element.unavailableInstanceCount &&
                    element.unavailableInstanceCount > 0
                ) {
                    warnings.push('instanceCount');
                }
            }
        }
        element.problems = problems;
        element.warnings = warnings;
        return element;
    });

    // Если в наборе есть хоть одна проблема/предупреждение, то устанавливаем и на набор
    state.elements.entities
        .filter((element) => element.kitId)
        .forEach((element) => {
            let problems: string[] = [];
            let warnings: string[] = [];
            state.elements.entities
                .filter((el) => el.parentId === element.id)
                .forEach((el) => {
                    if (el.problems.length > 0) {
                        el.problems.forEach((problem) => {
                            if (!problems.includes(problem)) problems.push(problem);
                        });
                    }
                    if (el.warnings.length > 0) {
                        el.warnings.forEach((warning) => {
                            if (!warnings.includes(warning)) warnings.push(warning);
                        });
                    }
                });
            element.problems = problems;
            element.warnings = warnings;
        });

    let equipmentElements = state.equipment.filteredEntities?.map((item) => {
        //if
        item = {
            ...item,
            isCancelled: false,
            elementInstanceCount: 0,
            elementAnonymousInstancesCount: 0,
        };
        let element = state.elements.entities.find((el) => el.id === item.id);
        if (element) {
            item.isCancelled = element.isCancelled;
            item.elementInstanceCount = element.instanceCount;
            item.elementAnonymousInstancesCount = element.anonymousInstanceCount || 0;
        }

        if (item.subRows) {
            item.subRows = item.subRows.map((subRow: RentElementsGridItemCustom) => {
                let newSubrow = {
                    ...subRow,
                    isCancelled: false as boolean | undefined,
                    elementInstanceCount: 0,
                    elementAnonymousInstancesCount: 0,
                };
                let element = state.elements.entities.find((el) => el.id === subRow.id);
                if (element) {
                    newSubrow.isCancelled = element.isCancelled;
                    newSubrow.elementInstanceCount = element.instanceCount;
                    newSubrow.elementAnonymousInstancesCount = element.anonymousInstanceCount || 0;
                }
                return newSubrow;
            });
        }

        if (item.subRows) {
            let anonymousInstancesCount = 0;
            item.subRows.forEach((chEl: RentElementsGridItemCustom) => {
                anonymousInstancesCount += chEl.elementAnonymousInstancesCount || 0;
            });
            item.elementAnonymousInstancesCount = anonymousInstancesCount;
        }

        return item;
    });

    state.elements.entities = state.elements.entities.map((element) => {
        let subrentedInstanceCount = 0;
        if (element.instanceIds && element.instanceIds.length > 0) {
            element.instanceIds.forEach((id) => {
                const tt = findTimeTable(state.timeTables, { instanceId: id });
                if (tt) {
                    if (tt.subrentSupply) subrentedInstanceCount += 1;
                }
            });
        }

        return {
            ...element,
            subrentedInstanceCount: subrentedInstanceCount,
        };
    });

    let createdNewInstances = false;
    let variantChanged = false;
    let rentElementCanceledByCorrection = false;
    let leftoverCleared = false;

    // Отмена без корректировки для проектов доступна для: DRAFT, ORDERED, BOOKED, CANCELED
    // Отмена без корректировки для поставок доступна для: SUBRENT_DRAFT, SUBRENT_SHIPMENT_BOOKED, SUBRENT_CANCELED

    const isRentElementCanceledByCorrection = (element: OperationElement) => {
        if (element.stateCode && element.isCancelled) {
            let arr = [
                RentStateCodeEnum.DRAFT,
                RentStateCodeEnum.ORDERED,
                RentStateCodeEnum.BOOKED,
                RentStateCodeEnum.CANCELED,
                RentStateCodeEnum.SUBRENTDRAFT,
                RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
                RentStateCodeEnum.SUBRENTCANCELED,
            ];
            if (!arr.includes(element.stateCode)) return true;
        }
        return false;
    };

    state.elements.entities.forEach((element) => {
        if (!variantChanged && element.id > 0 && element.variantId !== element.variantIdOriginal) variantChanged = true;
        if (!createdNewInstances && element.id > 0 && element.instanceCount > element.instanceCountOriginal) createdNewInstances = true;
        if (!rentElementCanceledByCorrection && element.id && isRentElementCanceledByCorrection(element))
            rentElementCanceledByCorrection = true;
        if (
            !leftoverCleared &&
            element.id > 0 &&
            element.keepLeftover === false &&
            (element.leftoverInstanceCount > 0 || element.leftoverAnonymousInstanceCount > 0)
        )
            leftoverCleared = true;
    });

    if (!state.elementIsNowEditing) {
        let elementsToSort: OperationElement[] = [];
        let otherElements: OperationElement[] = [];
        state.elements.entities.forEach((el) => {
            if (isDefined(el.parentId)) otherElements.push(el);
            else elementsToSort.push(el);
        });
        elementsToSort = sortByFn(elementsToSort, state.elements.sortBy!);
        state.elements.entities = [...elementsToSort, ...otherElements];
    }

    const operation_finalTotalPriceWithTaxes =
        isDefined(state.taxRate) && isDefined(state.taxBaseType)
            ? MoneyUtils.calculatePriceWithTaxes(operation_finalTotalPrice, state.taxRate, state.taxBaseType)
            : undefined;

    return {
        ...state,
        elementsCount: state.elements.entities.length,
        instanceCount: operation_instanceCount,
        priceBeforeDiscount: operation_priceBeforeDiscount,
        discountAmount: operation_discountAmount,
        finalTotalPrice: operation_finalTotalPrice,
        finalTotalPriceWithTaxes: operation_finalTotalPriceWithTaxes,
        equipment: {
            ...state.equipment,
            //entities: equipmentElements || [],
            filteredEntities: equipmentElements || [],
        },
        createdNewInstances,
        variantChanged,
        rentElementCanceledByCorrection,
        leftoverCleared,
        // data: {
        //     ...state.data,
        //     stackMaps: nAvailableIntervals
        // }
    };
}

// Actions
type OperationFormAction =
    | StartNewOperationAction
    | SetDefaultRentPeriodAction
    | SetDefaultDiscountAction
    | SetDefaultShiftCountAction
    | ResetDefaultRentPeriodAction
    | ResetDefaultDiscountAction
    | ResetDefaultShiftCountAction
    | RemoveInstancesAction
    | RemoveTrackedInstancesAction
    | AddInstancesAction
    | AddInstanceFromScannerAction
    | AddKitAction
    | CreateOperationSuccessAction
    | CreateOperationErrorAction
    | ResetAction
    | FullResetAction
    | ResetFormAction
    | SetElementsListParamsAction
    | SetElementsListParamsAction1
    | CreateOperationAction
    | CreateOperationPendingAction
    | EditElementAction
    | SetProductsListParamsAction
    | ResetProductsListFiltersAction
    | ResetProductsListFiltersSuccessAction
    | LoadProductsAction
    | LoadProductsPendingAction
    | LoadProductsSuccessAction
    | LoadProductsErrorAction
    | LoadElementsForProjectAction
    | LoadElementsForProjectPendingAction
    | LoadElementsForProjectSuccessAction
    | LoadElementsForProjectErrorAction
    | SetProductsListParamsSuccessAction
    | SetRenterAndProjectAction
    | SetRenterAndProjectSuccessAction
    | SetOperationStartDateToCurrentAction
    | SetOperationEndDateToCurrentAction
    | ChangeVariantOfElementAction
    | MoveElementToKitAction
    | MoveElementFromKitAction
    | SetBAPreferencesAction
    | ChangeOperationTypeAction
    | SetKeepLeftoverAction
    | ResetAllKeepLeftoverAction
    | AddInstanceFromEquipmentAction
    | AddInstanceToExistedOperationElementAction
    | AddNewOperationElementFromEquipmentAction
    | AddElementItemByNomenclatureAction
    | AddInstanceToElementAction
    | AddElementItemFromElementsAction
    | RemoveInstanceFromElementAction
    | cancelOperationElementAction
    | AddKitsInfoAction
    //| AddNomenclaturesAction
    | UpdateElementAction
    | SetActualizeDateModeAction
    | SetElementIsNowEditingAction
    | SetAutoRecalculateShiftsCountModeAction
    | SetLoading
    | SetDiscountForElement
    | SetShiftCountForElement
    | SetPriceForElementAction
    | SetRentPeriodForElement
    | SetStartDateForAllElementsAction
    | SetEndDateForAllElementsAction
    | LoadConcurrentOperationsAction
    | LoadConcurrentOperationsSuccessAction
    | RemoveConcurrentOperationAction
    //| RemoveConcurrentOperationPendingAction
    | RemoveConcurrentOperationSuccessAction
    //| RemoveConcurrentOperationErrorAction
    | UpdateElementsContextAction
    | SetCommentAction
    | SetCommentVisibilityAction
    | ReloadAllTimetablesAction
    | UpdateAnyTimetablesAction
    | SetStateAction
    | SetEffectivePriceAndDiscountForSelectedIdsAction
    | SetFinalPricePerShiftForSelectedIdsAction
    | SetFinalTotalPriceForSelectedIdsAction
    | SetDatesForSelectedIdsAction
    | SetShiftCountForSelectedIdsAction
    | RecalculateShiftCountForSelectedIdsAction
    | SetTotalDiscountAction
    | SetFinalTotalPriceAction
    | SetStateAction
    | SetNomenclaturesAction;

/// startNewOperation
interface StartNewOperationAction extends Action {
    type: ACTION_TYPES.START_NEW_OPERATION;
    payload: {
        isSubrent: boolean|undefined;
        taxRate: number | undefined;
        taxBaseType: TaxBaseTypeCodeEnum | undefined;
        projectTemplate: boolean;
        renterId: number | undefined;
        renterShortName: string | undefined;
        projectId: number;
        projectShortName: string;
        projectType: ProjectTypeCodeEnum | undefined;
        typeCode: OperationTypeCodeEnum;
        elements: OperationElement[];
        mnemoKey: string;
        fromRequest: boolean;
        discount?: number;
        targetStateCode?: RentStateCodeEnum;
        productStackMapLists?: Array<ProductStackMapList | KitStackMapList>;
        instanceStackMapList?: Array<InstanceStackMapList>;
        timetables?: Array<TimeTable>;
        elementsDelayedReturnDates?: { [key: number]: Date };
        setActualDate?: boolean;
        recalculateShiftCount?: boolean;
        shiftCount?: number;
        startDate?: any;
        endDate?: any;
        operationStartDate?: any;
    };
}

export const startNewOperation = (
    isSubrent: boolean|undefined,
    taxRate: number | undefined,
    taxBaseType: TaxBaseTypeCodeEnum | undefined,
    projectTemplate: boolean,
    renterId: number | undefined,
    renterShortName: string | undefined,
    projectId: number,
    projectShortName: string,
    projectType: ProjectTypeCodeEnum | undefined,
    typeCode: OperationTypeCodeEnum,
    elements: OperationElement[],
    mnemoKey: string,
    fromRequest: boolean,
    discount?: number,
    targetStateCode?: RentStateCodeEnum,
    productStackMapLists?: Array<ProductStackMapList | KitStackMapList>,
    instanceStackMapList?: Array<InstanceStackMapList>,
    timetables?: TimeTable[],
    elementsDelayedReturnDates?: { [key: number]: Date },
    setActualDate?: boolean,
    recalculateShiftCount?: boolean,
    shiftCount?: number,
    startDate?: any,
    endDate?: any,
    operationStartDate?: any
): StartNewOperationAction => ({
    type: ACTION_TYPES.START_NEW_OPERATION,
    payload: {
        isSubrent,
        taxRate,
        taxBaseType,
        projectTemplate,
        renterId,
        renterShortName,
        projectId,
        projectShortName,
        projectType,
        typeCode,
        elements,
        mnemoKey,
        fromRequest,
        discount,
        targetStateCode,
        productStackMapLists,
        instanceStackMapList,
        timetables,
        elementsDelayedReturnDates,
        setActualDate,
        recalculateShiftCount,
        shiftCount,
        startDate,
        endDate,
        operationStartDate: operationStartDate ? moment(operationStartDate) : moment(getCurrentServerTime()),
    },
});

/// setDefaultRentPeriod
interface SetDefaultRentPeriodAction extends Action {
    type: ACTION_TYPES.SET_DEFAULT_RENT_PERIOD;
    payload: { startDate?: Moment; endDate?: Moment; recalculateShiftsCount?: boolean | undefined };
}

export const setDefaultRentPeriod = (
    startDate?: Moment,
    endDate?: Moment,
    recalculateShiftsCount?: boolean
): SetDefaultRentPeriodAction => ({
    type: ACTION_TYPES.SET_DEFAULT_RENT_PERIOD,
    payload: { startDate, endDate, recalculateShiftsCount },
});

/// setDefaultDiscount
interface SetDefaultDiscountAction extends Action {
    type: ACTION_TYPES.SET_DEFAULT_DISCOUNT;
    payload: number;
}

export const setDefaultDiscount = (discount: number): SetDefaultDiscountAction => ({
    type: ACTION_TYPES.SET_DEFAULT_DISCOUNT,
    payload: discount,
});

/// setDefaultShiftCount
interface SetDefaultShiftCountAction extends Action {
    type: ACTION_TYPES.SET_DEFAULT_SHIFT_COUNT;
    payload: number | undefined;
}

export const setDefaultShiftCount = (shiftCount: number | undefined): SetDefaultShiftCountAction => ({
    type: ACTION_TYPES.SET_DEFAULT_SHIFT_COUNT,
    payload: shiftCount,
});

/// resetDefaultRentPeriod
interface ResetDefaultRentPeriodAction extends Action {
    type: ACTION_TYPES.RESET_DEFAULT_RENT_PERIOD;
}

export const resetDefaultRentPeriod = (): ResetDefaultRentPeriodAction => ({
    type: ACTION_TYPES.RESET_DEFAULT_RENT_PERIOD,
});

/// resetDefaultDiscount
interface ResetDefaultDiscountAction extends Action {
    type: ACTION_TYPES.RESET_DEFAULT_DISCOUNT;
}

export const resetDefaultDiscount = (): ResetDefaultDiscountAction => ({
    type: ACTION_TYPES.RESET_DEFAULT_DISCOUNT,
});

/// resetDefaultDiscount1
// interface ResetDefaultDiscountAction1 extends Action {
//     type: ACTION_TYPES.RESET_DEFAULT_DISCOUNT1;
// }
//
// export const resetDefaultDiscount1 = (): ResetDefaultDiscountAction1 => ({
//     type: ACTION_TYPES.RESET_DEFAULT_DISCOUNT1
// });

/// resetDefaultShiftCount
interface ResetDefaultShiftCountAction extends Action {
    type: ACTION_TYPES.RESET_DEFAULT_SHIFT_COUNT;
}

export const resetDefaultShiftCount = (): ResetDefaultShiftCountAction => ({
    type: ACTION_TYPES.RESET_DEFAULT_SHIFT_COUNT,
});

/// resetDefaultShiftCount1
// interface ResetDefaultShiftCountAction1 extends Action {
//     type: ACTION_TYPES.RESET_DEFAULT_SHIFT_COUNT1;
// }
//
// export const resetDefaultShiftCount1 = (): ResetDefaultShiftCountAction1 => ({
//     type: ACTION_TYPES.RESET_DEFAULT_SHIFT_COUNT1,
// });

/// setComment
// interface SetCommentAction extends Action {
//     type: ACTION_TYPES.SET_COMMENT;
//     payload: string;
// }

// export const setComment = (comment: string): SetCommentAction => ({
//     type: ACTION_TYPES.SET_COMMENT,
//     payload: comment,
// });

/// removeInstances
interface RemoveInstancesAction extends Action {
    type: ACTION_TYPES.REMOVE_INSTANCES;
    payload: { id: number; amount: number };
}

export const removeInstances = (id: number, amount: number): RemoveInstancesAction => ({
    type: ACTION_TYPES.REMOVE_INSTANCES,
    payload: { id, amount },
});

interface RemoveTrackedInstancesAction extends Action {
    type: ACTION_TYPES.REMOVE_TRACKED_INSTANCES;
    payload: { id: number; ids: number[]; anonymousCount: number };
}

export const removeTrackedInstances = (id: number, ids: number[], anonymousCount: number): RemoveTrackedInstancesAction => ({
    type: ACTION_TYPES.REMOVE_TRACKED_INSTANCES,
    payload: { id, ids, anonymousCount },
});

/// addElement
interface AddInstancesAction extends Action {
    type: ACTION_TYPES.ADD_ELEMENT;
    payload: { element: OperationElement[] | OperationElement; records?: NomenclatureRecord[]; subrentForShortage?: boolean; xx?: boolean };
}

export const addElement = (
    element: OperationElement[] | OperationElement,
    records?: NomenclatureRecord[],
    subrentForShortage?: boolean,
    xx?: boolean
): AddInstancesAction => ({
    type: ACTION_TYPES.ADD_ELEMENT,
    payload: { element, records, subrentForShortage, xx },
});

interface UpdateElementAction extends Action {
    type: ACTION_TYPES.UPDATE_ELEMENT;
    payload: { element: OperationElement };
}

export const updateElement = (element: OperationElement): UpdateElementAction => ({
    type: ACTION_TYPES.UPDATE_ELEMENT,
    payload: { element },
});

/// addElement
interface AddInstanceFromScannerAction extends Action {
    type: ACTION_TYPES.ADD_INSTANCE_FROM_SCANNER;
    payload: {
        instanceId: number;
        entity: InstanceInfoRead;
        nomenclature: NomenclatureRecord;
    };
}

export const addInstanceFromScanner = (
    instanceId: number,
    entity: InstanceInfoRead,
    nomenclature: NomenclatureRecord
): AddInstanceFromScannerAction => ({
    type: ACTION_TYPES.ADD_INSTANCE_FROM_SCANNER,
    payload: { instanceId, entity, nomenclature },
});

/// addKit
interface AddKitAction extends Action {
    type: ACTION_TYPES.ADD_KIT;
    payload: { element: OperationElement; members: OperationElement[] };
}

export const addKit = (element: OperationElement, members: OperationElement[]): AddKitAction => ({
    type: ACTION_TYPES.ADD_KIT,
    payload: { element, members },
});

/// editElement
interface EditElementAction extends Action {
    type: ACTION_TYPES.EDIT_ELEMENT;
    payload: {
        elementId: number;
        startDate: Date;
        endDate: Date;
        discount: number;
        shiftCount: number;
        instanceCount: number;
        discountChanged: boolean;
        shiftCountChanged: boolean;
        rentPeriodChanged: boolean;
        effectivePrice?: number;
        instanceIds?: number[];
        anonumousInstanceCount?: number;
        pricingSchemeId?: PricingSchemeExternalRepresentationObj;
    };
}

export const editElement = (
    elementId: number,
    startDate: Date,
    endDate: Date,
    discount: number,
    shiftCount: number,
    instanceCount: number,
    discountChanged: boolean,
    shiftCountChanged: boolean,
    rentPeriodChanged: boolean,
    effectivePrice?: number,
    instanceIds?: number[],
    anonumousInstanceCount?: number,
    pricingSchemeId?: PricingSchemeExternalRepresentationObj
): EditElementAction => ({
    type: ACTION_TYPES.EDIT_ELEMENT,
    payload: {
        elementId,
        startDate,
        endDate,
        discount,
        shiftCount,
        instanceCount,
        discountChanged,
        shiftCountChanged,
        rentPeriodChanged,
        effectivePrice,
        instanceIds,
        anonumousInstanceCount,
        pricingSchemeId,
    },
});

/// setElementsListParams
interface SetElementsListParamsAction extends Action {
    type: ACTION_TYPES.SET_ELEMENTS_LIST_PARAMS;
    payload: Partial<ListParams>;
}

export const setElementsListParams = (params: Partial<ListParams>): SetElementsListParamsAction => ({
    type: ACTION_TYPES.SET_ELEMENTS_LIST_PARAMS,
    payload: params,
});

interface SetElementsListParamsAction1 extends Action {
    type: ACTION_TYPES.SET_ELEMENTS_LIST_PARAMS1;
    payload: EquipmentListParams;
}

export const setElementsListParams1 = (params: EquipmentListParams): SetElementsListParamsAction1 => ({
    type: ACTION_TYPES.SET_ELEMENTS_LIST_PARAMS1,
    payload: params,
});

/// createOperation
interface CreateOperationAction extends Action {
    type: ACTION_TYPES.CREATE_OPERATION;
    payload: AxiosPromise<OperationInfoRead>;
}

interface CreateOperationPendingAction extends Action {
    type: ACTION_TYPES.CREATE_OPERATION_PENDING;
    payload: AxiosPromise<OperationInfoRead>;
}

interface CreateOperationSuccessAction extends Action {
    type: ACTION_TYPES.CREATE_OPERATION_SUCCESS;
    payload: AxiosResponse<OperationInfoRead>;
}

interface CreateOperationErrorAction extends Action {
    type: ACTION_TYPES.CREATE_OPERATION_ERROR;
    payload: AxiosError;
}

export const createOperation = (intl) => {
    return (dispatch, getState: () => IRootState) => {
        let rootState: IRootState = getState();
        let state: OperationFormState = rootState.operationForm;

        if (!state.typeCode || !state.projectId) return;

        let rentElementsToCreate: RentElementInfoWrite[] = state.elements.entities
            .filter((element) => /*element.id <= 0 &&*/ !element.kitId && !element.parentId)
            .filter((element: OperationElement) => element.isCancelled !== true)
            .map((element: OperationElement) => {
                if (element.id <= 0) return ProductUtils.createRentElementInfoCreate(element, !state.projectTemplate);
                else return ProductUtils.createRentElementInfoUpdate(element, !state.projectTemplate);
            });

        let rentElementsKitsToCreate: RentElementsKitObjWrite[] = state.elements.entities
            .filter((element: OperationElement) => /*element.id <= 0 &&*/ isDefined(element.kitId))
            .filter((element: OperationElement) => element.isCancelled !== true)
            .map((element: OperationElement) => {
                if (element.id <= 0) {
                    let rentElementsToCreate = state.elements.entities
                        .filter((element: OperationElement) => element.isCancelled !== true)
                        .filter((_element: OperationElement) => _element.parentId === element.id)
                        .map((element: OperationElement) =>
                            element.id < 0
                                ? ProductUtils.createRentElementInfoCreate(element, !state.projectTemplate)
                                : ProductUtils.createRentElementInfoUpdate(element, !state.projectTemplate)
                        );
                    return {
                        kitId: element.kitId || 0,
                        rentElements: rentElementsToCreate,
                    };
                } else {
                    let rentElementsToUpdate = state.elements.entities
                        .filter((element: OperationElement) => element.isCancelled !== true)
                        .filter((_element: OperationElement) => _element.parentId === element.id)
                        .map((element: OperationElement) =>
                            element.id < 0
                                ? ProductUtils.createRentElementInfoCreate(element, !state.projectTemplate)
                                : ProductUtils.createRentElementInfoUpdate(element, !state.projectTemplate)
                        );
                    return {
                        id: element.id,
                        kitId: element.kitId || 0,
                        rentElements: rentElementsToUpdate,
                        businessVersion: element.businessVersion,
                    };
                }
            });

        let cancelledElements = state.elements.entities
            .filter((el) => /*el.canBeCancelled && */ el.isCancelled)
            .map((el) => {
                return { id: el.id, businessVersion: el.businessVersion, rentElementsKitId: (el.parentId || null) as any };
            });

        let operationInfoCreate: OperationInfoCreate = {
            comment:
                state.currentOperation?.comment && state.currentOperation.comment.length > 0 ? state.currentOperation.comment : undefined,
            rentElements: rentElementsToCreate.length ? rentElementsToCreate : undefined,
            rentElementsKits: rentElementsKitsToCreate.length ? rentElementsKitsToCreate : undefined,
            canceledRentElements: cancelledElements.length ? { entities: cancelledElements } : undefined,
            projectId: state.isSubrent ? undefined : state.projectTemplate ? undefined : state.projectId,
            subrentId: !state.isSubrent ? undefined : state.projectTemplate ? undefined : state.projectId,
            templateId: state.projectTemplate ? state.projectId : undefined,
            typeCode: state.typeCode,
            commonRentTerms: {
                discount: state.discount,
                shiftCount: state.shiftCount,
                rentPeriodStartDate: state.rentPeriodStartDate || new Date(),
                rentPeriodEndDate: state.rentPeriodEndDate || new Date(),
            },
            targetStateCode: state.targetStateCode,
            concurrentOperationUUID: state.currentOperationUUID,
        };

        return dispatch({
            type: ACTION_TYPES.CREATE_OPERATION,
            payload:
                state.typeCode === OperationTypeCodeEnum.CORRECT
                    ? serverApi.createCorrectionOperation(rootState.system.businessAccountId, operationInfoCreate)
                    : serverApi.createOperation(rootState.system.businessAccountId, operationInfoCreate),
        } as CreateOperationAction).then((result: Error | { action: CreateOperationSuccessAction; value: any }) => {
            if (result instanceof Error) {
                // Ошибка создания операции
                window.scrollTo(0, 0);
            } else {
                setTimeout(() => {
                    const { businessAccountId } = rootState.system;
                    // Операция создана, перенаправляем на карточку операции
                    let path: string | undefined;
                    if (state.isSubrent/*isSubrentOperation(state.typeCode, state.targetStateCode)*/) {
                        path = `/${businessAccountId}/subrent/shippings/${state.projectId}?tab=${SubrentShippingPageTabsEnum.ELEMENTS}`;
                    } else {
                        if (state.projectTemplate) {
                            path = `/${businessAccountId}/projects/${ProjectsPageTabsEnum.TEMPLATES}/${state.projectId}?tab=${TemplatePageTabsEnum.ELEMENTS}`;
                        } else {
                            if (state.projectType === ProjectTypeCodeEnum.OFFER) {
                                path = `/${businessAccountId}/projects/${ProjectsPageTabsEnum.OFFERS}/${state.projectId}?tab=${OfferPageTabsEnum.ELEMENTS}`;
                            } else {
                                path = `/${businessAccountId}/projects/${ProjectsPageTabsEnum.PROJECTS}/${state.projectId}?tab=${ProjectPageTabsEnum.ELEMENTS}`;
                            }
                        }
                    }

                    if (path) dispatch(replace(path));
                    dispatch(resetOperation());
                    dispatch(resetBusinessAccountIndicators());
                    showNotification(
                        'success',
                        <span className={'rr-operation-page__operation-created-message'}>
                            <a
                                onClick={() => {
                                    dispatch(
                                        push(
                                            `/${rootState.system.businessAccountId}${'/history/operations/'}${
                                                result.value.data.id
                                            }?tab=elements`
                                        )
                                    );
                                }}
                            >
                                Операция
                            </a>{' '}
                            создана
                        </span>
                    );
                }, 1500);
            }
            return result;
        });
    };
};

/// reset
interface ResetAction extends Action {
    type: ACTION_TYPES.RESET;
}

export const resetOperation = (): ResetAction => ({
    type: ACTION_TYPES.RESET,
});

interface FullResetAction extends Action {
    type: ACTION_TYPES.FULL_RESET;
}

export const fullResetOperation = (): FullResetAction => ({
    type: ACTION_TYPES.FULL_RESET,
});

/// resetOperationForm
interface ResetFormAction extends Action {
    type: ACTION_TYPES.RESET_FORM;
}

export const resetOperationForm = () => {
    return (dispatch, getState: () => IRootState) => {
        if (!getState().operationForm.mnemoKey) {
            return dispatch({
                type: ACTION_TYPES.RESET_FORM,
            });
        } else {
        }
    };
};

/// setProductsListParams
interface SetProductsListParamsSuccessAction extends Action {
    type: ACTION_TYPES.SET_PRODUCTS_LIST_PARAMS_SUCCESS;
    payload: ProductsListParams;
}

interface SetProductsListParamsAction extends Action {
    type: ACTION_TYPES.SET_PRODUCTS_LIST_PARAMS;
    payload: Promise<ProductsListParams>;
}

export const setProductsListParams = (params: ProductsListParams) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.SET_PRODUCTS_LIST_PARAMS,
            payload: Promise.resolve(params),
        });
    };
};

/// resetProductsListParams
interface ResetProductsListFiltersSuccessAction extends Action {
    type: ACTION_TYPES.RESET_PRODUCTS_LIST_FILTERS_SUCCESS;
    payload: ProductsListParams;
}

interface ResetProductsListFiltersAction extends Action {
    type: ACTION_TYPES.RESET_PRODUCTS_LIST_FILTERS;
    payload: Promise<ProductsListParams>;
}

export const resetProductsListFilters = () => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.RESET_PRODUCTS_LIST_FILTERS,
            payload: Promise.resolve({}),
        });
    };
};

/// loadProducts
interface LoadProductsAction extends Action {
    type: ACTION_TYPES.LOAD_PRODUCTS;
    payload: AxiosPromise<NomenclatureRecordList>;
}

interface LoadProductsPendingAction extends Action {
    type: ACTION_TYPES.LOAD_PRODUCTS_PENDING;
    payload: AxiosPromise<NomenclatureRecordList>;
}

interface LoadProductsSuccessAction extends Action {
    type: ACTION_TYPES.LOAD_PRODUCTS_SUCCESS;
    payload: AxiosResponse<NomenclatureRecordList>;
}

interface LoadProductsErrorAction extends Action {
    type: ACTION_TYPES.LOAD_PRODUCTS_ERROR;
    payload: AxiosError;
}

export const loadProducts = (showError: boolean = true) => {
    return (dispatch, getState: () => IRootState) => {
        let rootState: IRootState = getState();
        let state = rootState.operationForm;
        let params = state.products.params;

        let filters: string[] = [];
        if (params.problem) filters.push(getStringServerProblem(params.problem));
        if (params.categoryIds !== undefined && params.categoryIds !== '') filters.push('categoryIds;IN;' + params.categoryIds);

        let from = moment(params.startDate || Date.now())
            .startOf('month')
            .subtract(1, 'month')
            .valueOf();
        let until = moment(params.endDate || Date.now())
            .endOf('month')
            .add(1, 'month')
            .valueOf();

        if (params.finalTotalPrice && initialProductsParamsState.finalTotalPrice) {
            if (params.finalTotalPrice[0] !== undefined) filters.push('pricePerShift;GE;' + params.finalTotalPrice[0] * 100);
            if (params.finalTotalPrice[1] !== undefined) filters.push('pricePerShift;LE;' + params.finalTotalPrice[1] * 100);
        }

        if (params.nomenclatureEntityTypeCode) {
            if (params.nomenclatureEntityTypeCode === 'kit')
                filters.push('nomenclatureEntityTypeCode;IN;' + NomenclatureEntityTypeCodeEnum.KIT);
            else if (params.nomenclatureEntityTypeCode === 'product+variant')
                filters.push(
                    'nomenclatureEntityTypeCode;IN;' + NomenclatureEntityTypeCodeEnum.PRODUCT + ';' + NomenclatureEntityTypeCodeEnum.VARIANT
                );
        }

        filters.push('visible;EQ;true');

        let possibleDelayedRentElementIds = rootState.operationForm.elements.entities
            .filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT)
            .map((item) => item.id);

        return dispatch({
            type: ACTION_TYPES.LOAD_PRODUCTS,
            payload: serverApi.listNomenclatureOnInterval(
                rootState.system.businessAccountId,
                from,
                until,
                {
                    possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                    listFilters: filters,
                },
                params.limit,
                params.page === undefined || params.limit === undefined ? 0 : (params.page - 1) * params.limit,
                params.sortBy,
                params.sortOrder,
                params.search && params.search.length > 0 ? params.search : undefined,
                rootState.operationForm.projectTemplate
                    ? [TimetableTypeCodeEnum.STOCK]
                    : [
                          TimetableTypeCodeEnum.ORDERAVAILABLE,
                          TimetableTypeCodeEnum.AVAILABLE,
                          TimetableTypeCodeEnum.STOCK,
                          TimetableTypeCodeEnum.ORDER,
                      ]
            ),
        } as LoadProductsAction).then((result) => {
            if (result instanceof Error) {
                if (showError) showNotification('error', 'Не удалось загрузить список продуктов');
            } else {
                gridDataChangedSignal();
            }
        });
    };
};

/// loadElementsForProject
interface LoadElementsForProjectAction extends Action {
    type: ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT;
    payload: AxiosPromise<RentElementRecordList>;
}

interface LoadElementsForProjectPendingAction extends Action {
    type: ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT_PENDING;
    payload: AxiosPromise<RentElementRecordList>;
}

interface LoadElementsForProjectSuccessAction extends Action {
    type: ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT_SUCCESS;
    payload: AxiosResponse<RentElementRecordList>;
}

interface LoadElementsForProjectErrorAction extends Action {
    type: ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT_ERROR;
    payload: AxiosError;
}

// Загрузить список обязательств
export const loadElementsForProject = (projectId: number, parentType: 'project' | 'projectTemplate' | 'shipping') => {
    return (dispatch, getState) => {
        let rootState: IRootState = getState();
        let state = (getState() as IRootState).operationForm;
        let filters: string[] = [];
        filters.push(`availableTransitionCodes;IN;${state.typeCode}`);
        if (state.targetStateCode) filters.push(`availableTargetCorrectionStates;IN;${state.targetStateCode}`);

        let methodName: 'listProjectElements' | 'listTemplateElements' | 'listSubrentElements' = 'listProjectElements';
        if (parentType === 'projectTemplate') methodName = 'listTemplateElements';
        else if (parentType === 'shipping') methodName = 'listSubrentElements';

        return dispatch({
            type: ACTION_TYPES.LOAD_ELEMENTS_FOR_PROJECT,
            payload: serverApi[methodName](
                rootState.system.businessAccountId,
                projectId,
                1000,
                0,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                { query: { filters: filters } }
            ),
        } as LoadElementsForProjectAction).then((result) => {
            if (result instanceof Error) {
                throw result;
            }
        });
    };
};

/// setRenterAndProject
interface SetRenterAndProjectSuccessAction extends Action {
    type: ACTION_TYPES.SET_RENTER_AND_PROJECT_SUCCESS;
    payload: {
        renterId?: number;
        renterShortName?: string;
        projectId?: number;
        projectShortName?: string;
        defaultDiscount?: number;
        isSubrentOperation?: boolean;
    };
}

interface SetRenterAndProjectAction extends Action {
    type: ACTION_TYPES.SET_RENTER_AND_PROJECT;
    payload: Promise<{
        renterId?: number;
        renterShortName?: string;
        projectId?: number;
        projectShortName?: string;
        defaultDiscount?: number;
        isSubrentOperation?: boolean;
    }>;
}

export const setRenterAndProject = (
    renterId?: number,
    renterShortName?: string,
    projectId?: number,
    projectShortName?: string,
    defaultDiscount?: number,
    isSubrentOperation?: boolean
): SetRenterAndProjectAction => ({
    type: ACTION_TYPES.SET_RENTER_AND_PROJECT,
    payload: Promise.resolve({ renterId, renterShortName, projectId, projectShortName, defaultDiscount, isSubrentOperation }),
});

interface SetOperationStartDateToCurrentAction extends Action {
    type: ACTION_TYPES.SET_OPERATION_START_DATE_TO_CURRENT;
    payload: { xxx: boolean; yyy: boolean; recalculateShiftCount: boolean };
}

export const setOperationStartDateToCurrent = (
    xxx: boolean = false,
    yyy: boolean = false,
    recalculateShiftCount: boolean = true
): SetOperationStartDateToCurrentAction => ({
    type: ACTION_TYPES.SET_OPERATION_START_DATE_TO_CURRENT,
    payload: { xxx, yyy, recalculateShiftCount },
});

interface SetOperationEndDateToCurrentAction extends Action {
    type: ACTION_TYPES.SET_OPERATION_END_DATE_TO_CURRENT;
    payload: { xxx: boolean; yyy: boolean; recalculateShiftCount: boolean };
}

export const setOperationEndDateToCurrent = (
    xxx: boolean = false,
    yyy: boolean = false,
    recalculateShiftCount: boolean = true
): SetOperationEndDateToCurrentAction => ({
    type: ACTION_TYPES.SET_OPERATION_END_DATE_TO_CURRENT,
    payload: { xxx, yyy, recalculateShiftCount },
});

interface ChangeVariantOfElementAction extends Action {
    type: ACTION_TYPES.CHANGE_VARIANT_OF_ELEMENT;
    payload: { elementId: number; newVariantId: number; newVariantName: string; effectivePrice: number; externalCode: string | undefined };
}

export const changeVariantOfElement = (
    elementId: number,
    newVariantId: number,
    newVariantName: string,
    effectivePrice: number,
    externalCode: string | undefined
): ThunkAction<any, RootState, any, ChangeVariantOfElementAction> => {
    // TODO По ходу нужно захватить еще номенклатуру с измененным вариантом
    return async (dispatch, getState: () => RootState) => {
        const state = getState().operationForm;
        const elements = state.elements.entities;
        const nomenclatures: NomenclatureOnInterval[] = [];
        const element = elements.find((el) => el.id === elementId);
        if (element && element.variantId) {
            const from = getElementStartDate(element);
            const until = getElementEndDate(element);
            nomenclatures.push({ id: element.variantId, type: NomenclatureEntityTypeCodeEnum.VARIANT, from, until });
            nomenclatures.push({ id: newVariantId, type: NomenclatureEntityTypeCodeEnum.VARIANT, from, until });
        }

        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));

        return dispatch({
            type: ACTION_TYPES.CHANGE_VARIANT_OF_ELEMENT,
            payload: { elementId, newVariantId, newVariantName, effectivePrice, externalCode },
        });
    };
};

interface MoveElementToKitAction extends Action {
    type: ACTION_TYPES.MOVE_ELEMENT_TO_KIT;
    payload: { kitId: number; elementIds: number[] };
}

export const moveElementToKit = (kitId: number, elementIds: number[]): MoveElementToKitAction => ({
    type: ACTION_TYPES.MOVE_ELEMENT_TO_KIT,
    payload: { kitId, elementIds },
});

interface MoveElementFromKitAction extends Action {
    type: ACTION_TYPES.MOVE_ELEMENT_FROM_KIT;
    payload: { kitId: number; elementId: number };
}

export const moveElementFromKit = (kitId: number, elementId: number): MoveElementFromKitAction => ({
    type: ACTION_TYPES.MOVE_ELEMENT_FROM_KIT,
    payload: { kitId, elementId },
});

interface AddInstanceFromEquipmentAction extends Action {
    type: ACTION_TYPES.ADD_ELEMENT_FROM_EQUIPMENT;
    payload: { equipmentElement: RentElementsGridItemCustom };
}

export const addInstanceFromEquipment = (equipmentElement: any) => {
    return (dispatch, getState: () => IRootState) => {
        return dispatch({
            type: ACTION_TYPES.ADD_ELEMENT_FROM_EQUIPMENT,
            payload: equipmentElement,
        } as AddInstanceFromEquipmentAction);
    };
};

interface SetBAPreferencesAction extends Action {
    type: ACTION_TYPES.SET_BA_PREFERENCES;
    payload: {
        shiftLengthInMinutes?: number;
        shiftCountRoundingType?: ShiftCountRoundingTypeCodeEnum;
        requiredTimeIndentBetweenElementsInMinutes?: number;
    };
}

export const setBAPreferences = (
    shiftLengthInMinutes: number | undefined,
    shiftCountRoundingType: ShiftCountRoundingTypeCodeEnum | undefined,
    requiredTimeIndentBetweenElementsInMinutes: number | undefined
): SetBAPreferencesAction => ({
    type: ACTION_TYPES.SET_BA_PREFERENCES,
    payload: { shiftLengthInMinutes, shiftCountRoundingType, requiredTimeIndentBetweenElementsInMinutes },
});

interface ChangeOperationTypeAction extends Action {
    type: ACTION_TYPES.CHANGE_OPERATION_TYPE;
    payload: {
        operationType: OperationTypeCodeEnum | undefined;
        correctionCode: RentStateCodeEnum | undefined;
    };
}

export const changeOperationType = (
    operationType: OperationTypeCodeEnum,
    correctionCode?: RentStateCodeEnum | undefined
): ChangeOperationTypeAction => ({
    type: ACTION_TYPES.CHANGE_OPERATION_TYPE,
    payload: { operationType, correctionCode },
});

interface SetKeepLeftoverAction extends Action {
    type: ACTION_TYPES.SET_KEEPLEFTOVER;
    payload: {
        elementId: number;
        keepLeftover: boolean;
    };
}

export const setKeepLeftover = (elementId: number, keepLeftover: boolean): SetKeepLeftoverAction => ({
    type: ACTION_TYPES.SET_KEEPLEFTOVER,
    payload: { elementId, keepLeftover },
});

interface ResetAllKeepLeftoverAction extends Action {
    type: ACTION_TYPES.RESET_ALL_KEEPLEFTOVER;
}

export const resetAllKeepLeftover = (): ResetAllKeepLeftoverAction => ({
    type: ACTION_TYPES.RESET_ALL_KEEPLEFTOVER,
});

interface AddInstanceToExistedOperationElementAction extends Action {
    type: ACTION_TYPES.ADD_INSTANCE_TO_EXISTED_OPERATION_ELEMENT;
    payload: {
        element: OperationElement;
        instanceId?: number;
        insteadOfAnonymous?: boolean;
    };
}

export const addInstanceToExistedOperationElement = (
    element: OperationElement,
    instanceId?: number,
    insteadOfAnonymous?: boolean
): AddInstanceToExistedOperationElementAction => ({
    type: ACTION_TYPES.ADD_INSTANCE_TO_EXISTED_OPERATION_ELEMENT,
    payload: { element, instanceId, insteadOfAnonymous },
});

interface AddNewOperationElementFromEquipmentAction extends Action {
    type: ACTION_TYPES.ADD_NEW_OPERATION_ELEMENT_FROM_EQUIPMENT;
    payload: {
        elements: OperationElement[];
        records?: (NomenclatureRecord | InstanceRecord)[] | undefined;
    };
}

export const addNewOperationElementFromEquipment = (
    elements: OperationElement[],
    records?: (NomenclatureRecord | InstanceRecord)[] | undefined
): AddNewOperationElementFromEquipmentAction => ({
    type: ACTION_TYPES.ADD_NEW_OPERATION_ELEMENT_FROM_EQUIPMENT,
    payload: { elements, records },
});

interface AddElementItemByNomenclatureAction extends Action {
    type: ACTION_TYPES.ADD_ELEMENT_ITEM_BY_NOMENCLATURE;
    payload: {
        nomenclature: NomenclatureRecord;
        records?: NomenclatureRecord[];
        kitMembers?: KitMemberObjRead[];
    };
}

export const addElementItemByNomenclature = (
    nomenclature: NomenclatureRecord,
    records: NomenclatureRecord[] | undefined = undefined,
    kitMembers: KitMemberObjRead[] | undefined = undefined
): AddElementItemByNomenclatureAction => ({
    type: ACTION_TYPES.ADD_ELEMENT_ITEM_BY_NOMENCLATURE,
    payload: { nomenclature, records, kitMembers },
});

interface AddInstanceToElementAction extends Action {
    type: ACTION_TYPES.ADD_INSTANCE_TO_ELEMENT;
    payload: {
        elementId: number;
        instanceId: number;
    };
}

export const addInstanceToElement = (elementId: number, instanceId: number): AddInstanceToElementAction => ({
    type: ACTION_TYPES.ADD_INSTANCE_TO_ELEMENT,
    payload: { elementId, instanceId },
});

interface AddElementItemFromElementsAction extends Action {
    type: ACTION_TYPES.ADD_ELEMENT_ITEM_FROM_ELEMENTS;
    payload: {
        element: OperationElement;
    };
}

export const addElementItemFromElements = (element: OperationElement): AddElementItemFromElementsAction => ({
    type: ACTION_TYPES.ADD_ELEMENT_ITEM_FROM_ELEMENTS,
    payload: { element },
});

interface RemoveInstanceFromElementAction extends Action {
    type: ACTION_TYPES.REMOVE_INSTANCE_FROM_ELEMENT;
    payload: {
        element: OperationElement;
    };
}

export const removeInstanceFromElement = (element: OperationElement): RemoveInstanceFromElementAction => ({
    type: ACTION_TYPES.REMOVE_INSTANCE_FROM_ELEMENT,
    payload: { element },
});

interface cancelOperationElementAction extends Action {
    type: ACTION_TYPES.CANCEL_OPERATION_ELEMENT;
    payload: {
        elementId: number;
        cancelled: boolean;
    };
}

export const cancelOperationElement = (elementId: number, cancelled: boolean): cancelOperationElementAction => ({
    type: ACTION_TYPES.CANCEL_OPERATION_ELEMENT,
    payload: { elementId, cancelled },
});

interface AddKitsInfoAction extends Action {
    type: ACTION_TYPES.ADD_KITS_INFO;
    payload: {
        kitsInfo: KitInfoRead[];
    };
}

export const addKitsInfo = (kitsInfo: KitInfoRead[]): AddKitsInfoAction => ({
    type: ACTION_TYPES.ADD_KITS_INFO,
    payload: { kitsInfo },
});

// interface AddNomenclaturesAction extends Action {
//     type: ACTION_TYPES.ADD_NOMENCLATURES;
//     payload: {
//         nomenclatures: NomenclatureRecord[];
//     };
// }

// export const addNomenclatures = (nomenclatures: NomenclatureRecord[]): AddNomenclaturesAction => ({
//     type: ACTION_TYPES.ADD_NOMENCLATURES,
//     payload: { nomenclatures },
// });

const filterEquipmentData = (data: Array<RentElementsGridItemCustom>, params: EquipmentListParams): Array<RentElementsGridItemCustom> => {
    let entities = data;
    let newEntities: RentElementsGridItemCustom[] = entities ? [...entities] : [];
    let filtered: RentElementsGridItemCustom[] = [];

    newEntities = newEntities.map((element) => ({
        ...element,
        subRows: element.subRows?.map((subRow) => subRow),
    }));

    newEntities.forEach((element) => {
        if (element.subRows && element.subRows.length > 0) {
            // набор
            if (
                element.stateCode === RentStateCodeEnum.MIXED &&
                params.typeCode?.length === 1 &&
                params.typeCode?.includes(element.stateCode)
            )
                filtered.push(element);
            else {
                filtered.push(element);
                let filteredSubRows: RentElementsGridItemCustom[] = [];
                element.subRows.forEach((subRow) => {
                    if (filterEquipmentElement(subRow, params)) filteredSubRows.push(subRow);
                });
                element.subRows = filteredSubRows;
            }
        } else {
            if (filterEquipmentElement(element, params)) filtered.push(element);
        }
    });

    filtered = filtered.filter((element) => !element.subRows || element.subRows.length > 0);
    return filtered;
};

const filterEquipmentElement = (element: RentElementsGridItemCustom, params: EquipmentListParams): boolean => {
    let statuses: any[] = [];
    let _statuses: any[] = [];

    if (!params.typeCode || params.typeCode.length === 0) {
        statuses = [
            RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
            RentStateCodeEnum.SUBRENT,
            RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
            RentStateCodeEnum.SUBRENTCANCELED,
            RentStateCodeEnum.SUBRENTDRAFT,
            RentStateCodeEnum.ORDERED,
            RentStateCodeEnum.BOOKED,
            RentStateCodeEnum.RENT,
            RentStateCodeEnum.RETURNED,
            RentStateCodeEnum.RETURNEDBROKEN,
            RentStateCodeEnum.LOSTDURINGRENT,
            RentStateCodeEnum.CANCELED,
            RentStateCodeEnum.DRAFT,
            RentStateCodeEnum.MIXED,
        ];
    } else {
        statuses = [...params.typeCode];
    }

    let hiddenTypes = params.hide ? getHideStateCodeFilterTypes(params.hide) : [];
    statuses.forEach((status) => {
        if (!hiddenTypes.includes(status)) _statuses.push(status);
    });

    let newEntities: RentElementsGridItemCustom[] = [element];
    newEntities = newEntities.filter((element) => _statuses.includes(element.stateCode));
    if (newEntities.length === 0) return false;

    // Фильтр по проблеме
    if (params.problem) {
        let problem = params.problem;
        let newEntities: RentElementsGridItemCustom[] = [element];
        if (problem === ProblemEnum.RETURN_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.returnDelay === true);
        } else if (problem === ProblemEnum.GIVEAWAY_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.giveawayDelay === true);
        } else if (problem === ProblemEnum.BOOK_SHORTAGE) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.bookShortage === true);
        } else if (problem === ProblemEnum.ORDER_SHORTAGE) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.orderShortage === true);
        } else if (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.subrentShipmentDelay === true);
        } else if (problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.subrentReturnToShipperDelay === true);
        } else if (problem === ProblemEnum.ANY_DELAY) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.anyDelay === true);
        } else if (problem === ProblemEnum.ANY_SHORTAGE) {
            newEntities = newEntities.filter((element) => element.problemsAndWarnings?.anyShortage === true);
        } else if (problem === ProblemEnum.ANY_PROBLEM) {
            newEntities = newEntities.filter(
                (element) => element.problemsAndWarnings?.severity && element.problemsAndWarnings?.severity > 4
            );
        } else if (problem === ProblemEnum.ANY_PROBLEM_OR_WARNING) {
            newEntities = newEntities.filter(
                (element) => element.problemsAndWarnings?.severity && element.problemsAndWarnings?.severity > 0
            );
        }
        if (newEntities.length === 0) return false;
    }

    // Фильтр по дате начала
    if (params.startDate) {
        let newEntities: RentElementsGridItemCustom[] = [element];
        let dateFromMSec = params.startDate[0]
            ? moment(params.startDate[0]).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf()
            : undefined;
        let dateToMSec = params.startDate[1]
            ? moment(params.startDate[1]).hours(23).minutes(59).seconds(59).milliseconds(999).valueOf()
            : undefined;
        if (dateFromMSec && dateToMSec)
            newEntities = newEntities.filter(
                (element) =>
                    dateFromMSec &&
                    moment(element.rentTerms.rentPeriodStartDate).valueOf() >= dateFromMSec &&
                    dateToMSec &&
                    moment(element.rentTerms.rentPeriodStartDate).valueOf() <= dateToMSec
            );
        else if (dateFromMSec)
            newEntities = newEntities.filter(
                (element) => dateFromMSec && moment(element.rentTerms.rentPeriodStartDate).valueOf() >= dateFromMSec
            );
        else if (dateToMSec)
            newEntities = newEntities.filter(
                (element) => dateToMSec && moment(element.rentTerms.rentPeriodStartDate).valueOf() <= dateToMSec
            );
        if (newEntities.length === 0) return false;
    }

    // Фильтр по дате завершения
    if (params.endDate) {
        let newEntities: RentElementsGridItemCustom[] = [element];
        let dateFromMSec = params.endDate[0]
            ? moment(params.endDate[0]).hours(0).minutes(0).seconds(0).milliseconds(0).valueOf()
            : undefined;
        let dateToMSec = params.endDate[1]
            ? moment(params.endDate[1]).hours(23).minutes(59).seconds(59).milliseconds(999).valueOf()
            : undefined;
        if (dateFromMSec && dateToMSec)
            newEntities = newEntities.filter(
                (element) =>
                    dateFromMSec &&
                    moment(element.rentTerms.rentPeriodEndDate).valueOf() >= dateFromMSec &&
                    dateToMSec &&
                    moment(element.rentTerms.rentPeriodEndDate).valueOf() <= dateToMSec
            );
        else if (dateFromMSec)
            newEntities = newEntities.filter(
                (element) => dateFromMSec && moment(element.rentTerms.rentPeriodEndDate).valueOf() >= dateFromMSec
            );
        else if (dateToMSec)
            newEntities = newEntities.filter(
                (element) => dateToMSec && moment(element.rentTerms.rentPeriodEndDate).valueOf() <= dateToMSec
            );
        if (newEntities.length === 0) return false;
    }

    return true;
};

/// setAutoRecalculateShiftsCountMode
interface SetElementIsNowEditingAction extends Action {
    type: ACTION_TYPES.SET_ELEMENT_IS_NOW_EDITING;
    payload: boolean;
}

export const setElementIsNowEditing = (value: boolean): SetElementIsNowEditingAction => ({
    type: ACTION_TYPES.SET_ELEMENT_IS_NOW_EDITING,
    payload: value,
});

/// setAutoRecalculateShiftsCountMode
interface SetAutoRecalculateShiftsCountModeAction extends Action {
    type: ACTION_TYPES.SET_AUTO_RECALCULATE_SHIFTS_COUNT_MODE;
    payload: boolean;
}

export const setAutoRecalculateShiftsCountMode = (value: boolean): SetAutoRecalculateShiftsCountModeAction => ({
    type: ACTION_TYPES.SET_AUTO_RECALCULATE_SHIFTS_COUNT_MODE,
    payload: value,
});

/// setActualizeDateMode
interface SetActualizeDateModeAction extends Action {
    type: ACTION_TYPES.SET_ACTUALIZE_DATES_MODE;
    payload: boolean;
}

export const setActualizeDateMode = (value: boolean): SetActualizeDateModeAction => ({
    type: ACTION_TYPES.SET_ACTUALIZE_DATES_MODE,
    payload: value,
});

interface SetLoading extends Action {
    type: ACTION_TYPES.SET_LOADING;
    payload: boolean;
}

export const setLoading = (loading: boolean): SetLoading => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
});

interface SetDiscountForElement extends Action {
    type: ACTION_TYPES.SET_DISCOUNT_FOR_ELEMENT;
    payload: {
        elementId: number;
        discount: number;
    };
}

export const setDiscountForElement = (elementId: number, discount: number): SetDiscountForElement => ({
    type: ACTION_TYPES.SET_DISCOUNT_FOR_ELEMENT,
    payload: {
        elementId,
        discount,
    },
});

interface SetShiftCountForElement extends Action {
    type: ACTION_TYPES.SET_SHIFT_COUNT_FOR_ELEMENT;
    payload: {
        elementId: number;
        shiftCount: number;
    };
}

export const setShiftCountForElement = (elementId: number, shiftCount: number): SetShiftCountForElement => ({
    type: ACTION_TYPES.SET_SHIFT_COUNT_FOR_ELEMENT,
    payload: {
        elementId,
        shiftCount,
    },
});

interface SetPriceForElementAction extends Action {
    type: ACTION_TYPES.SET_PRICE_FOR_ELEMENT;
    payload: {
        elementId: number;
        price: number;
    };
}

export const setPriceForElement = (elementId: number, price: number): SetPriceForElementAction => ({
    type: ACTION_TYPES.SET_PRICE_FOR_ELEMENT,
    payload: {
        elementId,
        price,
    },
});

interface SetRentPeriodForElement extends Action {
    type: ACTION_TYPES.SET_RENT_PERIOD_FOR_ELEMENT;
    payload: {
        elementId: number;
        rentPeriod: [Date, Date];
        shiftCount: number | undefined;
    };
}

export const setRentPeriodForElement = (
    elementId: number,
    rentPeriod: [Date, Date],
    shiftCount: number | undefined
): SetRentPeriodForElement => ({
    type: ACTION_TYPES.SET_RENT_PERIOD_FOR_ELEMENT,
    payload: {
        elementId,
        rentPeriod,
        shiftCount,
    },
});

interface SetStartDateForAllElementsAction extends Action {
    type: ACTION_TYPES.SET_START_DATE_FOR_ALL_ELEMENTS;
    payload: { date: Date };
}

export const setStartDateForAllElements = (date: Date): SetStartDateForAllElementsAction => ({
    type: ACTION_TYPES.SET_START_DATE_FOR_ALL_ELEMENTS,
    payload: { date },
});

interface SetEndDateForAllElementsAction extends Action {
    type: ACTION_TYPES.SET_END_DATE_FOR_ALL_ELEMENTS;
    payload: { date: Date };
}

export const setEndDateForAllElements = (date: Date): SetEndDateForAllElementsAction => ({
    type: ACTION_TYPES.SET_END_DATE_FOR_ALL_ELEMENTS,
    payload: { date },
});

// loadConcurrentOperations
interface LoadConcurrentOperationsAction extends Action {
    type: ACTION_TYPES.LOAD_CONCURRENT_OPERATIONS;
    payload: { activityFrameId: number; activityFrameType: RentActivityFrameTypeCodeEnum };
}

interface LoadConcurrentOperationsSuccessAction extends Action {
    type: ACTION_TYPES.LOAD_CONCURRENT_OPERATIONS_SUCCESS;
    payload: AxiosResponse<ConcurrentOperationObjList>;
}

export const registerAndListConcurrentOperations = (concurrentOperationObj: ConcurrentOperationObj) => {
    return (dispatch, getState: () => IRootState) => {
        let rootState: IRootState = getState();

        return dispatch({
            type: ACTION_TYPES.LOAD_CONCURRENT_OPERATIONS,
            payload: serverApi.registerAndListConcurrentOperations(rootState.system.businessAccountId, concurrentOperationObj),
        }) as any;
    };
};

// removeConcurrentOperation
interface RemoveConcurrentOperationAction extends Action {
    type: ACTION_TYPES.REMOVE_CONCURRENT_OPERATION;
    payload: { uuid: string };
}

// interface RemoveConcurrentOperationPendingAction extends Action {
//     type: ACTION_TYPES.REMOVE_CONCURRENT_OPERATION_PENDING;
//     //payload: AxiosPromise<ConcurrentOperationObj[]>;
// }

interface RemoveConcurrentOperationSuccessAction extends Action {
    type: ACTION_TYPES.REMOVE_CONCURRENT_OPERATION_SUCCESS;
    payload: AxiosResponse<void>;
}

// interface RemoveConcurrentOperationErrorAction extends Action {
//     type: ACTION_TYPES.REMOVE_CONCURRENT_OPERATION_ERROR;
//     payload: AxiosError;
// }

export const removeConcurrentOperation = (uuid: string) => {
    return (dispatch, getState: () => IRootState) => {
        let rootState: IRootState = getState();

        return dispatch({
            type: ACTION_TYPES.REMOVE_CONCURRENT_OPERATION,
            payload: serverApi.removeConcurrentOperation(rootState.system.businessAccountId, uuid),
        }) as any;
    };
};

// updateElementsContext
interface UpdateElementsContextAction extends Action {
    type: ACTION_TYPES.UPDATE_ELEMENTS_CONTEXT;
    payload: {
        records: (NomenclatureRecord | InstanceRecord)[];
        elementsDelayedReturnDates: OperationFormState['elementsDelayedReturnDates'];
        project?: ProjectInfoRead;
        operationStartTime: Moment;
    };
}

export const updateElementsContext = (): ThunkAction<any, RootState, any, UpdateElementsContextAction> => {
    return async (dispatch, getState: () => RootState) => {
        const state: RootState = getState();
        //const elements = state.operationForm.elements.entities;
        //const records: (NomenclatureRecord | InstanceRecord)[] = [];
        let elementsDelayedReturnDates: OperationFormState['elementsDelayedReturnDates'] = {};

        let project: ProjectInfoRead | undefined;

        if (!state.operationForm.isSubrent && !state.operationForm.projectTemplate && state.operationForm.projectId) {
            try {
                let projectResp = await serverApi.getProjectById(state.system.businessAccountId, state.operationForm.projectId);
                project = projectResp.data;
            } catch (err) {
                throw err;
            }
        }

        /*if (elements.length > 0) {
            const instanceIds: number[] = [];
            const productIds: number[] = [];
            const variantIds: number[] = [];
            let startDate = Number.MAX_SAFE_INTEGER;
            let endDate = 0;
            elements.forEach((element) => {
                if (element.instanceIds && element.instanceIds.length > 0) {
                    element.instanceIds.forEach((id) => {
                        if (!instanceIds.includes(id)) instanceIds.push(id);
                    });
                }
                if (element.productId && !productIds.includes(element.productId)) productIds.push(element.productId);
                if (element.variantId && !variantIds.includes(element.variantId)) variantIds.push(element.variantId);
                else if (!element.variantId && !variantIds.includes(-1)) variantIds.push(-1);

                let itemStartDate = element.rentPeriodStartDate;
                let itemEndDate = element.rentPeriodEndDate;
                // Это хак для шаблонов
                if (state.operationForm.projectTemplate) {
                    itemStartDate = new Date();
                    itemEndDate = new Date(Date.now() + 100000);
                }
                startDate = Math.min(startDate, moment(itemStartDate).valueOf());
                endDate = Math.max(endDate, moment(itemEndDate).valueOf());
            });

            if (instanceIds.length > 0) {
                let possibleDelayedRentElementIds = elements
                    .filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT)
                    .map((item) => item.id);
                let res: AxiosResponse<InstanceRecordList> | undefined;
                try {
                    res = await serverApi.listInstancesOnInterval(
                        state.system.businessAccountId,
                        startDate - 365 * 1 * 24 * 60 * 60 * 1000,
                        endDate + 365 * 2 * 24 * 60 * 60 * 1000,
                        {
                            possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                            listFilters: [`id;IN;${instanceIds.join(';')}`]
                        },
                        instanceIds.length,
                        0,
                        undefined,
                        undefined,
                        undefined,
                        [
                            TimetableTypeCodeEnum.ORDERAVAILABLE,
                            TimetableTypeCodeEnum.AVAILABLE,
                            TimetableTypeCodeEnum.STOCK,
                            TimetableTypeCodeEnum.ORDER,
                        ]
                    );
                } catch (e) {
                    throw e;
                }
                if (res) {
                    records.push(...res.data.records);
                    res.data.elementsDelayedReturnDates?.forEach((item) => {
                        elementsDelayedReturnDates[item.id] = new Date(item.date);
                    });
                }
            }

            let filters = ['productId;IN;' + productIds.join(';')];
            if (variantIds.length > 0) filters.push('variantId;IN;' + variantIds.join(';'));
            filters.push('identifiesRentElementAvailability;EQ;true');

            let possibleDelayedRentElementIds = elements
                .filter((item) => isDefined(item.productId) && item.stateCode === RentStateCodeEnum.RENT)
                .map((item) => item.id);
            let productsRes: AxiosResponse<NomenclatureRecordList> | undefined;
            try {
                productsRes = await serverApi.listNomenclatureOnInterval(
                    state.system.businessAccountId,
                    startDate - 365 * 1 * 24 * 60 * 60 * 1000,
                    endDate + 365 * 2 * 24 * 60 * 60 * 1000,
                    {
                        possibleDelayedRentElementIds: possibleDelayedRentElementIds.length ? possibleDelayedRentElementIds : undefined,
                        listFilters: filters
                    },
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [
                        TimetableTypeCodeEnum.ORDERAVAILABLE,
                        TimetableTypeCodeEnum.AVAILABLE,
                        TimetableTypeCodeEnum.STOCK,
                        TimetableTypeCodeEnum.ORDER,
                    ]
                );
            } catch (e) {
                throw e;
            }
            if (productsRes) {
                records.push(...productsRes.data.records);
                productsRes.data.elementsDelayedReturnDates?.forEach((item) => {
                    elementsDelayedReturnDates[item.id] = new Date(item.date);
                });
            }
        }
*/
        const operationStartTime = moment(getCurrentServerTime())/*.seconds(0)*/.milliseconds(0);
        await dispatch(reloadAllTimetables());

        return dispatch({
            type: ACTION_TYPES.UPDATE_ELEMENTS_CONTEXT,
            payload: { records: [], elementsDelayedReturnDates, project, operationStartTime },
        });
    };
};
//
// interface ReloadAllNomenclaturesAction extends Action {
//     type: ACTION_TYPES.UPDATE_NOMENCLATURES;
//     payload: NomenclatureOnInterval[];
// }
//
// export const reloadAllNomenclatures = (): ThunkAction<any, RootState, any, ReloadAllNomenclaturesAction> => {
//     return async (dispatch, getState: () => RootState) => {
//         const state = getState();
//         const nomenclatures = await loadAllNomenclaturesForElements(state.system.businessAccountId, state.operationForm.elements.entities);
//         return dispatch({
//             type: ACTION_TYPES.UPDATE_NOMENCLATURES,
//             payload: nomenclatures,
//         });
//     };
// };

type ObjectValues<T> = T extends {
    [K in string]: infer U;
}
    ? U
    : never;

type ActionCreate<Type extends ObjectValues<typeof ACTION_TYPES>, Payload> = {
    type: Type;
    payload: Payload;
};

type SetCommentAction = ActionCreate<typeof ACTION_TYPES.SET_COMMENT, string | undefined>;

export const setComment = (value?: string): SetCommentAction => ({
    type: ACTION_TYPES.SET_COMMENT,
    payload: value,
});

type SetCommentVisibilityAction = ActionCreate<typeof ACTION_TYPES.SET_COMMENT_VISIBILITY, boolean>;

export const setCommentVisibility = (value: boolean): SetCommentVisibilityAction => ({
    type: ACTION_TYPES.SET_COMMENT_VISIBILITY,
    payload: value,
});

// type UpdateNomenclaturesAction = ActionCreate<typeof ACTION_TYPES.UPDATE_NOMENCLATURES, NomenclatureOnInterval[]>;
//
// export const updateNomenclatures = (value: NomenclatureOnInterval[]): UpdateNomenclaturesAction => ({
//     type: ACTION_TYPES.UPDATE_NOMENCLATURES,
//     payload: value,
// });

// type UpdateTimetablesFromServerAction = ActionCreate<typeof ACTION_TYPES.UPDATE_TIMETABLES_FROM_SERVER, TimeTable[]>;

// export const updateTimetablesFromServer = (value: TimeTable[]): UpdateTimetablesFromServerAction => ({
//     type: ACTION_TYPES.UPDATE_TIMETABLES_FROM_SERVER,
//     payload: value,
// });

interface ReloadAllTimetablesAction extends Action {
    type: ACTION_TYPES.UPDATE_TIMETABLES;
    payload: { timetables: TimeTable[]; updateState: boolean; elementsDelayedReturnDates: { [key: number]: Date } };
}

export const reloadAllTimetables = (): ThunkAction<any, RootState, any, ReloadAllTimetablesAction> => {
    // TODO По ходу нужно захватить еще номенклатуру с измененным вариантом
    return async (dispatch, getState: () => RootState) => {
        const state = getState();
        const targetStackTypeCodes = getTargetStackTypeCodes({isProjectTemplate: state.operationForm.projectTemplate, isOrderOperation: isOrderOperation(state.operationForm.typeCode)});
        const res = await loadAllNomenclaturesForRentElements(state.system.businessAccountId, state.operationForm.elements.entities, targetStackTypeCodes);
        const timetables = nomenclaturesToTimetables(res.nomenclatures);
        //
        const _elementsDelayedReturnDates = {};
        res.elementsDelayedReturnDates?.forEach((item) => {
            _elementsDelayedReturnDates[item.id] = item.date;
        });

        return dispatch({
            type: ACTION_TYPES.UPDATE_TIMETABLES,
            payload: { timetables, updateState: true, elementsDelayedReturnDates: _elementsDelayedReturnDates },
        });
    };
};

interface UpdateAnyTimetablesAction extends Action {
    type: ACTION_TYPES.UPDATE_TIMETABLES;
    payload: { timetables: TimeTable[]; updateState: boolean; elementsDelayedReturnDates: { [key: number]: Date } };
}

export const updateAnyTimetables = (
    timetables: TimeTable[],
    elementsDelayedReturnDates?: IdDatePairObj[]
): ThunkAction<any, RootState, any, UpdateAnyTimetablesAction> => {
    // TODO По ходу нужно захватить еще номенклатуру с измененным вариантом
    return async (dispatch, getState: () => RootState) => {
        const state = getState().operationForm;
        const ttToUpdate: TimeTable[] = [];
        timetables.forEach((tt) => {
            const t = state.timeTables.find((t) => t.id === tt.id && t.type === tt.type);
            if (t) {
                if (t.from !== tt.from || t.until !== tt.until) ttToUpdate.push(tt);
            } else {
                ttToUpdate.push(tt);
            }
        });

        let newTimetables: TimeTable[];
        let updateState: boolean;
        if (ttToUpdate.length > 0) {
            updateState = true;
            newTimetables = [...state.timeTables];
            ttToUpdate.forEach((tt) => {
                const index = newTimetables.findIndex((t) => t.id === tt.id && t.type === tt.type);
                if (index > -1) {
                    newTimetables[index] = tt;
                } else {
                    newTimetables.push(tt);
                }
            });
        } else {
            updateState = false;
            newTimetables = state.timeTables;
        }

        const _elementsDelayedReturnDates =
            elementsDelayedReturnDates && elementsDelayedReturnDates.length > 0
                ? { ...state.elementsDelayedReturnDates }
                : state.elementsDelayedReturnDates;
        elementsDelayedReturnDates?.forEach((item) => {
            _elementsDelayedReturnDates[item.id] = item.date;
        });

        return dispatch({
            type: ACTION_TYPES.UPDATE_TIMETABLES,
            payload: { timetables: newTimetables, updateState: updateState, elementsDelayedReturnDates: _elementsDelayedReturnDates },
        });
    };
};

interface RemoveAllTimetablesAction extends Action {
    type: ACTION_TYPES.UPDATE_TIMETABLES;
    payload: { timetables: TimeTable[]; updateState: boolean; elementsDelayedReturnDates: { [key: number]: Date } };
}

export const removeAllTimetables = (): ThunkAction<any, RootState, any, RemoveAllTimetablesAction> => {
    return async (dispatch, getState: () => RootState) => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_TIMETABLES,
            payload: { timetables: [], updateState: false, elementsDelayedReturnDates: [] },
        });
    };
};

export const commentSelector = (state: RootState) => state.operationForm.currentOperation?.comment;
export const commentVisibleSelector = (state: RootState) => state.operationForm.currentOperation?.commentVisible;

type SetStateAction = ActionCreate<typeof ACTION_TYPES.SET_STATE, OperationFormState>;

export const setOperationFormState = (state: OperationFormState): SetStateAction => ({
    type: ACTION_TYPES.SET_STATE,
    payload: state,
});

// effectivePrice
type SetEffectivePriceAndDiscountForSelectedIdsAction = ActionCreate<
    typeof ACTION_TYPES.SET_EFFECTIVE_PRICE_AND_DISCOUNT_FOR_SELECTED_IDS,
    { selectedIds: number[]; price?: number; discount?: number }
>;

export const setEffectivePriceAndDiscountForSelectedIds = (
    selectedIds: number[],
    price: number | undefined,
    discount: number | undefined
): SetEffectivePriceAndDiscountForSelectedIdsAction => ({
    type: ACTION_TYPES.SET_EFFECTIVE_PRICE_AND_DISCOUNT_FOR_SELECTED_IDS,
    payload: { selectedIds, price, discount },
});

// finalPricePerShift
type SetFinalPricePerShiftForSelectedIdsAction = ActionCreate<
    typeof ACTION_TYPES.SET_FINAL_PRICE_PER_SHIFT_FOR_SELECTED_IDS,
    { selectedIds: number[]; price: number }
>;

export const setFinalPricePerShiftForSelectedIds = (selectedIds: number[], price: number): SetFinalPricePerShiftForSelectedIdsAction => ({
    type: ACTION_TYPES.SET_FINAL_PRICE_PER_SHIFT_FOR_SELECTED_IDS,
    payload: { selectedIds, price },
});

// finalTotalPrice
type SetFinalTotalPriceForSelectedIdsAction = ActionCreate<
    typeof ACTION_TYPES.SET_FINAL_TOTAL_PRICE_FOR_SELECTED_IDS,
    { selectedIds: number[]; price: number }
>;

export const setFinalTotalPriceForSelectedIds = (selectedIds: number[], price: number): SetFinalTotalPriceForSelectedIdsAction => ({
    type: ACTION_TYPES.SET_FINAL_TOTAL_PRICE_FOR_SELECTED_IDS,
    payload: { selectedIds, price },
});

// Даты
type SetDatesForSelectedIdsAction = ActionCreate<
    typeof ACTION_TYPES.SET_DATES_FOR_SELECTED_IDS,
    { selectedIds: number[]; dates: [Date|undefined, Date|undefined] }
    >;

export const setDatesForSelectedIds = (selectedIds: number[], dates: [Date|undefined, Date|undefined]): SetDatesForSelectedIdsAction => ({
    type: ACTION_TYPES.SET_DATES_FOR_SELECTED_IDS,
    payload: { selectedIds, dates },
});

// Смены
type SetShiftCountForSelectedIdsAction = ActionCreate<
    typeof ACTION_TYPES.SET_SHIFT_COUNT_FOR_SELECTED_IDS,
    { selectedIds: number[]; shiftCount: number }
    >;

export const setShiftCountForSelectedIds = (selectedIds: number[], shiftCount: number): SetShiftCountForSelectedIdsAction => ({
    type: ACTION_TYPES.SET_SHIFT_COUNT_FOR_SELECTED_IDS,
    payload: { selectedIds, shiftCount },
});

// Пересчитать смены
type RecalculateShiftCountForSelectedIdsAction = ActionCreate<
    typeof ACTION_TYPES.RECALCULATE_SHIFT_COUNT_FOR_SELECTED_IDS,
    { selectedIds: number[];}
    >;

export const recalculateShiftCountForSelectedIds = (selectedIds: number[]): RecalculateShiftCountForSelectedIdsAction => ({
    type: ACTION_TYPES.RECALCULATE_SHIFT_COUNT_FOR_SELECTED_IDS,
    payload: { selectedIds },
});

type SetTotalDiscountAction = ActionCreate<typeof ACTION_TYPES.SET_TOTAL_DISCOUNT, { price: number }>;

export const setTotalDiscount = (price: number): SetTotalDiscountAction => ({
    type: ACTION_TYPES.SET_TOTAL_DISCOUNT,
    payload: { price },
});

type SetFinalTotalPriceAction = ActionCreate<typeof ACTION_TYPES.SET_FINAL_TOTAL_PRICE, { price: number }>;

export const setFinalTotalPrice = (price: number): SetFinalTotalPriceAction => ({
    type: ACTION_TYPES.SET_FINAL_TOTAL_PRICE,
    payload: { price },
});

type SetNomenclaturesAction = ActionCreate<typeof ACTION_TYPES.SET_NOMENCLATURES, any[] | undefined>;

export const setNomenclatures = (nomenclatures?: any[]): SetNomenclaturesAction => ({
    type: ACTION_TYPES.SET_NOMENCLATURES,
    payload: nomenclatures,
});

import { FormFieldsGroup, FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField } from '../../../../../core/utils/formUtils';
import { ListExpensesParams } from '../../api/expenses.api.types';
import { LocalizationEnum } from '../../../../../localization';
import { ProjectRecord, ProjectStateCodeEnum } from '../../../../../server';
import React, { ReactNode } from 'react';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Select } from 'antd';
import { Dot } from '../../../../../components/dot/dot';
import { SliderRangeWithManualInput } from '../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { createHiddenField } from '../../../../../components/modalForm/utils';
import {FiltersDateTimeRangePicker} from "../../../../../components/datePicker/FiltersDateTimeRangePicker";

export const expensesFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListExpensesParams>([
    {
        fields: [
            createHiddenField('authorId') as TypedFormField<ListExpensesParams>,
            createHiddenField('screenLeft') as TypedFormField<ListExpensesParams>,
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 180 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: [
                            `stateCode;IN;${[ProjectStateCodeEnum.NEW, ProjectStateCodeEnum.INPROGRESS].join(';')}`,
                            'archive;EQ;false',
                        ],
                        renderer: (data: { name: ReactNode; id: number; data?: ProjectRecord }) => {
                            const isNewStatus = data.data?.stateCode === ProjectStateCodeEnum.NEW;
                            const disabled = isNewStatus && form.getFieldValue('stateCode') === ProjectStateCodeEnum.INPROGRESS;
                            return (
                                <Select.Option key={data.id} disabled={disabled}>
                                    {isNewStatus && <Dot className={`rr-status-bg-NEW`} style={{ marginRight: 10 }} />}
                                    {data['name']}
                                </Select.Option>
                            );
                        },
                    };
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                label: 'Скрыть отменённые',
                id: 'hideCancelled',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Внутренняя стоимость',
                id: 'internalFinalTotalPrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 1,
                }),
            },
            {
                label: 'Внешняя стоимость',
                id: 'externalFinalTotalPrice',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: (form: WrappedFormUtils) => ({
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 1,
                }),
            },
            {
                label: 'Дата с',
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'date',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateTimeRangePicker,
            },
        ],
    },
]);

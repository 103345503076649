import React, {FC, ReactNode} from 'react';
import {Button, Icon} from 'antd';
import {IconCheck, IconClose, IconInfoSolid} from '../../../../components/icons';
import classNames from 'classnames';
import {LocalizationEnum, useLocalize} from '../../../../core/hooks/useLocalize';
import {ModulesState, ModuleBadge} from "./moduleBadge";
import './moduleInfo.less';

interface ModuleInfoProps {
    name: ReactNode;
    icon: ReactNode;
    enabled: boolean | null;
    description: ReactNode;
    toggleEnabled: () => void;
    inProgress?: boolean;
}

const className = 'rr-settings-modules-module';

export const ModuleInfo: FC<ModuleInfoProps> = ({ icon, enabled, toggleEnabled, description, name, inProgress }) => {
    const L = useLocalize();
    let actionButtonDisabled: boolean;
    let actionButtonLabel: ReactNode;
    let actionButtonClassName: string;
    let status: ReactNode;
    let statusIcon: ReactNode;
    let statusClassName: string;

    if (enabled === true) {
        actionButtonDisabled = false;
        actionButtonLabel = L(LocalizationEnum.PAGE__SETTINGS__MODULES__DISABLE_BUTTON_LABEL);
        actionButtonClassName = 'rr-round-btn-dangerSecondary';
        status = L(LocalizationEnum.PAGE__SETTINGS__MODULES__ENABLED_STATUS);
        statusIcon = <Icon component={IconCheck} />;
        statusClassName = 'enabled';
    } else if (enabled === false) {
        actionButtonDisabled = false;
        actionButtonLabel = L(LocalizationEnum.PAGE__SETTINGS__MODULES__ENABLE_BUTTON_LABEL);
        actionButtonClassName = 'rr-round-btn-successSecondary';
        status = L(LocalizationEnum.PAGE__SETTINGS__MODULES__DISABLED_STATUS);
        statusIcon = <Icon component={IconClose} />;
        statusClassName = 'disabled';
    } else {
        actionButtonDisabled = true;
        actionButtonLabel = L(LocalizationEnum.PAGE__SETTINGS__MODULES__ENABLE_BUTTON_LABEL);
        actionButtonClassName = 'rr-round-btn-successSecondary';
        status = L(LocalizationEnum.PAGE__SETTINGS__MODULES__UNAVAILABLED_STATUS);
        statusClassName = 'unavailable';
    }

    return (
        <div className={className}>
            <div className={`${className}-icon`}>{icon}</div>
            <div className={`${className}-title`}>
                <div className={`${className}-name`}>{name}</div>
                {
                    !inProgress && (
                        <div className={classNames(`${className}-status`, `${className}-status__${statusClassName}`)}>
                            {statusIcon && <span className={`${className}-status-icon`}>{statusIcon}</span>}
                            {status}
                        </div>
                    )
                }
                {
                    inProgress && (
                        <ModuleBadge style={{marginTop: 10, width: 185}} type={ModulesState.IN_PROGRESS} />
                    )
                }
            </div>
            <div className={`${className}-description`}>
                {description && (
                    <div className={`${className}-description-icon`}>
                        <Icon component={IconInfoSolid} />
                    </div>
                )}
                <div>{description}</div>
            </div>
            <div className={`${className}-actions`}>
                <Button className={actionButtonClassName} disabled={actionButtonDisabled} onClick={() => toggleEnabled()}>
                    {actionButtonLabel}
                </Button>
            </div>
        </div>
    );
};

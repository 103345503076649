import React, {FC, ReactElement, useState} from 'react';
import RCRangeCalendar from 'rc-calendar/lib/RangeCalendar';
import moment, {Moment} from "moment";
import {getCalendarLocaleByCurrentLocale} from "../../../../../../localization/localizationUtils";
import {TodayButton} from "../todayButton/todayButton";
import {DatePickerMode} from "antd/lib/date-picker/interface";
import {RangeCalendarFooter, RangeCalendarFooterProps} from "./rangeCalendarFooter";
import classNames from "classnames";
import './rangeCalendar.less';

interface RangeCalendarProps {
    value?: [Moment|undefined, Moment|undefined];
    onChange?: (value?: [Moment, Moment]) => void;
    onOk?: () => void;
    footer?: ReactElement|null;
    showTime?: boolean;
    renderDate?: (current: moment.Moment, today: moment.Moment) => React.ReactNode;
    //
    recalculateShiftCountBlockProps?: RangeCalendarFooterProps['recalculateShiftCountBlockProps'];
    requiredTimeIndentProps?:RangeCalendarFooterProps['requiredTimeIndentProps'];

    disabledDate?: (current:Moment)=>boolean;

    onStartDateTodayButtonClick?:()=>void;
    onEndDateTodayButtonClick?:()=>void;
    onStartDateTodayButtonClick1?:(()=>void)|null;
    onEndDateTodayButtonClick1?:(()=>void)|null;
}

export const RangeCalendar:FC<RangeCalendarProps> = (props) => {

    const [mode, setMode]= useState<[DatePickerMode, DatePickerMode]>(['date', 'date']);
    const startDate = props.value?.[0]?.startOf('second');
    const endDate = props.value?.[1]?.startOf('second');

    const onChange = (value: [Moment, Moment])=>{
        const startD = value[0].startOf('second');
        const endD = value[1].startOf('second');

        const newValue:[Moment, Moment] = [startD, endD];
        props.onChange?.(newValue);
    };

    return (
        <RCRangeCalendar
            disabledDate={props.disabledDate}
            prefixCls={'ant-calendar'}
            className={classNames('rr-range-calendar', 'rr-range-calendar-with-intervals')}
            showToday={false}
            selectedValue={props.value}
            dateRender={props.renderDate}
            locale={getCalendarLocaleByCurrentLocale()}
            onChange={(dates:[Moment|undefined, Moment|undefined])=>{
                if(dates[0] && dates[1]) onChange?.(dates as [Moment, Moment]);
            }}
            mode={mode}
            onPanelChange={(value, m: [DatePickerMode, DatePickerMode]) => {
                let newMode = [...m] as [DatePickerMode, DatePickerMode];
                if (mode[0] === 'year' && m[0] === 'date') newMode[0] = 'month';
                if (mode[1] === 'year' && m[1] === 'date') newMode[1] = 'month';
                setMode(newMode)
            }}
            renderSidebar={()=>{
                return (
                    <>
                        <TodayButton
                            disabled={!props.onStartDateTodayButtonClick}
                            onClick={props.onStartDateTodayButtonClick}
                            style={{position: "absolute", zIndex: 11, top: 20.5, right: 414}}
                        />
                        <TodayButton
                            disabled={!props.onEndDateTodayButtonClick}
                            onClick={props.onEndDateTodayButtonClick}
                            style={{position: "absolute", zIndex: 11, top: 20.5, right: 68}}
                        />
                    </>
                )
            }}
            renderFooter={()=>{
                if(props.footer === null) return null;
                else if(props.footer) return props.footer;
                else return (
                    <RangeCalendarFooter
                        showTime={props.showTime}
                        value={props.value}
                        onChange={(dates)=>{
                            const newStartDate = startDate ? startDate.clone() : undefined;
                            const newEndDate = endDate ? endDate.clone() : undefined;
                            if(newStartDate && newEndDate){
                                if(dates?.[0]){
                                    newStartDate.hours(dates[0].hours()).minutes(dates[0].minutes());
                                }
                                if(dates?.[1]){
                                    newEndDate.hours(dates[1].hours()).minutes(dates[1].minutes());
                                }
                                onChange?.([newStartDate, newEndDate] as [Moment, Moment]);
                            }
                        }}
                        onOk={props.onOk}
                        recalculateShiftCountBlockProps={props.recalculateShiftCountBlockProps}
                        requiredTimeIndentProps={props.requiredTimeIndentProps}
                        onStartDateTodayButtonClick1={props.onStartDateTodayButtonClick1}
                        onEndDateTodayButtonClick1={props.onEndDateTodayButtonClick1}
                    />
                );
            }}
        />
    );
};

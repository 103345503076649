import React, { createRef, ReactNode, RefObject } from 'react';
import { Button, Icon, Select } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import { LocalizationEnum, localize } from '../../../../../localization';
import { IconCrosshairsSolid, IconMoneyCheckAltSolid } from '../../../../../components/icons';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import {
    NomenclatureRecord,
    NomenclatureStateCodeEnum,
    ProjectRecord,
    ProjectStateCodeEnum,
    ProjectTypeCodeEnum,
    RentStateCodeEnum,
    SubrentRecord,
    SubrentStateCodeEnum,
} from '../../../../../server';
import { Popover } from '../../../../../components/popover/Popover';
import { MoveElementsToOtherProjectFunction } from '../../elements-list';
import { ProjectStateBadge } from '../../../projects/production/components/ProjectStateBadge/ProjectStateBadge';
import { ProjectStateCode } from '../../../projects/production/data/projectData';
import { ServerUtils } from '../../../../../core/utils/serverUtils';
import ReactDOM from 'react-dom';

export interface IValue extends NomenclatureRecord {
    id: number;
}

interface IProps extends StateProps, DispatchProps {
    children: ReactNode;
    moveProjectElementsToOtherProject: MoveElementsToOtherProjectFunction;
    stateCode?: NomenclatureStateCodeEnum;
    selectElementsForMove(): { subrentId: number; elements: Array<any>; projectId: number };
    copy: boolean;
    isSubrent: boolean;
    projectStateCode: ProjectStateCodeEnum | SubrentStateCodeEnum | undefined;
    closeMoveElementPopup?: () => void;
    counterpartyId?: number;
    hasNotAnonymousInstances?: boolean;
    copyWithoutInstances?: boolean;
    showConcurrentOperationsPopup?: boolean;
    additionalFilters?: string[];
}

interface IState {
    visible: boolean;
    value?: ProjectRecord | SubrentRecord;
}

class _AddProjectPopover extends React.Component<IProps, IState> {
    _ref: RefObject<Select>;

    constructor(props: IProps) {
        super(props);

        this._ref = createRef();

        this.state = {
            visible: false,
            value: undefined,
        };
    }

    componentDidUpdate(_: IProps, prevState: IState) {
        if (this.state.visible && this.state.visible !== prevState.visible) {
            setTimeout(() => {
                const node = ReactDOM.findDOMNode(this._ref.current);
                if (node != null) {
                    const event = new MouseEvent('click', { bubbles: true });
                    node.dispatchEvent(event);
                }
            }, 250);
        }
    }

    handleVisibleChange = (visible: boolean) => {
        if (!visible) {
            this.setState({ visible, value: undefined });
        } else {
            this.setState({ visible });
        }
    };

    onButtonClick = async (e) => {
        e.stopPropagation();
        const targetProjectType = this.state.value && 'projectType' in this.state.value ? this.state.value.projectType : undefined;
        if (this.state.value || e.target.value !== '') {
            this.props.moveProjectElementsToOtherProject(
                this.state.value ?? e.target.value,
                targetProjectType,
                this.props.copy,
                this.props.copyWithoutInstances,
                this.props.showConcurrentOperationsPopup
            );
            this.props.closeMoveElementPopup?.();
            this.setState({
                value: undefined,
            });
            this.handleVisibleChange(false);
        }
    };

    newProduct = (value?: { data: IState['value'] }) => {
        this.setState({
            value: value?.data,
        });
    };

    customChangeData = (data: (ProjectRecord | SubrentRecord)[]) => {
        const selectedElementsForMove = this.props.selectElementsForMove();

        let allTemplates = selectedElementsForMove.elements.every(
            (item) =>
                item.stateCode === RentStateCodeEnum.DRAFT ||
                item.stateCode === RentStateCodeEnum.SUBRENTDRAFT ||
                item.stateCode === RentStateCodeEnum.CANCELED ||
                item.stateCode === RentStateCodeEnum.SUBRENTCANCELED
        );

        return data.map((item) => {
            const renterOrSupplierShortName = 'renterShortName' in item ? item.renterShortName : item.supplierShortName;
            const supplierId = 'supplierId' in item ? item.supplierId : undefined;
            const isOffer = 'projectType' in item && item.projectType === ProjectTypeCodeEnum.OFFER;
            const isProject = 'projectType' in item && item.projectType === ProjectTypeCodeEnum.BASE;

            let icon: ReactNode;
            if (isOffer) {
                icon = <Icon style={{ fontSize: 20, marginRight: 8 }} component={IconMoneyCheckAltSolid} />;
            } else if (isProject) {
                icon = null;
            }

            let name = (
                <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                    {icon}{' '}
                    <span>
                        {item.shortName} <span style={{ fontWeight: 200 }}>{renterOrSupplierShortName}</span>
                    </span>
                </div>
            );

            if (!this.props.copy && (item.id === selectedElementsForMove.projectId || item.id === selectedElementsForMove.subrentId)) {
                name = (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ccc',
                            cursor: 'not-allowed',
                            margin: '-5px -12px',
                            padding: '5px  12px',
                        }}
                    >
                        <Icon style={{ fontSize: 20, color: '#ababab', marginRight: 8 }} component={IconCrosshairsSolid} />
                        <span>
                            {item.shortName} <span style={{ fontWeight: 200 }}>{renterOrSupplierShortName}</span>
                        </span>
                    </div>
                );

                return { ...item, name };
            }

            if (!allTemplates) {
                if (
                    (!this.props.copy &&
                        (
                            item.id === selectedElementsForMove.projectId ||
                            item.id === selectedElementsForMove.subrentId ||
                            this.props.projectStateCode === ProjectStateCodeEnum.NEW ||
                            this.props.projectStateCode === SubrentStateCodeEnum.NEW ||

                            ((this.props.projectStateCode === ProjectStateCodeEnum.INPROGRESS) && (item.stateCode !== ProjectStateCodeEnum.INPROGRESS)) ||
                            ((this.props.projectStateCode === SubrentStateCodeEnum.INPROGRESS) && (item.stateCode !== SubrentStateCodeEnum.INPROGRESS)) ||
                            ((this.props.projectStateCode === ProjectStateCodeEnum.FINISHED ||
                                this.props.projectStateCode === SubrentStateCodeEnum.FINISHED) &&
                                (item.stateCode === ProjectStateCodeEnum.NEW || item.stateCode === SubrentStateCodeEnum.NEW)) ||
                            (this.props.isSubrent && this.props.hasNotAnonymousInstances && this.props.counterpartyId !== supplierId))) ||
                    (this.props.copy &&
                        this.props.isSubrent &&
                        this.props.hasNotAnonymousInstances &&
                        this.props.counterpartyId !== supplierId &&
                        !this.props.copyWithoutInstances)
                ) {
                    if (!isOffer && !isProject) {
                        if(item.id === selectedElementsForMove.subrentId) icon = <Icon style={{ fontSize: 20, color: '#ababab', marginRight: 8 }} component={IconCrosshairsSolid} />;
                        else if(supplierId){
                            if (
                                this.props.projectStateCode === SubrentStateCodeEnum.NEW ||
                                (this.props.projectStateCode === SubrentStateCodeEnum.INPROGRESS &&
                                    item.stateCode !== SubrentStateCodeEnum.INPROGRESS) ||
                                (this.props.projectStateCode === SubrentStateCodeEnum.FINISHED &&
                                    item.stateCode === SubrentStateCodeEnum.NEW)
                            ) {
                                icon = <ProjectStateBadge dot={true} hideLabel={true} type={item.stateCode as ProjectStateCode} />;
                            }
                        }
                    } else if (isProject) {
                        if (!this.props.copy) {
                            if (
                                this.props.projectStateCode === ProjectStateCodeEnum.NEW ||
                                (this.props.projectStateCode === ProjectStateCodeEnum.INPROGRESS &&
                                    item.stateCode !== ProjectStateCodeEnum.INPROGRESS) ||
                                (this.props.projectStateCode === ProjectStateCodeEnum.FINISHED &&
                                    item.stateCode === ProjectStateCodeEnum.NEW)
                            ) {
                                icon = <ProjectStateBadge dot={true} hideLabel={true} type={item.stateCode as ProjectStateCode} />;
                            }
                        }
                    }

                    name = (
                        <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: '#ccc',
                                cursor: 'not-allowed',
                                margin: '-5px -12px',
                                padding: '5px  12px',
                            }}
                        >
                            {icon}
                            <span>
                                {item.shortName} <span style={{ fontWeight: 200 }}>{renterOrSupplierShortName}</span>
                            </span>
                        </div>
                    );
                }
            }

            return { ...item, name };
        });
    };

    render() {
        const { children, isSubrent, additionalFilters } = this.props;
        const { visible, value } = this.state;

        let filters = ServerUtils.createRequestFilters<ProjectRecord>([
            ['archive', 'EQ', false],
            isSubrent
                ? undefined
                : [
                      'stateCode',
                      'IN',
                      [
                          ProjectStateCodeEnum.NEW,
                          ProjectStateCodeEnum.FINISHED,
                          ProjectStateCodeEnum.INPROGRESS,
                          ProjectStateCodeEnum.APPROVED,
                      ],
                  ],
        ]).concat(additionalFilters ?? []);

        return (
            <Popover
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                overlayClassName={'rr-operationForm-kit-add-element-popover'}
                headerClassName={'rr-grid-actions-popover-header-xxx'}
                header={
                    <>
                        <EntityRemoteSelect
                            _ref={this._ref}
                            style={{ width: '100%', maxWidth: 314 }}
                            placeholder={
                                isSubrent
                                    ? 'Выберите поставку'
                                    : localize(LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__PLACEHOLDER__SELECT_PROJECT)
                            }
                            nameField={'name'}
                            onChange={this.newProduct}
                            className={'rr-select-gray'}
                            showSearch
                            operationName={isSubrent ? 'listSubrents' : 'listProjects'}
                            value={value as any} // TODO избавиться от any
                            filters={filters}
                            sortOrder={'DESC'}
                            sortBy={'lastActivityDate'}
                            customChangeData={this.customChangeData as any}
                        />
                        <Button className={'rr-btn-blue'} style={{ marginLeft: 8 }} onClick={this.onButtonClick} disabled={!value}>
                            OK
                        </Button>
                    </>
                }
            >
                {children}
            </Popover>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const AddProjectPopover = connect(mapStateToProps, mapDispatchToProps)(_AddProjectPopover);

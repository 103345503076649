import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Tabs } from 'antd';
import {
    IconCartArrowDownSolid,
    IconCheck,
    IconClose,
    IconCrosshairsSolid,
    IconEdit,
    IconEllipsisHSolid,
    IconExclamationCircleSolid,
    IconExclamationSolid,
    IconListAlt,
    IconPlusCircle,
    IconSatelliteDishSolid,
    IconSyncSolid,
} from '../../../../components/icons';
import { OperationFormElementsList } from './elementsList/OperationFormElementsList';
import { Location } from 'history';
import {
    addInstanceFromScanner,
    changeOperationType,
    commentVisibleSelector,
    createOperation,
    loadElementsForProject,
    loadProducts,
    OperationElement,
    registerAndListConcurrentOperations,
    resetAllKeepLeftover,
    resetOperation,
    setActualizeDateMode,
    setAutoRecalculateShiftsCountMode,
    setCommentVisibility,
    setOperationEndDateToCurrent,
    setOperationStartDateToCurrent,
    updateElementsContext,
} from '../reducers/operationForm.reducer';
import { OperationFormPageTopBlock } from './operationFormTopBlock/OperationFormPageTopBlock';
import { OperationBottomBlock } from './operationFormBottomBlock/OperationBottomBlock';
import { Alert } from '../../../../components/alert/alert';
import {
    InstanceTrackingTypeCodeEnum,
    OperationTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../../server/api';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { push } from 'connected-react-router';
import Spin from '../../../../components/spin/spin';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { Status } from '../../../../components/status/status';
import { ErrorItem, OperationProblemModal, OperationProblemModalType } from './OperationProblemModal';
import { localize, localizeIntl } from '../../../../localization/localizationUtils';
import { LocalizationEnum } from '../../../../localization';
import moment from 'moment';
import { getPathFromState, getShiftCountFromDates, getStateFromPath } from '../../../../shared/util/utils';
import {
    canAddNewProductsToOperation,
    canBeErrorWhenShortage,
    canChangeKeepLeftover,
    canCreateOperationWithAnonymousInstances,
} from '../utils/utils';
import AdditionalEntityActions from '../../../../components/additionalEntityActions/additionalEntityActions';
import RoundButton from '../../../../components/button/roundButton';
import { OperationModuleTabsEnum } from '../../../../shared/constants/tabEnums';
import {OperationTypeSelectButton} from '../components/OperationTypeSelectButton';
import {barcodeScanEventTarget, RootState, getStore, getStoreState} from '../../../../../index';
import {BarcodeScanEvent} from '../../../../shared/BarcodeScanEvent';
import {showNotification} from '../../../../components/notification/showNotification';
import {OperationFormEquipmentList} from './equipmentList/OperationFormEquipmentList';
import { AxiosError } from 'axios';
import {scan} from "./operationFormScanUtils";
import {showScanInstanceSuccessNotification, showScanNomenclatureSuccessNotification} from "../../../../shared/reducers/scanner.reducer";
import {NomenclaturesTab} from "./nomenclaturesTab/nomenclaturesTab";
import {getGridCategoriesStorageData, getGridStorageData} from "../../../../components/grid/utils";
import {OperationFormNomenclaturesGridName} from "./nomenclaturesTab/list/nomenclaturesList";
import {Redirect} from "react-router";

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
}

interface IState {
    problemModalIsShowing: boolean;
    activeTab?: OperationModuleTabsEnum;
    loading?: boolean;
}

class Component extends React.PureComponent<IProps, IState> {
    private needToReset = false;
    private intervalId;

    private instancesScanned: number = 0;
    private instancesScannedKeyIndex: number = 0;
    private nomenclaturesScanned: number = 0;
    private nomenclaturesScannedKeyIndex: number = 0;

    constructor(props) {
        super(props);

        this.state = {
            problemModalIsShowing: false,
            activeTab: undefined,
            loading: false,
        };
    }

    onBarcodeScanEvent = async (e: CustomEvent<BarcodeScanEvent>) => {
        scan({
            tab: this.props.tab,
            operationTypeCode: this.props.operationTypeCode,
            code: e.detail.code,
            businessAccountId: this.props.businessAccountId,
            instanseAddedShowMessage: this.instanceAddedShowMessage,
            nomenclatureAddedShowMessage: this.nomenclatureAddedShowMessage,
            equipmentFilteredEntities: this.props.equipmentFilteredEntities,
            elements: this.props.elements,
            timeTables: this.props.timeTables,
            rentPeriodStartDate: this.props.rentPeriodStartDate,
            rentPeriodEndDate: this.props.rentPeriodEndDate,
            addInstanceFromScanner: this.props.addInstanceFromScanner,
            addKitMembersWhenInstanceScan: this.props.addKitMembersWhenInstanceScan,
        });
    };

    instanceAddedShowMessage = () => {
        // let key = 'uniq_' + this.instancesScannedKeyIndex;
        // this.instancesScanned++;
        // showBarcodeScannerSuccessNotification(
        //     <>
        //         <span style={{ fontWeight: 800 }}>{this.instancesScanned}</span>{' '}
        //         {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__INSTANCE_ADDED, undefined, {
        //             value: this.instancesScanned,
        //         })}
        //     </>,
        //     key,
        //     () => {
        //         this.instancesScannedKeyIndex++;
        //         this.instancesScanned = 0;
        //     }
        // );
        getStore().dispatch(showScanInstanceSuccessNotification());
    };

    nomenclatureAddedShowMessage = () => {
        // let key = 'uniq1_' + this.nomenclaturesScannedKeyIndex;
        // this.nomenclaturesScanned++;
        // showBarcodeScannerSuccessNotification(
        //     <>
        //         <span style={{ fontWeight: 800 }}>{this.nomenclaturesScanned}</span>{' '}
        //         {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__NOMENCLATURE_ADDED, undefined, {
        //             value: this.nomenclaturesScanned,
        //         })}
        //     </>,
        //     key,
        //     () => {
        //         this.nomenclaturesScannedKeyIndex++;
        //         this.nomenclaturesScanned = 0;
        //     }
        // );
        getStore().dispatch(showScanNomenclatureSuccessNotification());
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
        barcodeScanEventTarget.addListener(this.onBarcodeScanEvent);
        //if(!this.state.activeTab && this.props.tab === OperationModuleTabsEnum.PRODUCTS) this.setState({activeTab: OperationModuleTabsEnum.PRODUCTS});
        if(!this.state.activeTab) this.setState({activeTab: this.props.tab});
    };

    componentWillUnmount = () => {
        let { resetOperation } = this.props;
        if (this.needToReset) {
            this.needToReset = false;
            resetOperation();
        }
        if (this.intervalId) clearInterval(this.intervalId);

        barcodeScanEventTarget.removeListener(this.onBarcodeScanEvent);
    };

    _createOperation = async () => {
        const res: any = await this.props.createOperation(this.props.intl);
        if (res instanceof Error) {
            const err: AxiosError = res as any;
            if (err?.response?.status === 409 && err?.response?.data?.code === 'API-007') {
                try {
                    await this.updateOperationContext();
                    showNotification('error', 'Операция не создана из-за нехватки, контекст обновлен');
                } catch (e) {
                    showNotification('error', 'Контекст не обновлен');
                }
            }
        }
    };

    createOperation = async (e) => {
        let problems = this.getOperationProblems();
        if (
            !problems.find((problem) => problem.type === OperationProblemModalType.ERROR) &&
            problems.find((problem) => problem.type === OperationProblemModalType.WARNING)
        ) {
            this.setState({ problemModalIsShowing: true });
        } else if (problems.length === 0) {
            this._createOperation();
        }
    };

    updateOperationContext = async () => {
        this.setState({ loading: true });
        try {
            window.dispatchEvent(new Event('refetchOperationFormProductsList'));
            //await this.props.loadProducts(false);
            await this.props.loadElementsForProject(
                this.props.operationProjectId || 0,
                this.props.isSubrent ? 'shipping' : this.props.projectTemplate ? 'projectTemplate' : 'project'
            );
            // Тут загрузить все карты для всех обязательств
            await this.props.updateElementsContext();
            this.setState({ loading: false });
        } catch (e: unknown) {
            this.setState({ loading: false });
            throw e;
        }
    };

    onUpdateOperationContextButtonClick = async () => {
        try {
            await this.updateOperationContext();
            showNotification('success', 'Контекст обновлен');
        } catch (e: unknown) {
            showNotification('error', 'Контекст не обновлен');
        }
    };

    public resetOperation = () => {
        this.onCancelButtonClick();
    };

    onCancelButtonClick = async () => {
        let ok = await showConfirm(
            this.props.intl,
            localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
        );
        if (ok) {
            let { push, resetOperation, fromRequest } = this.props;
            if (!fromRequest) {
                this.needToReset = true;
                if (this.props.goBackPath) push(this.props.goBackPath);
            } else {
                resetOperation();
            }
        }
    };

    onActualizeDatesButtonClick = async () => {
        this.props.setActualizeDateMode(true);
    };

    onAutoRecalculateShiftsCountButtonClick = async () => {
        this.props.setAutoRecalculateShiftsCountMode(true);
    };

    onRestAllKeepLeftoverButtonClick = () => {
        this.props.resetAllKeepLeftover();
    };

    onCommentEditButtonClick = () => {
        this.props.setCommentVisibility(true);
        this.setState({
            activeTab: OperationModuleTabsEnum.ELEMENTS,
        });
    };

    getOperationProblems = () => {
        let { operationTypeCode, operationCorrectionStateCode } = this.props;

        let problemElements: OperationElement[] | undefined;
        let problems: ErrorItem[] = [];
        let operationProblemType: OperationProblemModalType | undefined;
        //// Новая логика по ошибкам
        /// Ошибок не может быть при отмене
        if (
            !this.props.projectTemplate &&
            operationTypeCode !== OperationTypeCodeEnum.CANCEL /* && operationCorrectionStateCode !== RentStateCodeEnum.CANCELED*/
        ) {
            problemElements = this.props.elements.filter(
                (item) => item.problems.includes('endDate') || item.problems.includes('startDate')
            );

            if (problemElements.length) {
                problems.push({
                    message: 'Задан некорректный период', //'При создании операции передан некорректный интервал аренды, начало интервала позже или совпадает с его окночанием',
                    elements: problemElements,
                    type: OperationProblemModalType.ERROR,
                    problemField: 'startDate',
                });
            }

            // problemElements = this.props.elements.filter((item) => item.problems.includes('startDate'));
            // if (problemElements.length) {
            //     problems.push({
            //         message: 'Задан некорректный период', //'При создании операции передан некорректный интервал аренды, окончание интервала раньше или совпадает с его началом',
            //         elements: problemElements,
            //         type: OperationProblemModalType.ERROR,
            //         problemField: 'startDate',
            //     });
            // }

            // Когда добавлены элементы и есть хоть 1н с отрицательной доступностью
            problemElements = this.props.elements.filter(
                (item) => !item.kitId && !item.isCancelled && item.unavailableInstanceCount && item.unavailableInstanceCount > 0
            );
            if (this.props.elementsCount > 0 && problemElements.length > 0) {
                if (canBeErrorWhenShortage(this.props.operationTypeCode, this.props.operationCorrectionStateCode)) {
                    let err;
                    if (
                        operationTypeCode === OperationTypeCodeEnum.ORDER ||
                        operationTypeCode === OperationTypeCodeEnum.DRAFT ||
                        operationCorrectionStateCode === RentStateCodeEnum.ORDERED
                    ) {
                        operationProblemType = OperationProblemModalType.WARNING;
                        if (operationTypeCode === OperationTypeCodeEnum.DRAFT) err = 'Операция приведет к нехватке по черновикам';
                        else
                            err = localize(
                                LocalizationEnum.PAGE__NEW_OPERATION__MODAL__WARNING_ON_SUBMIT__OPERATION_CAUSE_REQUEST_SHORTAGE
                            );
                    } else if (
                        operationTypeCode === OperationTypeCodeEnum.RETURN ||
                        operationTypeCode === OperationTypeCodeEnum.RETURNBROKEN ||
                        operationTypeCode === OperationTypeCodeEnum.LOSTNORETURN ||
                        operationCorrectionStateCode === RentStateCodeEnum.RETURNED ||
                        operationCorrectionStateCode === RentStateCodeEnum.RETURNEDBROKEN ||
                        operationCorrectionStateCode === RentStateCodeEnum.LOSTDURINGRENT
                    ) {
                        operationProblemType = OperationProblemModalType.WARNING;
                        err = localize(LocalizationEnum.PAGE__NEW_OPERATION__POPUP_NOTIFICATIONS__CREATION_FAILED_DUE_SHORTAGE);
                    } else if (
                        operationTypeCode === OperationTypeCodeEnum.RENT ||
                        operationTypeCode === OperationTypeCodeEnum.BOOK ||
                        operationTypeCode === OperationTypeCodeEnum.PROLONG ||
                        operationCorrectionStateCode === RentStateCodeEnum.BOOKED ||
                        operationCorrectionStateCode === RentStateCodeEnum.RENT
                    ) {
                        if (operationCorrectionStateCode) operationProblemType = OperationProblemModalType.WARNING;
                        else operationProblemType = OperationProblemModalType.ERROR;
                        err = localize(LocalizationEnum.PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_INSTANCES_FOR);
                        if (operationTypeCode === OperationTypeCodeEnum.RENT || operationCorrectionStateCode === RentStateCodeEnum.RENT) {
                            err = (
                                <>
                                    {err}{' '}
                                    {localize(LocalizationEnum.PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_FOR_GIVE_AWAY)}
                                </>
                            );
                        } else if (
                            operationTypeCode === OperationTypeCodeEnum.BOOK ||
                            operationCorrectionStateCode === RentStateCodeEnum.BOOKED
                        ) {
                            err = (
                                <>
                                    {err} {localize(LocalizationEnum.PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_FOR_BOOKING)}
                                </>
                            );
                        } else if (operationTypeCode === OperationTypeCodeEnum.PROLONG) {
                            err = (
                                <>
                                    {err}{' '}
                                    {localize(LocalizationEnum.PAGE__NEW_OPERATION__MODAL__PROBLEM_ON_SUBMIT__NOT_ENOUGH_FOR_PROLONGATION)}
                                </>
                            );
                        }
                    }
                    if (err && operationProblemType) {
                        problems.push({
                            message: err,
                            elements: problemElements,
                            type: operationProblemType,
                            problemField: 'instanceCount',
                        });
                    }
                }
            } else if (this.props.elementsCount <= 0) {
                problems.push({
                    type: OperationProblemModalType.ERROR,
                    tooltip: <>{localize(LocalizationEnum.PAGE__NEW_OPERATION__TOOLTIP__DISABLED_SUBMIT_BUTTON_ON_EMPTY_COMPOSITION)}</>,
                });
            }

            {
                if (!canCreateOperationWithAnonymousInstances(operationCorrectionStateCode || operationTypeCode)) {
                    problemElements = this.props.elements
                        .filter((element) => !element.isCancelled)
                        .filter(
                            (element) =>
                                (element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                                    element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED) &&
                                ((!element.instanceIds && element.instanceCount > 0) ||
                                    (element.instanceIds && element.instanceIds.length < element.instanceCount))
                        );
                    if (problemElements.length) {
                        problems.push({
                            message: 'Требуется выбрать экземпляры',
                            elements: problemElements,
                            type: OperationProblemModalType.ERROR,
                            tooltip: 'Требуется выбрать экземпляры',
                            problemField: 'instanceIds',
                        });
                    }
                }
            }

            {
                let problemElements = this.props.elements.filter(
                    (element) =>
                        !element.kitId &&
                        !element.isCancelled &&
                        (element.shiftCount || 0) >
                            getShiftCountFromDates(
                                moment(element.rentPeriodStartDate),
                                moment(element.rentPeriodEndDate),
                                element.shiftLengthInMinutes,
                                this.props.shiftCountRoundingType
                            )
                );
                if (problemElements.length) {
                    problems.push({
                        message: 'Количество смен превосходит период аренды',
                        elements: problemElements,
                        type: OperationProblemModalType.WARNING,
                        problemField: 'shiftCount',
                    });
                }
            }
        } else if (this.props.projectTemplate && this.props.elementsCount <= 0) {
            problems.push({
                type: OperationProblemModalType.ERROR,
                tooltip: <>{localize(LocalizationEnum.PAGE__NEW_OPERATION__TOOLTIP__DISABLED_SUBMIT_BUTTON_ON_EMPTY_COMPOSITION)}</>,
            });
        } else if (!this.props.projectTemplate && this.props.elementsCount <= 0) {
            problems.push({
                type: OperationProblemModalType.ERROR,
                tooltip: <>{localize(LocalizationEnum.PAGE__NEW_OPERATION__TOOLTIP__DISABLED_SUBMIT_BUTTON_ON_EMPTY_COMPOSITION)}</>,
            });
        } else if (this.props.projectTemplate && operationTypeCode !== OperationTypeCodeEnum.CANCEL) {
            problemElements = this.props.elements.filter(
                (item) => !item.kitId && !item.isCancelled && item.unavailableInstanceCount && item.unavailableInstanceCount > 0
            );
            if (problemElements.length) {
                problems.push({
                    message: 'Операция приведет к добавлению в шаблон большего числа, чем находится в инвентаре',
                    elements: problemElements,
                    type: OperationProblemModalType.WARNING,
                    problemField: 'instanceCount',
                });
            }
        }
        return problems;
    };

    onTabsChange = (key: string) => {
        if (key === OperationModuleTabsEnum.PRODUCTS) {
            let gridData = getGridStorageData(OperationFormNomenclaturesGridName);
            let gridCategoriesData = getGridCategoriesStorageData(OperationFormNomenclaturesGridName);

            this.props.push(
                getPathFromState(this.props.location.pathname, '', {
                    tab: key,
                    ...gridData.filters,
                    ...gridData.params,
                    categoryIds: gridCategoriesData.selectedIds ? gridCategoriesData.selectedIds.join(';') : undefined,
                })
            );
        }else{
            this.props.push(getPathFromState(this.props.location.pathname, '', { tab: key }));
        }
        this.setState({ activeTab: key as OperationModuleTabsEnum });
    };

    operationProblemModalOnOk = () => {
        this._createOperation();
        this.setState({ problemModalIsShowing: false });
    };

    operationProblemModalOnCancel = () => {
        this.setState({ problemModalIsShowing: false });
    };

    render() {
        console.log('OperationModule render()');

        let { creationInProgress, creationError, operationTypeCode } = this.props;

        let errType;
        let operationWarningMessage: any;
        let allProblems = this.getOperationProblems();
        let problems = allProblems.filter((problem) => problem.type === OperationProblemModalType.ERROR);
        if (problems.length) {
            errType = OperationProblemModalType.ERROR;
            operationWarningMessage = problems.find((problem) => problem.tooltip)?.tooltip;
            problems = problems.filter((problem) => problem.elements);
        } else {
            problems = allProblems.filter((problem) => problem.type === OperationProblemModalType.WARNING);
            if (problems.length) {
                errType = OperationProblemModalType.WARNING;
            }
        }

        let activeTab: OperationModuleTabsEnum | undefined = this.props.tab;

        let elementsCount = 0;
        this.props.elements.forEach((element) => {
            if (!element.parentId) elementsCount++;
        });

        let fromProjectCount = 0;
        let fromProjectCountTotal = 0; //this.props.equipmentFilteredEntities?.length;
        let fromProjectSuccess: boolean = false;

        // Сколько всего элементов вообще в выборке
        this.props.equipmentFilteredEntities?.forEach((element) => {
            if (!element.subRows) fromProjectCountTotal += 1;
            else fromProjectCountTotal += element.subRows.length;
        });

        //
        this.props.equipmentFilteredEntities?.forEach((element) => {
            if (!element.subRows) {
                let el = this.props.elements.find((el) => el.id === element.id);
                if (el && el.isCancelled) fromProjectCount += 1;
                else if (el && el.leftoverInstanceCount <= 0) fromProjectCount += 1;
            } else {
                element.subRows.forEach((element1) => {
                    let el = this.props.elements.find((el) => el.id === element1.id);
                    if (el && el.isCancelled) fromProjectCount += 1;
                    else if (el && el.leftoverInstanceCount <= 0) fromProjectCount += 1;
                });
            }
        });

        let countStr =
            fromProjectCount === fromProjectCountTotal ? `(${fromProjectCount})` : `(${fromProjectCount} из ${fromProjectCountTotal})`;
        if (!this.props.equipmentFilteredEntities) countStr = '';
        if (fromProjectCountTotal !== 0 && fromProjectCount === fromProjectCountTotal) fromProjectSuccess = true;

        if(this.props.tab === OperationModuleTabsEnum.PRODUCTS && !this.state.activeTab){
            let gridData = getGridStorageData(OperationFormNomenclaturesGridName);
            let gridCategoriesData = getGridCategoriesStorageData(OperationFormNomenclaturesGridName);
            const path = getPathFromState(this.props.location.pathname, '', {
                tab: this.props.tab,
                ...gridData.filters,
                ...gridData.params,
                categoryIds: gridCategoriesData.selectedIds ? gridCategoriesData.selectedIds.join(';') : undefined,
            });
            return <Redirect to={path} />;
        }else if(this.props.tab !== OperationModuleTabsEnum.PRODUCTS && !this.state.activeTab){
            const path = getPathFromState(this.props.location.pathname, this.props.location.search, {
                tab: this.props.tab
            });
            return <Redirect to={path} />;
        }

        return (
            <div style={{ margin: -30 }}>
                <Spin delay={0} spinning={creationInProgress || this.state.loading || this.props.loading}>
                    <div>
                        <div style={{ margin: 30 }}>
                            <OperationFormPageTopBlock
                                creationBlock={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {operationTypeCode === OperationTypeCodeEnum.CORRECT && this.props.operationCorrectionStateCode ? (
                                            <div
                                                style={{ display: 'inline-block', lineHeight: '21px', userSelect: 'none', marginRight: 20 }}
                                            >
                                                <div style={{ width: '100%', marginBottom: 0, whiteSpace: 'nowrap' }}>
                                                    {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TARGET_ELEMENTS_STATE)}
                                                </div>
                                                <div style={{ lineHeight: '0px' }}>
                                                    <Status
                                                        entityType={'element'}
                                                        code={this.props.operationCorrectionStateCode}
                                                        style={{
                                                            width: '100%',
                                                            lineHeight: '16px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* Предупреждение */}
                                        {errType === OperationProblemModalType.WARNING ? (
                                            <Icon
                                                onClick={() => this.setState({ problemModalIsShowing: true })}
                                                component={IconExclamationCircleSolid}
                                                style={{
                                                    marginRight: 4,
                                                    fontSize: 32,
                                                    color: '#ff9924',
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        ) : null}

                                        {/* Ошибка */}
                                        {errType === OperationProblemModalType.ERROR && problems.length ? (
                                            <Icon
                                                onClick={() => this.setState({ problemModalIsShowing: true })}
                                                component={IconExclamationSolid}
                                                style={{
                                                    marginRight: 4,
                                                    fontSize: 32,
                                                    color: '#f8516c',
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        ) : null}

                                        {
                                            <OperationTypeSelectButton
                                                errType={errType}
                                                currentOperationTypeCode={this.props.operationTypeCode}
                                                disabled={errType === OperationProblemModalType.ERROR}
                                                operationWarningMessage={operationWarningMessage}
                                                onApplyOperation={() => {
                                                    this.createOperation(null);
                                                }}
                                                onChangeOperationType={async (
                                                    code: OperationTypeCodeEnum,
                                                    correctionCode?: RentStateCodeEnum | undefined
                                                ) => {
                                                    await this.props.changeOperationType(code, correctionCode);
                                                    try {
                                                        await this.updateOperationContext();
                                                    } catch (e) {
                                                        showNotification('error', 'Ошибка');
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                }
                            />

                            {creationError ? (
                                <Alert
                                    type={'error'}
                                    message={'Ошибка создания операции'}
                                    description={creationError.message}
                                    showIcon
                                    closable
                                    style={{ marginBottom: 30 }}
                                />
                            ) : null}

                            <div className="card-container">
                                <Tabs
                                    defaultActiveKey={activeTab}
                                    activeKey={activeTab}
                                    onChange={this.onTabsChange}
                                    className={'rr-tabs rr-operationForm-tabs'}
                                    type="line"
                                    animated={false}
                                    destroyInactiveTabPane
                                    tabBarExtraContent={
                                        <>
                                            <AdditionalEntityActions
                                                overlayStyle={{ width: 300 }}
                                                disabled={false}
                                                isElement={false}
                                                content={
                                                    <>
                                                        <Button
                                                            disabled={!canChangeKeepLeftover(operationTypeCode)}
                                                            className={'ant-btn-page-actions'}
                                                            block
                                                            onClick={this.onRestAllKeepLeftoverButtonClick}
                                                        >
                                                            <Icon component={IconClose} style={{ color: '#F4516C' }} />
                                                            Убрать остатки
                                                        </Button>
                                                        <Button
                                                            disabled={this.props.commentVisible}
                                                            className={'ant-btn-page-actions'}
                                                            block
                                                            onClick={this.onCommentEditButtonClick}
                                                        >
                                                            <Icon component={IconEdit} style={{ color: '#525891' }} />
                                                            Комментировать
                                                        </Button>
                                                        {(this.props.operationCorrectionStateCode &&
                                                            [
                                                                RentStateCodeEnum.RENT,
                                                                RentStateCodeEnum.RETURNED,
                                                                RentStateCodeEnum.LOSTDURINGRENT,
                                                                RentStateCodeEnum.RETURNEDBROKEN,
                                                                RentStateCodeEnum.SUBRENT,
                                                                RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
                                                            ].includes(this.props.operationCorrectionStateCode)) ||
                                                        this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
                                                        this.props.operationTypeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
                                                        this.props.operationTypeCode === OperationTypeCodeEnum.RENT ||
                                                        this.props.operationTypeCode === OperationTypeCodeEnum.RETURN ||
                                                        this.props.operationTypeCode === OperationTypeCodeEnum.RETURNBROKEN ||
                                                        this.props.operationTypeCode === OperationTypeCodeEnum.LOSTNORETURN ? (
                                                            <Button
                                                                className={'ant-btn-page-actions'}
                                                                block
                                                                disabled={creationInProgress || this.props.actualizeDateMode}
                                                                onClick={this.onActualizeDatesButtonClick}
                                                            >
                                                                <Icon component={IconCrosshairsSolid} style={{ color: '#34bfa3' }} />
                                                                Режим текущей даты
                                                            </Button>
                                                        ) : null}

                                                        {
                                                            <Button
                                                                className={'ant-btn-page-actions'}
                                                                block
                                                                disabled={creationInProgress || this.props.autoRecalculateShiftsCountMode}
                                                                onClick={this.onAutoRecalculateShiftsCountButtonClick}
                                                            >
                                                                <Icon component={IconSyncSolid} style={{ color: '#5ea5fa' }} />
                                                                Режим пересчета смен
                                                            </Button>
                                                        }
                                                        {
                                                            <Button
                                                                className={'ant-btn-page-actions'}
                                                                block
                                                                onClick={this.onUpdateOperationContextButtonClick}
                                                            >
                                                                <Icon component={IconSatelliteDishSolid} style={{ color: '#FC8C2F' }} />
                                                                Обновить контекст
                                                            </Button>
                                                        }
                                                    </>
                                                }
                                            >
                                                <RoundButton colorScheme={'default3'} className="button-three-points">
                                                    <Icon component={IconEllipsisHSolid} style={{ fontSize: '36px' }} />
                                                </RoundButton>
                                            </AdditionalEntityActions>
                                        </>
                                    }
                                >
                                    {canAddNewProductsToOperation(operationTypeCode) ? (
                                        <Tabs.TabPane
                                            tab={
                                                <>
                                                    <Icon
                                                        component={IconPlusCircle}
                                                        style={{ fontSize: 25, verticalAlign: 'middle', marginTop: -4, marginRight: 12 }}
                                                    />
                                                    {`Создание новых`}
                                                </>
                                            }
                                            key={OperationModuleTabsEnum.PRODUCTS}
                                        >
                                            {/*<OperationFormProductsList visible={activeTab === OperationModuleTabsEnum.PRODUCTS} />*/}
                                            <NomenclaturesTab />
                                        </Tabs.TabPane>
                                    ) : null}
                                    {
                                        <Tabs.TabPane
                                            tab={
                                                <>
                                                    <Icon
                                                        component={IconListAlt}
                                                        style={{ fontSize: 25, verticalAlign: 'middle', marginTop: -4, marginRight: 12 }}
                                                    />
                                                    {`Выбор существующих `}
                                                    {fromProjectSuccess ? (
                                                        <Icon
                                                            style={{
                                                                color: '#34BFA3',
                                                                fontSize: 28,
                                                                marginLeft: 5,
                                                                marginRight: 6,
                                                                marginTop: -2,
                                                                verticalAlign: 'middle',
                                                            }}
                                                            component={IconCheck}
                                                        />
                                                    ) : undefined}
                                                    {countStr}
                                                </>
                                            }
                                            key={OperationModuleTabsEnum.EQUIPMENT}
                                        >
                                            <OperationFormEquipmentList visible={activeTab === OperationModuleTabsEnum.EQUIPMENT} />
                                        </Tabs.TabPane>
                                    }
                                    {
                                        <Tabs.TabPane
                                            tab={
                                                <>
                                                    <Icon
                                                        component={IconCartArrowDownSolid}
                                                        style={{ fontSize: 25, verticalAlign: 'middle', marginTop: -4, marginRight: 12 }}
                                                    />
                                                    {`Добавлено (${elementsCount})`}
                                                </>
                                            }
                                            key={OperationModuleTabsEnum.ELEMENTS}
                                        >
                                            <OperationFormElementsList visible={activeTab === OperationModuleTabsEnum.ELEMENTS} />
                                        </Tabs.TabPane>
                                    }
                                </Tabs>
                            </div>

                            <OperationBottomBlock />

                            <OperationProblemModal
                                errors={problems}
                                visible={this.state.problemModalIsShowing}
                                type={errType}
                                isCorrection={operationTypeCode === OperationTypeCodeEnum.CORRECT}
                                onOk={this.operationProblemModalOnOk}
                                onCancel={this.operationProblemModalOnCancel}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (storeState: RootState, ownProps: any) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    let tab =
        searchParams.tab &&
        (searchParams.tab === OperationModuleTabsEnum.PRODUCTS ||
            searchParams.tab === OperationModuleTabsEnum.EQUIPMENT ||
            searchParams.tab === OperationModuleTabsEnum.ELEMENTS)
            ? searchParams.tab
            : storeState.operationForm.typeCode === OperationTypeCodeEnum.DRAFT
            ? OperationModuleTabsEnum.ELEMENTS
            : OperationModuleTabsEnum.PRODUCTS;
    if (!canAddNewProductsToOperation(storeState.operationForm.typeCode) && tab !== OperationModuleTabsEnum.EQUIPMENT)
        tab = OperationModuleTabsEnum.ELEMENTS;
    if (searchParams.tab === undefined && storeState.operationForm.elements?.entities.length > 0) tab = OperationModuleTabsEnum.ELEMENTS;

    const activityFrameId: number | undefined = storeState.operationForm.projectId;
    const activityFrame: RentActivityFrameTypeCodeEnum = storeState.operationForm.isSubrent
        ? RentActivityFrameTypeCodeEnum.SUBRENT
        : storeState.operationForm.projectTemplate
        ? RentActivityFrameTypeCodeEnum.TEMPLATE
        : RentActivityFrameTypeCodeEnum.PROJECT;

    return {
        businessAccountId: storeState.system.businessAccountId,
        operationTypeCode: storeState.operationForm.typeCode,
        creationError: storeState.operationForm.creationError,
        creationInProgress: storeState.operationForm.creationInProgress,
        loading: storeState.operationForm.loading,
        elementsCount: storeState.operationForm.elementsCount,
        fromRequest: storeState.operationForm.fromRequest,
        elements: storeState.operationForm.elements.entities,
        operationCorrectionStateCode: storeState.operationForm.targetStateCode,
        userPermissions: storeState.permissions.permissions,
        rentPeriodStartDate: storeState.operationForm.rentPeriodStartDate,
        rentPeriodEndDate: storeState.operationForm.rentPeriodEndDate,
        projectTemplate: storeState.operationForm.projectTemplate,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        goBackPath: storeState.operationForm.goBackPath,
        tab: tab,
        operationStartTime: storeState.operationForm.operationStartTime,
        timeTables: storeState.operationForm.timeTables,
        isSubrent: storeState.operationForm.isSubrent,
        operationProjectId: storeState.operationForm.projectId,
        equipmentEntities: storeState.operationForm.equipment.entities,
        equipmentFilteredEntities: storeState.operationForm.equipment.filteredEntities,
        autoRecalculateShiftsCountMode: storeState.operationForm.autoRecalculateShiftsCountMode,
        actualizeDateMode: storeState.operationForm.actualizeDateMode,
        activityFrameId: activityFrameId,
        activityFrame: activityFrame,
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        addKitMembersWhenInstanceScan: storeState.userSettings.addKitMembersWhenInstanceScan,
        commentVisible: commentVisibleSelector(storeState),
    };
};

const mapDispatchToProps = {
    createOperation,
    resetOperation,
    push,
    setOperationStartDateToCurrent,
    setOperationEndDateToCurrent,
    changeOperationType,
    resetAllKeepLeftover,
    addInstanceFromScanner,
    loadElementsForProject,
    setAutoRecalculateShiftsCountMode,
    setActualizeDateMode,
    loadConcurrentOperations1: registerAndListConcurrentOperations,
    loadProducts,
    updateElementsContext,
    setCommentVisibility,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationModule = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    injectIntl(Component, { forwardRef: true })
);

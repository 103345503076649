import React, {FC, ReactNode} from 'react';
import {Moment} from "moment";
import './rangeCalendar.less';
import {localize} from "../../../../../../localization/localizationUtils";
import {TimeRangePicker} from "../../../../../timeRangePicker/timeRangePicker";
import {RecalculateShiftsCountButton} from "../../../../../../modules/main/operationForm/RecalculateShiftsCountButton";
import {Button, Switch} from "antd";
import {LocalizationEnum} from "../../../../../../localization";
import {RecalculateShiftCountBlockProps, RequiredTimeIndentProps} from "../../rangePicker.types";

export interface RangeCalendarFooterProps {
    value?: [Moment|undefined, Moment|undefined];
    onChange?: (value?: [Moment, Moment]) => void;
    onOk?: () => void;
    showTime?: boolean;
    recalculateShiftCountBlockProps?: RecalculateShiftCountBlockProps;
    requiredTimeIndentProps?: RequiredTimeIndentProps;
    onStartDateTodayButtonClick1?:(()=>void)|null;
    onEndDateTodayButtonClick1?:(()=>void)|null;
}



export const RangeCalendarFooter:FC<RangeCalendarFooterProps> = (props) => {

    const {recalculateShiftCountBlockProps, requiredTimeIndentProps} = props;

    return (
        <div className="ant-calendar-footer-extra" style={{ width: '100%', padding: '16px 0' }}>
            <div
                style={{
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.showTime && (
                    <TimeRangePicker
                        requiredTimeIndentBetweenElementsInMinutes={
                            requiredTimeIndentProps?.minutes
                        }
                        productHasOwnRequiredTimeIndentBetweenElements={
                            requiredTimeIndentProps?.hasOwnIndent
                        }
                        showRequiredTimeIndentBetweenElementsInMinutesIcon={
                            Boolean(requiredTimeIndentProps)
                        }
                        value={props.value as any}
                        minutesStep={5}
                        onChange={(value) => {
                            props.onChange?.(value);
                        }}
                        onStartDateTodayButtonClick1={props.onStartDateTodayButtonClick1}
                        onEndDateTodayButtonClick1={props.onEndDateTodayButtonClick1}
                    />
                )}
            </div>
            <div
                style={{
                    width: '38%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {recalculateShiftCountBlockProps && (
                    <>
                        <RecalculateShiftsCountButton
                            hasOwnShiftLength={recalculateShiftCountBlockProps.hasOwnShiftLength}
                            hint={recalculateShiftCountBlockProps.shiftLengthHint}
                            shiftLengthInMinutes={recalculateShiftCountBlockProps.shiftLengthInMin}
                            style={{ marginRight: 6 }}
                            from={recalculateShiftCountBlockProps.fromShiftCount}
                            to={recalculateShiftCountBlockProps.toShiftCount}
                        />
                        Пересчитать смены
                        <Switch
                            style={{ marginLeft: 12, verticalAlign: 'middle' }}
                            defaultChecked={recalculateShiftCountBlockProps.recalculateShiftCount}
                            onChange={recalculateShiftCountBlockProps.recalculateShiftCountOnChange}
                        />
                    </>
                )}
            </div>
            <div style={{ width: '12%' }}>
                <Button type="primary" onClick={props.onOk}>
                    {localize(LocalizationEnum.ASPECT__GLOBAL__OK)}
                </Button>
            </div>
        </div>
    );
};
